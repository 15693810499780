// English translations
import { merge } from 'lodash'
import elementTranslations from 'element-plus/dist/locale/en.mjs'

import baseTranslations from '@/i18n/store/en.js'
import updates from '@/i18n/updates/en.merge.js'

import whitelabelTranslations from './whitelabel-en.js'

const sources = [elementTranslations, baseTranslations, whitelabelTranslations, updates]

export default merge({}, ...sources)
