import { companyActivitySchema } from '@/api-sdk/resources/schemas/company'
import type MegaportAPI from '../megaport/api'
import { base64Encode } from '../megaport/utils'
import type { APISettings, URLParams } from '../megaport/types'

import type { ChangeEmailPayload, ChangePasswordPayload, GetActivityLogsPayload } from './types/profile'
import { mfaQrcodeSchema } from './schemas/profile'

/**
 * Work with the user's profile
 *
 * NOTE: Getting and updating user profile uses the v2/employee endpoints and can be found in the employee.ts file
 */
export default class ProfileResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get the QR code and secret for the user to set up 2 factor authentication
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async mfaQr(settings?: APISettings) {
    const response = await this.#api.get(`/v2/totp/qrcode`, settings, { schema: mfaQrcodeSchema })
    return response.body.data
  }

  /**
   * Request email change and send verification code to new email
   * NOTE: The password in the payload is due to be removed in the future
   * @param request A request object containing the new email and the current password
   * @param settings Additional settings to adjust the generated API request
   * @returns Success message and terms only (not used by portal)
   */
  async changeEmail(request: ChangeEmailPayload, settings?: APISettings) {
    const data: URLParams = { ...request }
    if (request.password) data.encodedPassword = base64Encode(request.password)

    await this.#api.post('/v2/email/change', settings, { data })
  }

  /**
   * Verify email update using token emailed to new email
   * @param confirmationCode Confirmation code from new email
   * @param settings Additional settings to adjust the generated API request
   * @returns New email only (not used by portal)
   */
  async verifyEmailUpdate(confirmationCode: string, settings?: APISettings) {
    const data = {
      confirmationCode,
    }
    await this.#api.jpost('/v2/auth/users/verifyEmail', settings, { data })
  }

  /**
   * Verify email using token that is emailed to you
   * Used only when visiting /verifyEmail route in portal
   * @param verifyToken
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async verifyEmail(verifyToken: string, settings?: APISettings) {
    const data = {
      verifyToken,
    }
    await this.#api.post('/v2/email/verify', settings, { data, authenticated: false })
  }

  /**
   * Change the password
   * @param oldPassword The old password to change
   * @param newPassword The new password to change to
   * @param settings Additional settings to adjust the generated API request
   * @returns Success message and terms only (not used by portal)
   */
  async changePassword({ oldPassword, newPassword }: ChangePasswordPayload, settings?: APISettings) {
    const data = {
      oldEncodedPassword: base64Encode(oldPassword),
      newEncodedPassword: base64Encode(newPassword),
    }
    await this.#api.post('/v2/password/change', settings, { data })
  }

  /**
   * Read all the activity for the person
   * @param params Pass in parameters to paginate and sort the data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async activity(params?: GetActivityLogsPayload, settings?: APISettings) {
    const response = await this.#api.get('/v3/activity', settings, { params, schema: companyActivitySchema })
    return {
      ...response.body,
      headers: {
        'pagination-total-page': response.headers['pagination-total-page'],
      },
    }
  }
}
