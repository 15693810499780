import * as globals from '@/Globals.js'

const keys = Object.keys(globals).filter(key => key.startsWith('G_'))

const install = app => {
  for (const key of keys) {
    app.config.globalProperties[key] = globals[key]
  }
}

export default install
