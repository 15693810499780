import sdk from '@/api-sdk'
import i18n from '@/i18n/i18n.ts'

export const coreState = {
  ready: {
    in: false,
    out: false,
  },
  isLoading: true,
  selectedIXProduct: null,
  selectedDates: {
    start: null,
    end: null,
  },
  selectedPeer: {
    in: null,
    out: null,
  },
  peers: [],
  telemetryData: {
    in: null,
    out: null,
  },
  flowTypes: null,
  selectedFlowType: null,
  errors: {
    in: null,
    out: null,
  },
  topFivePeers: {
    in: [],
    out: [],
  },
  topFiveTelemetry: {
    in: [],
    out: [],
  },
}
// copy of inital state
const defaultState = { ...coreState }
// ---------------------------------------------------------------------------- Getters
const coreGetters = {
  getTelemetryData(state) {
    return state.telemetryData
  },
  getIxPeers(state) {
    return state.peers
  },
  getReadyStatus: state => direction => {
    return state.ready[direction]
  },
  getLoadingState(state) {
    return state.isLoading
  },
  getSelectedProduct(state) {
    return state.selectedIXProduct
  },
  getSelectedPeer: state => direction => {
    return state.selectedPeer[direction]
  },
  getFlowTypes(state) {
    return state.flowTypes
  },
  getSelectedFlowType(state) {
    return state.selectedFlowType
  },
  getSelectedDates(state) {
    return state.selectedDates
  },
  getErrors(state) {
    return state.errors
  },
  getTopFivePeers(state) {
    return state.topFivePeers
  },
  getTopFiveTelemetry: state => direction => {
    return state.topFiveTelemetry[direction]
  },
  getPeersByDirection: state => direction => {
    return state.peers.slice().sort((a, b) => {
      if (a.volume[direction] < b.volume[direction]) {
        return 1
      } else if (a.volume[direction] > b.volume[direction]) {
        return -1
      }
      return 0
    })
  },
}

// ---------------------------------------------------------------------------- Actions
export const actions = {
  fetchPeers: ({ commit, dispatch, getters, state }) => {
    commit('setLoadingStatus', true)
    commit('setReadyStatus', { direction: 'in', status: false })
    commit('setReadyStatus', { direction: 'out', status: false })
    sdk.instance
      .ixPeers(state.selectedIXProduct.productUid)
      .then(async peers => {
        await commit('setIXPeers', peers)
        commit('setLoadingStatus', false)
        commit('setSelectedPeer', { direction: 'in', selected: `top-five-in` })
        await dispatch('fetchTopTelemetry', {
          direction: 'in',
          peers: getters.getPeersByDirection('in').slice(0, 5).reverse(),
        })

        commit('setSelectedPeer', { direction: 'out', selected: `top-five-out` })
        await dispatch('fetchTopTelemetry', {
          direction: 'out',
          peers: getters.getPeersByDirection('out').slice(0, 5).reverse(),
        })
      })
      .catch(err => {
        commit('setErrors', { direction: 'in', error: err })
        commit('setErrors', { direction: 'out', error: err })
      })
  },
  fetchIXTelemetry: ({ commit, state }, payload) => {
    commit('setReadyStatus', { direction: payload.direction, status: false })
    commit('clearTelemetryData', { direction: payload.direction })
    sdk.instance
      .product(state.selectedIXProduct.productUid)
      .ixTelemetryFlow(
        state.selectedFlowType.name,
        state.selectedDates.start,
        state.selectedDates.end,
        state.selectedPeer[payload.direction].serviceUid,
      )
      .then(res => {
        commit('setTelemetryData', { direction: payload.direction, telemetryData: res })
      })
      .catch(err => {
        commit('setErrors', { direction: payload.direction, error: err })
      })
      .finally(() => {
        commit('setReadyStatus', { direction: payload.direction, status: true })
      })
  },
  fetchFlowTypes: ({ commit }) => {
    sdk.instance
      .telemetryFlowDataTypes()
      .then(types => {
        commit('setFlowTypes', types)
        commit('setSelectedFlowType', { selected: types[0].metrics[0] })
      })
      .catch(err => {
        commit('setErrors', { direction: 'in', error: err })
        commit('setErrors', { direction: 'out', error: err })
      })
  },
  updateIXTelemetry: ({ dispatch, state }) => {
    if (state.selectedPeer.in) {
      if (Object.prototype.hasOwnProperty.call(state.selectedPeer.in, 'serviceUid')) {
        dispatch('fetchIXTelemetry', { direction: 'in' })
      } else {
        dispatch('fetchTopTelemetry', { direction: 'in', peers: state.topFivePeers.in })
      }
    }
    if (state.selectedPeer.out) {
      if (Object.prototype.hasOwnProperty.call(state.selectedPeer.out, 'serviceUid')) {
        dispatch('fetchIXTelemetry', { direction: 'out' })
      } else {
        dispatch('fetchTopTelemetry', { direction: 'out', peers: state.topFivePeers.out })
      }
    }
  },
  fetchTopTelemetry: async ({ commit, state }, payload) => {
    commit('setReadyStatus', { direction: payload.direction, status: false })
    commit('setTopFivePeers', { direction: payload.direction, peers: payload.peers })
    const telemetryData = []
    for await (const peer of payload.peers) {
      const req = sdk.instance.product(state.selectedIXProduct.productUid)
      const res = await req.ixTelemetryFlow(
        state.selectedFlowType.name,
        state.selectedDates.start,
        state.selectedDates.end,
        peer.serviceUid,
      )
      if (!res)
        commit('setErrors', {
          direction: payload.direction,
          error: i18n.t('ix-flows.load-error'),
        })
      telemetryData.push({ peer: peer.companyName, data: res })
    }
    commit('setTopFiveTelemetry', { direction: payload.direction, telemetry: telemetryData })
    commit('setReadyStatus', { direction: payload.direction, status: true })
  },
  resetIXFlows: ({ commit }) => {
    commit('clearTelemetryData', { direction: 'in' })
    commit('clearTelemetryData', { direction: 'out' })
    commit('setReadyStatus', { direction: 'in', status: false })
    commit('setReadyStatus', { direction: 'out', status: false })
    commit('setLoadingStatus', true)
    commit('clearSelectedPeers')
    commit('clearTopFiveTelemetry')
  },
  resetLoadReadyStatus: ({ commit }) => {
    commit('setReadyStatus', { direction: 'in', status: false })
    commit('setReadyStatus', { direction: 'out', status: false })
    commit('setLoadingStatus', true)
  },
}

// ---------------------------------------------------------------------------- Mutations
export const mutations = {
  setTelemetryData(state, payload) {
    state.telemetryData[payload.direction] = payload.telemetryData
  },
  clearTelemetryData(state, payload) {
    state.telemetryData[payload.direction] = null
  },
  setReadyStatus(state, payload) {
    state.ready[payload.direction] = payload.status
  },
  setLoadingStatus(state, payload) {
    state.isLoading = payload
  },
  setSelectedProduct(state, payload) {
    state.selectedIXProduct = payload
  },
  setSelectedPeer(state, payload) {
    state.selectedPeer[payload.direction] = payload.selected
  },
  clearSelectedPeers(state) {
    state.selectedPeer = Object.assign(
      {},
      {
        in: null,
        out: null,
      },
    )
  },
  setDateRange(state, payload) {
    state.selectedDates = {
      start: payload.startDate,
      end: payload.endDate,
    }
  },
  setIXPeers(state, payload) {
    state.peers = Object.assign(payload)
  },
  setFlowTypes(state, payload) {
    state.flowTypes = payload[0]
  },
  setSelectedFlowType(state, payload) {
    state.selectedFlowType = payload.selected
  },
  setErrors(state, payload) {
    state.errors[payload.direction] = payload.error
  },
  setTopFivePeers(state, payload) {
    state.topFivePeers[payload.direction] = payload.peers
  },
  setTopFiveTelemetry(state, payload) {
    state.topFiveTelemetry[payload.direction] = payload.telemetry
  },
  logout(state) {
    Object.assign(state, defaultState)
  },
  clearTopFiveTelemetry(state) {
    state.topFiveTelemetry = Object.assign(
      {},
      {
        in: [],
        out: [],
      },
    )
  },
}

export default {
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
  namespaced: true,
}
