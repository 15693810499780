<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="MCR-Copy-4"
      transform="translate(-32.000000, -32.000000)">
      <g
        id="Mega-IX"
        transform="translate(32.000000, 32.000000)">
        <g id="Group-9">
          <g
            id="Group-14"
            :fill="fillColor"
            fill-rule="nonzero">
            <g id="Group-13">
              <g id="Group-5">
                <path
                  id="Combined-Shape"
                  d="M68,2.19990758e-14 C105.555363,3.11974868e-14 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 -2.8753145e-14,105.555363 -1.9554734e-14,68 C-1.0356323e-14,30.444637 30.444637,1.28006648e-14 68,2.19990758e-14 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z M70.5,34 L70.5,102 L65.5,102 L65.5,34 L70.5,34 Z M53.1262955,77.9781705 L56.6618295,81.5137045 L41.207767,96.967767 L37.672233,93.432233 L53.1262955,77.9781705 Z M82.2919857,77.9781705 L97.7460482,93.432233 L94.2105143,96.967767 L78.7564518,81.5137045 L82.2919857,77.9781705 Z M41.207767,39.032233 L56.6618295,54.4862955 L53.1262955,58.0218295 L37.672233,42.567767 L41.207767,39.032233 Z M94.2105143,39.032233 L97.7460482,42.567767 L82.2919857,58.0218295 L78.7564518,54.4862955 L94.2105143,39.032233 Z" />
              </g>
            </g>
          </g>
          <g
            id="Group-3"
            transform="translate(37.440000, 38.800000)" />
          <g
            id="Group-3"
            transform="translate(67.940000, 87.200000) scale(1, -1) translate(-67.940000, -87.200000) translate(37.440000, 77.200000)" />
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'IX',

  viewBox: '0 0 136 136',

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
