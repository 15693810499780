<template>
  <section>
    <!-- Cancel/Delete Service Modal -->
    <cancel-service
      v-if="showCancelModal"
      v-model="showCancelModal"
      :service-uid="cancelUid" />

    <!-- Save Configured Services Modal -->
    <save-configuration-modal
      v-model="showSaveConfigurationModal"
      :saved-configurations="savedConfigurations" />

    <!-- Configured Services Heading -->
    <section class="configured-content">
      <div class="position-relative">
        <h4>{{ $t('sidebar.configured-services') }}</h4>

        <!-- Delete All Button -->
        <div class="position-absolute clear-all-configs">
          <el-tooltip
            placement="top"
            :content="$t('sidebar.clear-configured-services')"
            :show-after="500">
            <i
              class="far fa-trash-alt option-icon p-0-5"
              aria-hidden="true"
              @click="openCancelModal('DESIGNED')" />
            <!-- DESIGNED means all services (for some reason 🤷) -->
          </el-tooltip>
        </div>
      </div>

      <div
        v-for="service in items"
        :key="service.productUid">
        <!-- Service Name -->
        <p class="configured-services-header">
          {{ serviceHeader(service) }}
        </p>

        <!-- Service Details -->
        <!-- eslint-disable vue/no-v-html -->
        <p
          v-if="service.displayDetails"
          class="m-0 fs-1-3rem"
          data-name="display-details"
          v-html="service.displayDetails" />
        <!-- eslint-enable vue/no-v-html -->

        <div class="flex-row-centered justify-content-end">
          <!-- Service Icon -->
          <el-tooltip
            placement="top"
            :content="convertProductType(service.productType)"
            :show-after="500">
            <mu-mega-icon
              :icon="getIconFor(service.service)"
              class="configured-service-icon" />
          </el-tooltip>

          <!-- Price -->
          <price-book
            v-if="showPricing"
            :service="service.service"
            :lag-port-count="service.lagPortCount"
            :port-uid="service.portUid" />

          <!-- Edit Button -->
          <router-link :to="service.editLink">
            <el-tooltip
              placement="top"
              :content="$t('general.edit-type', { type: convertProductType(service.productType) })"
              :show-after="500">
              <i
                class="far fa-edit option-icon"
                :data-edit-configured="service.productName"
                aria-hidden="true" />
            </el-tooltip>
          </router-link>

          <!-- Delete Button -->
          <div>
            <el-tooltip
              placement="top"
              :content="$t('general.remove-type', { type: convertProductType(service.productType) })"
              :show-after="500">
              <i
                :data-delete-configured="service.productName"
                class="far fa-trash-alt option-icon"
                aria-hidden="true"
                @click="openCancelModal(service.productUid)" />
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- Taxes Disclaimer Text -->
      <p
        v-if="showPricing"
        class="text-align-right fs-1-3rem">
        {{ $t('pricebook.monthly-charges-excl-taxes') }}
      </p>

      <el-row
        class="mt-2"
        :gutter="8">
        <!-- Save Configured Services Button -->
        <el-col :span="12">
          <el-tooltip
            placement="top"
            :content="$t('sidebar.save-configured-services-later')"
            :show-after="500">
            <el-button
              data-name="save-configurations"
              @click="showSaveConfigurationModal = true">
              {{ $t('general.save') }}
            </el-button>
          </el-tooltip>
        </el-col>

        <!-- Order Button -->
        <el-col :span="12">
          <el-button
            type="primary"
            data-testid="order-services-button"
            data-name="order-services"
            @click="showOrderPanel">
            {{ $t('general.review-order') }}
          </el-button>
        </el-col>
      </el-row>

      <hr class="form-hr" />
    </section>
  </section>
</template>

<script>
// External tools
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

// Internal tools
import { convertProductType } from '@/helpers.js'
import eventBus from '@/utils/eventBus.js'
// Components
import PriceBookComponent from '@/components/PriceBook.vue'
import CancelServiceComponent from '@/components/cancel/Cancel.vue'
import SaveConfigurationModal from '@/components/sidebar/SaveConfigurationModal.vue'
import MuMegaIcon from '@/mega-ui/components/leafs/mega-icon/MuMegaIcon.vue'

export default defineComponent({
  name: 'ConfiguredItems',

  components: {
    'price-book': PriceBookComponent,
    'cancel-service': CancelServiceComponent,
    'save-configuration-modal': SaveConfigurationModal,
    'mu-mega-icon': MuMegaIcon,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    items: {
      type: Array,
      required: true,
    },
    savedConfigurations: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showCancelModal: false,
      cancelUid: null,
      showSaveConfigurationModal: false,
    }
  },

  computed: {
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Services', ['findPort']),
    ...mapGetters('Auth', ['hasFeatureFlag']),

    /**
     * Whether to show pricing information
     */
    showPricing() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
  },

  methods: {
    convertProductType(productType) {
      return convertProductType(productType)
    },
    hasCrossConnectEnabled(service) {
      return this.hasFeatureFlag('cross_connect_mvp') && service.service.crossConnectRequested
    },
    showOrderPanel() {
      eventBus.emit('showOrderPanel')
    },

    /**
     * Returns the header for a given service
     * @param {Object} service Service details
     */
    serviceHeader(service) {
      const info = [service.productName]
      if (service.lagPortCount) {
        info.push(this.$t('ports.count-port-lag', { count: service.lagPortCount }, service.lagPortCount))
      }
      if (this.hasCrossConnectEnabled(service)) {
        info.push(`- ${this.$t('ports.includes-cross-connect')}`)
      }
      return info.join(' ')
    },

    /**
     * Gets correct mega-ui icon depending on product type
     * @param {Object} service Service to get icon for
     */
    getIconFor(service) {
      let icon = service.productType

      if (service.productType === this.G_PRODUCT_TYPE_VXC) {
        const aEndDetails = this.findPort(service.aEnd.productUid)
        const bEndDetails = this.findPort(service.bEnd.productUid)

        if (aEndDetails?.companyUid && bEndDetails?.companyUid) {
          const foreignEnd = [aEndDetails, bEndDetails].find(end => end.companyUid !== this.companyUid)

          if (foreignEnd?.connectType === 'TRANSIT') icon = 'MegaportInternet'
        }
      }

      return icon
    },

    /**
     * Opens the cancel modal
     * @param {string} cancelUid uid of the service to cancel
     */
    openCancelModal(cancelUid) {
      this.cancelUid = cancelUid
      this.showCancelModal = true
    },
  },
})
</script>

<style lang="scss" scoped>
.configured-content {
  .el-button {
    width: 100%;
  }
  h4 {
    text-align: center;
    background-color: var(--color-info-light-9);
    padding: 10px 0;
  }
}

.configured-services-header {
  font-weight: 500;
  word-break: break-all;
  margin: 10px 0 0 0;
}

.option-icon {
  padding-inline: 0.5rem;
  align-self: baseline;
  color: var(--color-primary);
  cursor: pointer;

  &:hover {
    color: var(--color-success);
  }
}

svg.configured-service-icon {
  color: var(--color-text-regular);
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
  margin: 0 8px 0 3px;
  cursor: pointer;
}

.clear-all-configs {
  right: 7px;
  top: 7px;
}
</style>
