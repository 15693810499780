import type MegaportAPI from '../megaport/api'
import type { APISettings, URLParams } from '../megaport/types'

import { getResultsSchema, requestResultSchema } from './schemas/diagnostics'

/*
 * getIpRoutes, getBgpRoutes and getBgpNeighbourRoutes have been hardcoded to async=true for now,
 * once this is released the BE will default the BE endpoint to async mode and the query parameter can be removed.
 * this was done to ensure a smooth rollout for the async mode endpoints.
 */
export default class MCRRouteDiagnosticsResource {
  #api: MegaportAPI
  #productUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param productUid The UUID for the MCR
   */
  constructor(api: MegaportAPI, productUid: string) {
    this.#api = api
    this.#productUid = productUid
  }

  /**
   * Fetch async results using an operationId returned from getIpRoutes, getBgpRoutes or getBgpNeighbourRoutes
   * @param operationId
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of the requested results based on operationId
   */
  async getResults(operationId: string, settings?: APISettings) {
    const params = { operationId }
    const response = await this.#api.get(
      `/v2/product/mcr2/${this.#productUid}/diagnostics/routes/operation`,
      settings,
      { params, schema: getResultsSchema },
    )
    return {
      ...response.body,
      status: response.status,
    }
  }

  /**
   * Request the MCR routes table data
   * @param ip_address
   * @param settings Additional settings to adjust the generated API request
   * @returns An operationId to query using getResults
   */
  async getIpRoutes(ip_address?: string, settings?: APISettings) {
    const params: URLParams = {
      async: true,
    }
    if (ip_address) params.ip_address = ip_address
    const response = await this.#api.get(`/v2/product/mcr2/${this.#productUid}/diagnostics/routes/ip`, settings, {
      params,
      schema: requestResultSchema,
    })
    return response.body
  }

  /**
   * Request the BGP routes table data
   * @param ip_address
   * @param settings Additional settings to adjust the generated API request
   * @returns An operationId to query using getResults
   */
  async getBgpRoutes(ip_address?: string, settings?: APISettings) {
    const params: URLParams = {
      async: true,
    }
    if (ip_address) params.ip_address = ip_address
    const response = await this.#api.get(`/v2/product/mcr2/${this.#productUid}/diagnostics/routes/bgp`, settings, {
      params,
      schema: requestResultSchema,
    })
    return response.body
  }

  /**
   * Request the BGP routes as per the specified settings.
   * @param direction The direction (received or advertised)
   * @param peer_ip_address The IP address of the peer
   * @param settings Additional settings to adjust the generated API request
   * @returns An operationId to query using getResults
   */
  async getBgpNeighbourRoutes(direction: string, peer_ip_address: string, settings?: APISettings) {
    const params = {
      direction,
      peer_ip_address,
      async: true,
    }
    const response = await this.#api.get(
      `/v2/product/mcr2/${this.#productUid}/diagnostics/routes/bgp/neighbor`,
      settings,
      { params, schema: requestResultSchema },
    )
    return response.body
  }
}
