import sdk, { CanceledError } from '@/api-sdk'
import { DEFAULT_TERM_PRICES } from '@/Globals.js'

import captureSentryError from './CaptureSentryError'

/**
 * Helper function for creating priceBook requests with appropriate error handling.
 *
 * @param {string} product The name of the function to be called on the API-SDK priceBook resource
 * @param {Object} params Parameters for the priceBook request
 * @param {Object} [settings] API-SDK settings to use when making the request
 */
const priceBook = async (product, params, settings) => {
  try {
    return await sdk.instance.priceBook()[product](params, settings)
  } catch (error) {
    if (!(error instanceof CanceledError)) {
      captureSentryError(error)
    }

    throw error
  }
}

/**
 * Helper function for creating a batch of priceBook requests, each at a given term from the provided list. Eventually
 * this implementation may be replaced by an API solution.
 * API: This function will be removed once allTerms endpoints have been added for remaining products.
 *
 * @param {string} product The name of the function to be called on the API-SDK priceBook resource
 * @param {Object} otherParams The remaining parameters to be passed to each priceBook request
 * @param {Object} [settings] API-SDK settings to use when making the request
 */
const priceBookTerms = async (product, otherParams, settings) => {
  const defaultTermList = Object.keys(DEFAULT_TERM_PRICES).map(Number)
  const termPrices = await Promise.all(
    defaultTermList.map(term => priceBook(product, { ...otherParams, term }, settings)),
  )
  return termPrices.reduce((termMap, price, i) => {
    termMap[defaultTermList[i]] = price
    return termMap
  }, {})
}

// Use a weak map so scope abort controllers can be correctly garbage collected once the scope itself is no longer in use
const scopeAbortControllers = new WeakMap()

/**
 * Wrapper around API-SDK priceBook that automatically handles creation of request objects, and cancellation of
 * outdated requests within the same scope. Scope is defined using the "this" context of this function.
 *
 * Note that the prices returned include any discounts that are applied for that service or company. If you don't
 * specify buyout port, it will still work. If you don't specify productUid or specify one that isn't valid, it will
 * treat it as a new purchase.
 *
 * All of the functions return a monthlyRate field.
 */
export function scopedPriceBook() {
  // Ensure this function is called as a method on a vue component instead of directly
  if (!this) throw new Error('scopedPricebook needs to be called as a Vue component method!')

  /**
   * This function ensures that we use the same signal for identical requests in the same scope. This is necessary because
   * API-SDK dedupes pricebook requests, meaning that when the Portal sends the same request multiple times in a row, it
   * could end up receiving the request it just cancelled as the final response, causing no price to be shown at all.
   *
   * @param {IArguments} args The parameters of the request. Used to identify identical duplicate requests.
   * @returns {AbortSignal} The signal to use for the PriceBook request.
   */
  const getSignal = args => {
    const params = JSON.stringify([...args])
    const previousRequest = scopeAbortControllers.get(this)
    let controller

    if (previousRequest) {
      if (previousRequest.params === params) {
        controller = previousRequest.controller
      } else {
        previousRequest.controller.abort()
      }
    }
    if (!controller) {
      controller = new AbortController()
      scopeAbortControllers.set(this, { params, controller })
    }
    return controller.signal
  }

  // And with that ⬆️ addition, the shark is jumped. This file is officially, totally over-engineered. I am so sorry.

  return {
    //
    // Standard requests
    //
    async megaport(locationId, speed, term, buyoutPort, productUid, crossConnectRequested) {
      return await priceBook(
        'megaport',
        {
          locationId,
          speed,
          buyoutPort: buyoutPort ?? false,
          productUid,
          term,
          crossConnectRequested,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async mcr(locationId, speed, productUid, term) {
      return await priceBook(
        'mcr',
        {
          locationId,
          speed,
          productUid,
          term,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async mve(locationId, vendor, size, productUid, term) {
      return await priceBook(
        'mve',
        {
          locationId,
          vendor,
          size,
          productUid,
          term,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async vxc(aLocationId, bLocationId, speed, term, aEndProductType, connectType, buyoutPort, productUid) {
      return await priceBook(
        'vxc',
        {
          aLocationId,
          bLocationId,
          speed,
          aEndProductType,
          connectType: connectType ? connectType.toUpperCase() : 'DEFAULT',
          buyoutPort: buyoutPort ?? false,
          productUid,
          term,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async ix(ixType, portLocationId, speed, /* term, */ buyoutPort, productUid) {
      return await priceBook(
        'ix',
        {
          ixType,
          portLocationId,
          speed,
          buyoutPort: buyoutPort ?? false,
          productUid,
          // term, // Uncomment when term functionality has been extended to IXs
        },
        {
          signal: getSignal(arguments),
        },
      )
    },

    //
    // Term batch requests
    //
    async megaportTerms(locationId, speed, buyoutPort, productUid, crossConnectRequested) {
      return await priceBookTerms(
        'megaport',
        {
          locationId,
          speed,
          buyoutPort: buyoutPort ?? false,
          productUid,
          crossConnectRequested,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async mcrTerms(locationId, speed, productUid) {
      return await priceBookTerms(
        'mcr',
        {
          locationId,
          speed,
          productUid,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async mveTerms(locationId, vendor, size, productUid) {
      return await priceBookTerms(
        'mve',
        {
          locationId,
          vendor,
          size,
          productUid,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
    async vxcTerms(aLocationId, bLocationId, speed, aEndProductType, connectType, buyoutPort) {
      return await priceBook(
        'vxcAllTerms',
        {
          aLocationId,
          bLocationId,
          speed,
          aEndProductType,
          connectType: connectType ? connectType.toUpperCase() : 'DEFAULT',
          buyoutPort: buyoutPort,
        },
        {
          signal: getSignal(arguments),
        },
      )
    },
  }
}
