import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type { InvoiceRequestData } from './types/invoices'

/**
 * Get invoices for either the specified company or the company you are logged in under.
 */
export default class InvoicesResource {
  #api: MegaportAPI
  #companyUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param companyUid
   */
  constructor(api: MegaportAPI, companyUid = '') {
    this.#api = api
    this.#companyUid = companyUid
  }

  /**
   * Get all the invoices for the company.
   *
   * @param requestData
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(requestData: InvoiceRequestData, settings?: APISettings) {
    const params = {
      ...(requestData.fromDate && { fromDate: requestData.fromDate }),
      ...(requestData.toDate && { toDate: requestData.toDate }),
    }
    const response = await this.#api.get(
      `/v2/company/${this.#companyUid}/invoice/supplierId/${requestData.supplierId}`,
      settings,
      { params },
    )
    return response.body.data || response.body
  }

  /**
   * This endpoint will return the balance, estimate of next invoice and currency. The primary
   * use of this is to separate out the heavy operation of estimating the next invoice from
   * the normal data which is readily available.
   *
   * Note that in non-prod/non-fin-uat apis the balance returned will be null.
   *
   * @param companyId
   * @param supplierId
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async balance(companyId: number, supplierId: number, settings?: APISettings) {
    const response = await this.#api.get(`/v2/company/${companyId}/balance/supplierId/${supplierId}`, settings)
    return response.body.data
  }
}
