import type MegaportAPI from '../megaport/api'
import type { APISettings, EnsEventState } from '../megaport/types'

import { maintenanceEventsSchema, outagesEventsSchema } from './schemas/serviceStatus'

export default class ServicesStatusResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get list of maintenance events provided states
   * @param state ENS event state
   * @param settings Additional settings to adjust the generated API request
   * @returns response schema
   */
  async maintenance(state: EnsEventState[] = [], settings?: APISettings) {
    const response = await this.#api.get('ens/v1/status/maintenance', settings, {
      params: { state },
      schema: maintenanceEventsSchema,
    })
    return response.body.data
  }

  /** Get list of outage events provided states
   * @param state ENS event state
   * @param settings Additional settings to adjust the generated API request
   * @returns response schema
   */
  async outage(state: EnsEventState[] = [], settings?: APISettings) {
    const response = await this.#api.get('ens/v1/status/outage', settings, {
      params: { state },
      schema: outagesEventsSchema,
    })
    return response.body.data
  }
}
