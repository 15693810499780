import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type { EnableMFAPayload, UpdateEmployeePayload, UpdateEmployeePreferencesPayload } from './types/employee'
import { getEmployeeSchema } from './schemas/employee'

/**
 * Handles getting and updating user or employee details
 */
export default class EmployeeResource {
  #api: MegaportAPI
  #employeeId: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param employeeId Employee ID
   */
  constructor(api: MegaportAPI, employeeId?: number) {
    this.#api = api
    this.#employeeId = employeeId == null ? '' : `${employeeId}`
  }

  /**
   * Get information about the specified employee (or current user).
   *
   * Typically called upon login in the portal only
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns Object containing employee information
   */
  async get(settings?: APISettings) {
    const response = await this.#api.get(`/v2/employee/${this.#employeeId}`, settings, { schema: getEmployeeSchema })
    return response.body.data
  }

  /**
   * Update the employee (or current user)
   *
   * Payload type has been set to what is sent by the portal, however this endpoint is
   * flexible enough to allow you to pass in any of the fields returned by the above GET method.
   *
   * @param data
   * @param settings Additional settings to adjust the generated API request
   */
  async update(data: UpdateEmployeePayload | UpdateEmployeePreferencesPayload, settings?: APISettings) {
    await this.#api.put(`/v2/employee/${this.#employeeId}`, settings, { data })
  }

  /**
   * Delete an invited user that has not yet logged in
   * Only a company admin can delete users in their company
   *
   * @param settings Additional settings to adjust the generated API request
   */
  async delete(settings?: APISettings) {
    await this.#api.delete(`/v2/employee/${this.#employeeId}`, settings)
  }

  /**
   * Enable MFA for a user by user
   * Requires totpSecret and totpCode
   *
   * @param data Payload for enabling MFA
   * @param settings Additional settings to adjust the generated API request
   */
  async enableMfa(data: EnableMFAPayload, settings?: APISettings) {
    await this.#api.jpost(`v2/employee/${this.#employeeId}/mfa`, settings, { data })
  }

  /**
   * Disable MFA for a user by user
   * @param settings Additional settings to adjust the generated API request
   */
  async disableMfa(settings?: APISettings) {
    await this.#api.delete(`v2/employee/${this.#employeeId}/mfa`, settings)
  }

  /**
   * Reset MFA for a user by user
   * Requires totpSecret and totpCode
   *
   * @param data Payload for resetting MFA
   * @param settings Additional settings to adjust the generated API request
   */
  async resetMfa(data: EnableMFAPayload, settings?: APISettings) {
    await this.#api.put(`v2/employee/${this.#employeeId}/mfa`, settings, { data })
  }

  /**
   * Reset MFA for a user by company admin
   * @param settings Additional settings to adjust the generated API request
   */
  async adminResetMfa(settings?: APISettings) {
    await this.#api.delete(`v2/employee/${this.#employeeId}/mfa/admin`, settings)
  }
}
