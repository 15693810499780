import { boolean, number, string } from 'zod'

import { apiResponseArray } from '../../megaport/schemas'

export const getDealsSchema = apiResponseArray({
  id: number(),
  entityUid: string(),
  dealId: string(),
  partnerId: number(),
  opportunityName: string(),
  managedAccountId: number(),
  mrrTier: string(),
  orderWindowStartDate: number(),
  orderWindowEndDate: number(),
  partnerTier: string(),
  newLogo: boolean(),
  numberOfServicesAssociated: number(),
})
