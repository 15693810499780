/**
 * A standard error object returned by the Megaport SDK. Performs standard
 * formatting on errors originating from Axios, but can handle any JavaScript error
 *
 * @param cause An underlying error to extract generic error information from
 */
export default class MegaportError extends Error {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  handled: boolean
  requestedUrl?: string
  requestSent: boolean
  status?: number

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(cause: any) {
    super(cause.message ?? 'Unknown Megaport SDK error', { cause })

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    Error.captureStackTrace?.(this, MegaportError)

    this.cause ??= cause // Manually set cause property in case we are pre ECMAScript 2022
    this.handled = false
    this.name = 'MegaportError'
    this.requestSent = true

    // Handle errors that originate from an API request
    if (cause.response) {
      this.data = cause.response.data
      this.status = cause.response.status
      this.requestedUrl = cause.response.config.url

      // Update the error message to match the one returned from the API
      if (typeof this.data.error === 'string') {
        this.message = this.data.error
      } else if (typeof this.data.message === 'string') {
        this.message = this.data.message
      }
    } else if (cause.request) {
      // Detect a connection timeout
      if (cause.code === 'ECONNABORTED') {
        this.status = 408
        if (cause.config) {
          this.message = `Timeout when performing ${cause.config.method} on URL ${cause.config.baseURL}${cause.config.url}`
        }
      }

      // The error came from somewhere else, before we could send an Axios request
    } else {
      this.requestSent = false
    }
  }

  /**
   * Execute a callback function against this error, allowing it to be flagged as handled
   * @param callback A callback function returning a boolean indicating if it handled the error
   */
  executeCallback(callback?: ((error: MegaportError) => boolean) | null) {
    if (callback) this.handled = callback(this)
  }
}
