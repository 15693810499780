import { array, boolean, nullable, number, object, optional, string } from 'zod'

import { apiResponse, apiResponseArray } from '../../megaport/schemas'

export const partnerBrandingSchema = apiResponse({
  companyName: nullable(string()),
  logoKey: nullable(string()),
  web: nullable(string()),
})

export const getCompanySettingsSchema = apiResponse({
  consolidatedSettings: array(
    object({
      key: string(),
      value: boolean(),
    }),
  ),
})

const companyGroups = object({
  groups: object({
    BILLING_CONFIGURATION: optional(
      object({
        settings: object({
          AUTO_RENEW_TERM: object({
            key: string(),
            value: boolean(),
            locked: boolean(),
          }),
        }),
      }),
    ),
  }),
})

export const getCompanyBaseSettingsSchema = apiResponse({
  companyOverrides: companyGroups,
  childDefaults: companyGroups,
})

export const companyActivitySchema = apiResponseArray({
  personName: string(), // user name of the person who performed the action
  description: string(), // details of the action
  shortDescription: string(), // name of the action
  eventDate: number(), // API returns the datetime as a number
  userType: string(), // type of the user who performed the action
})
