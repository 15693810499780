<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Missing-DC-"
      transform="translate(-32.000000, -32.000000)">
      <g
        id="DC"
        transform="translate(32.000000, 32.000000)">
        <g
          id="Group"
          fill="#979797"
          fill-rule="nonzero">
          <path
            id="Oval"
            d="M68,0 C105.555363,0 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 0,105.555363 0,68 C0,30.444637 30.444637,0 68,0 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z" />
          <path
            id="Rectangle-Copy-3"
            d="M90.2,32 L90.2,96.4 L53.2,96.4 L53.2,32 L90.2,32 Z M86.199,36 L57.199,36 L57.199,92.399 L86.199,92.399 L86.199,36 Z" />
          <path
            id="Rectangle-Copy-3"
            d="M57,69.8 L57,96.4 L22,96.4 L22,69.8 L57,69.8 Z M53,73.8 L26,73.8 L26,92.4 L53,92.4 L53,73.8 Z" />
          <path
            id="Rectangle-Copy-4"
            d="M113.053846,57.2 L113.053846,96.4 L86.4,96.4 L86.4,57.2 L113.053846,57.2 Z M109.053,61.2 L90.399,61.2 L90.399,92.4 L109.053,92.4 L109.053,61.2 Z" />
          <polygon
            id="Path-20-Copy-11"
            points="65.3057692 60.4855769 65.3057692 63.4855769 60.8634615 63.4855769 60.8634615 60.4855769" />
          <polygon
            id="Path-20-Copy-12"
            points="83.075 60.4855769 83.075 63.4855769 78.6326923 63.4855769 78.6326923 60.4855769" />
          <polygon
            id="Path-20-Copy-13"
            points="74.1903846 60.4855769 74.1903846 63.4855769 69.7480769 63.4855769 69.7480769 60.4855769" />
          <polygon
            id="Path-20-Copy-14"
            points="65.3057692 67.1490385 65.3057692 70.1490385 60.8634615 70.1490385 60.8634615 67.1490385" />
          <polygon
            id="Path-20-Copy-15"
            points="83.075 67.1490385 83.075 70.1490385 78.6326923 70.1490385 78.6326923 67.1490385" />
          <polygon
            id="Path-20-Copy-16"
            points="74.1903846 67.1490385 74.1903846 70.1490385 69.7480769 70.1490385 69.7480769 67.1490385" />
          <polygon
            id="Path-20-Copy-17"
            points="65.3057692 73.8125 65.3057692 76.8125 60.8634615 76.8125 60.8634615 73.8125" />
          <polygon
            id="Path-20-Copy-18"
            points="83.075 73.8125 83.075 76.8125 78.6326923 76.8125 78.6326923 73.8125" />
          <polygon
            id="Path-20-Copy-19"
            points="74.1903846 73.8125 74.1903846 76.8125 69.7480769 76.8125 69.7480769 73.8125" />
          <polygon
            id="Path-20-Copy-20"
            points="65.3057692 80.4759615 65.3057692 83.4759615 60.8634615 83.4759615 60.8634615 80.4759615" />
          <polygon
            id="Path-20-Copy-22"
            points="74.1903846 80.4759615 74.1903846 83.4759615 69.7480769 83.4759615 69.7480769 80.4759615" />
          <polygon
            id="Path-20-Copy-23"
            points="65.3057692 87.1394231 65.3057692 90.1394231 60.8634615 90.1394231 60.8634615 87.1394231" />
          <polygon
            id="Path-20-Copy-24"
            points="83.075 87.1394231 83.075 90.1394231 78.6326923 90.1394231 78.6326923 87.1394231" />
          <polygon
            id="Path-20-Copy-25"
            points="74.1903846 87.1394231 74.1903846 90.1394231 69.7480769 90.1394231 69.7480769 87.1394231" />
          <polygon
            id="Path-20-Copy-21"
            points="83.075 80.4759615 83.075 83.4759615 78.6326923 83.4759615 78.6326923 80.4759615" />
          <polygon
            id="Path-20-Copy-2"
            points="65.3057692 40.0244986 65.3057692 43.0244986 60.8634615 43.0244986 60.8634615 40.0244986" />
          <polygon
            id="Path-20-Copy-3"
            points="83.075 40.0244986 83.075 43.0244986 78.6326923 43.0244986 78.6326923 40.0244986" />
          <polygon
            id="Path-20-Copy-4"
            points="74.1903846 40.0244986 74.1903846 43.0244986 69.7480769 43.0244986 69.7480769 40.0244986" />
          <polygon
            id="Path-20-Copy-5"
            points="65.3057692 47.1586538 65.3057692 50.1586538 60.8634615 50.1586538 60.8634615 47.1586538" />
          <polygon
            id="Path-20-Copy-6"
            points="83.075 47.1586538 83.075 50.1586538 78.6326923 50.1586538 78.6326923 47.1586538" />
          <polygon
            id="Path-20-Copy-7"
            points="74.1903846 47.1586538 74.1903846 50.1586538 69.7480769 50.1586538 69.7480769 47.1586538" />
          <polygon
            id="Path-20-Copy-8"
            points="65.3057692 53.8221154 65.3057692 56.8221154 60.8634615 56.8221154 60.8634615 53.8221154" />
          <polygon
            id="Path-20-Copy-9"
            points="83.075 53.8221154 83.075 56.8221154 78.6326923 56.8221154 78.6326923 53.8221154" />
          <polygon
            id="Path-20-Copy-10"
            points="74.1903846 53.8221154 74.1903846 56.8221154 69.7480769 56.8221154 69.7480769 53.8221154" />
          <polygon
            id="Path-5"
            points="55.5889695 76.0301574 55.6110305 80.0300965 24.8110305 80.1999696 24.7889695 76.2000304" />
          <polygon
            id="Path-20-Copy-31"
            points="97.2230769 64.9278846 97.2230769 67.9278846 92.7807692 67.9278846 92.7807692 64.9278846" />
          <polygon
            id="Path-20-Copy-32"
            points="106.107692 64.9278846 106.107692 67.9278846 101.665385 67.9278846 101.665385 64.9278846" />
          <polygon
            id="Path-20-Copy-33"
            points="97.2230769 71.5913462 97.2230769 74.5913462 92.7807692 74.5913462 92.7807692 71.5913462" />
          <polygon
            id="Path-20-Copy-34"
            points="106.107692 71.5913462 106.107692 74.5913462 101.665385 74.5913462 101.665385 71.5913462" />
          <polygon
            id="Path-20-Copy-35"
            points="97.2230769 78.2548077 97.2230769 81.2548077 92.7807692 81.2548077 92.7807692 78.2548077" />
          <polygon
            id="Path-20-Copy-36"
            points="106.107692 78.2548077 106.107692 81.2548077 101.665385 81.2548077 101.665385 78.2548077" />
          <polygon
            id="Path-20-Copy-37"
            points="97.2230769 84.9182692 97.2230769 87.9182692 92.7807692 87.9182692 92.7807692 84.9182692" />
          <polygon
            id="Path-20-Copy-38"
            points="106.107692 84.9182692 106.107692 87.9182692 101.665385 87.9182692 101.665385 84.9182692" />
        </g>
        <g
          id="Group-10"
          transform="translate(22.000000, 32.000000)">
          <g
            id="Group-2"
            transform="translate(70.780769, 32.317308)" />
        </g>
      </g>
    </g>
  </g>
</template>

<script>
/**
 * Note that this image has hard coded colors, so not accepting the color props.
 */
export default {
  name: 'MissingDC',

  viewBox: '0 0 136 136',
}
</script>
