import { createI18n } from 'vue-i18n'
import { has } from 'lodash'

import { defaultLanguage, defaultMessages } from '@/i18n/languages.ts'

/**
 * The Vue plugin for the Portal's i18n instance. Used for adding i18n to Vue applications.
 *
 * @example app.use(i18nPlugin)
 */
const i18nPlugin = createI18n({
  legacy: false, // Set to false to use Composition API
  globalInjection: true, // Enables global injection of $t
  locale: defaultLanguage.iso, // The user's actual language needs to be set later as that process is async
  fallbackLocale: defaultLanguage.iso,
  messages: { [defaultLanguage.iso]: defaultMessages },
  warnHtmlMessage: false,
  fallbackWarn: false,
  missing: (locale, key) => {
    // Only log the standard warning if the key does not exist in the locale or the fallback language.
    if (!has(defaultMessages, key)) {
      console.warn(`[intlify] Not found '${key}' key in '${locale}' locale messages.`)
    }
    return key
  },
  // postTranslation: str => `X${str}X` // Uncomment this to display everything translated with a prefix & suffix of X
})

/**
 * The Portal's i18n instance. Allows access to translation functions outside of a Vue context. Under the hood this is
 * just the `global` property of {@link i18nPlugin}.
 *
 * @example i18n.t('general.ok') // Returns 'OK' when current language is English
 */
const i18n = i18nPlugin.global

export default i18n
export { i18nPlugin }
