import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import { getDealsSchema } from './schemas/partnerVantage'
import type { GetDealsParams } from './types/partnerVantage'

export default class PartnerVantageResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   *
   * @param companyUid
   * @param settings Additional settings to adjust the generated API request
   * @param params params for including invalid deals
   * @returns
   */
  async deals(companyUid = '', params?: GetDealsParams, settings?: APISettings) {
    const response = await this.#api.get(`/v2/vantage/managedCompany/${companyUid}/deal`, settings, {
      params,
      schema: getDealsSchema,
    })
    return response.body.data || response.body
  }
}
