import type { ZodTypeAny } from 'zod'

/*********************************************
 * Supporting resource classes
 *********************************************/
import APIKeysResource from '../resources/apiKeys'
import CompaniesResource from '../resources/companies'
import CompanyResource from '../resources/company'
import EmployeeResource from '../resources/employee'
import EmploymentResource from '../resources/employment'
import InvoicesResource from '../resources/invoices'
import ManagedCompaniesResource from '../resources/managedCompanies'
import MarketplaceResource from '../resources/marketplace'
import MarketsResource from '../resources/markets'
import MCRPrefixListsResource from '../resources/mcrPrefixList'
import MCRPacketFilterListsResource from '../resources/mcrPacketFilterLists'
import MCRRouteDiagnosticsResource from '../resources/diagnostics'
import NetworkDesignResource from '../resources/networkDesign'
import NotificationPreferencesResource from '../resources/notificationPreferences'
import PartnerVantageResource from '../resources/partnerVantage'
import PriceBookResource from '../resources/pricebook'
import ProductResource from '../resources/product'
import ProfileResource from '../resources/profile'
import ServiceOrderResource from '../resources/serviceOrder'
import ServicesStatusResource from '../resources/servicesStatus'
import SSOResource from '../resources/sso'
import StripeResource from '../resources/stripe'
import G2PasscodeResource from '../resources/g2passcode'

import type {
  APICall,
  APISettings,
  ApproveVXCPayload,
  AwsOnboardingPayload,
  Credentials,
  ListsCache,
  LoginPayload,
  PriceBookCache,
  ProvisioningStatus,
  RegisterPayload,
  TwoFactorLogin,
  xAppHeader,
} from './types'
import {
  credentialsSchema,
  enabledMarketsSchema,
  federateSchema,
  ixPeersSchema,
  ixTypesSchema,
  locationsListSchema,
  marketsListSchema,
  partnerPortsListSchema,
  productsSchema,
  promoCodeSchema,
  telemetryDataTypesSchema,
  supplierTaxRulesSchema,
  productBandwidthsSchema,
  entitlementsSchema,
  locationsRttSchema,
} from './schemas'
import { base64Encode } from './utils'
import MegaportAPI from './api'

/**
 * Generic interface for making requests to Megaport's API. Handles authenticating a user
 */
export default class MegaportSDK {
  #api: MegaportAPI
  #credentials: Credentials | null
  static #instance?: MegaportSDK
  #listsCache: Partial<ListsCache>
  #priceBookCache: PriceBookCache

  /**
   * @param baseurl The Megaport API URL you want to connect to
   * @param includeCredentials Allow cross-domain cookies to be set from API responses
   * @param xApp The internal service name that is sent with every request to the Megaport API
   */
  constructor(baseurl: string, includeCredentials?: boolean, xApp?: xAppHeader) {
    this.#api = new MegaportAPI(baseurl, includeCredentials)
    this.#credentials = null
    this.#api.xApp = xApp
    MegaportSDK.#instance = this
    // For caching results where they won't change, e.g. lists
    this.#listsCache = {}
    this.#priceBookCache = new Map()
  }

  //////////////////////////////////////////////////
  //#region GETTERS / SETTERS

  get api() {
    return this.#api
  }

  /** Generic callback for all errors raised while making an API request. */
  get apiErrorCallback() {
    return this.api.errorCallback
  }
  set apiErrorCallback(value) {
    this.api.errorCallback = value
  }

  /** The Megaport API URL */
  get baseurl() {
    return this.api.config.baseURL!
  }

  get credentials() {
    return this.#credentials
  }

  /** Used to submit requests on behalf of a managed company. Set to the company's UUID. */
  get effectiveUid() {
    return this.api.callContext
  }
  set effectiveUid(value) {
    this.api.callContext = value
  }

  /**
   * Grabs the value of a company configuration flag for the currently authenticated user
   * @param setting The setting flag to check
   * @returns the value of the the passed in setting flag, or false if the user doesn't have it
   */
  getSettingFlag(setting: string) {
    const consolidatedSetting = this.credentials?.companyConfiguration.consolidatedSettings.find(
      ({ key }) => key === setting,
    )
    return consolidatedSetting ? consolidatedSetting.value : false
  }

  /**
   * Allows checking if the currently authenticated user has a specific feature flag
   * @param feature The feature flag to check
   * @returns boolean indicating if the authenticated user has the passed in feature flag
   */
  hasFeatureFlag(feature: string) {
    return this.credentials?.featureFlags?.includes(feature) ?? false
  }

  /** Are cross-domain cookies allowed to be set from API responses */
  get includeCredentials() {
    return this.api.config.withCredentials!
  }

  static get instance() {
    if (MegaportSDK.#instance) {
      return MegaportSDK.#instance
    }
    throw new ReferenceError('No singleton instance available for MegaportSDK')
  }

  /** Generic callback for all schema parsing errors. Requests still return even if there is a parsing error. */
  get parsingErrorCallback() {
    return this.api.parsingErrorCallback
  }
  set parsingErrorCallback(value) {
    this.api.parsingErrorCallback = value
  }

  /**
   * Clear out the lists cache, forcing future lists requests to call the API again
   */
  resetListsCache() {
    this.#listsCache = {}
  }

  /**
   * Clear the Pricebook cache, forcing any cached prices to be looked up on the API again
   */
  resetPriceBookCache() {
    this.#priceBookCache.clear()
  }

  /**
   * Saves the returned credentials to memory, and sets the relevant access tokens on MegaportAPI
   */
  #setCredentials(value: Credentials | null) {
    this.#credentials = value

    // Clear out the access tokens if we're clearing our credentials
    if (value == null) {
      this.api.authToken = null
      this.api.bearerToken = null

      // Otherwise get the access tokens from our newly retrieved credentials
    } else {
      this.api.authToken = value.session
      this.api.bearerToken = value.oAuthToken?.accessToken
    }
  }

  /**
   * Clear credentials and tokens
   */
  clearSession() {
    this.#setCredentials(null)
  }
  //#endregion
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  //#region AUTHENTICATION API CALLS

  /**
   * Authorize the user given the data in the object, and call back to either the success or fail function.
   *
   * @param payload The login details of a user. Supports username/password, SSO, and preexisting session tokens
   * @param settings Additional settings to adjust the generated API request
   * @returns A credentials object containing access information about the current user
   */
  async auth(payload: LoginPayload, settings?: APISettings) {
    let method: 'get' | 'post' = 'post'
    let url = '/v3/login'
    const options: APICall<Record<string, unknown>, typeof credentialsSchema> = {
      authenticated: false,
      schema: credentialsSchema,
    }

    // Normal login
    if ('username' in payload && 'password' in payload) {
      const { username, password, oneTimePassword, target_username, resetPassword } = payload
      const encodedPassword = base64Encode(password)
      options.data = { username, encodedPassword }

      if (oneTimePassword) options.data.oneTimePassword = oneTimePassword
      if (resetPassword) options.data.resetPassword = resetPassword
      if (target_username) options.data.target_username = target_username

      // Single sign-on
    } else if ('network' in payload) {
      const { access_token, id_token, network, oneTimePassword } = payload

      // Use the new idp endpoint for cognito
      if (network === 'cognito') {
        url = `/v2/idp/login`
      } else {
        url = `/v2/social/login/${network}`
      }

      options.data = {
        access_token,
        id_token,
      }

      if (oneTimePassword) {
        options.data.oneTimePassword = oneTimePassword
      }

      // Reloading user profile
    } else {
      // NOTE: For DLR we just want to trigger the /me endpoint with the X-Auth-Token header
      // in the request. This will need to be revisited before the old tokens are turned off.
      url = '/v2/me'
      method = 'get'
      options.authenticated = true

      if ('session' in payload) {
        // Reauthenticate using Session UUID
        this.api.authToken = payload.session
      } else {
        // Reauthenticate using JWT
        this.api.bearerToken = payload.accessToken
      }
    }

    // Send login request to API
    const response = await this.api[method](url, settings, options)

    // If it went through successfully, save the credentials locally for reuse
    this.#setCredentials(response.body.data)

    return response.body.data
  }

  /**
   * Confirm newly signed up user using details from confirmation email
   *
   * @param username User username
   * @param confirmationCode Confirmation code from cognito
   * @param email User email address
   * @param clientId Cognito client ID
   * @param settings Additional settings to adjust the generated API request
   */
  async confirmSignup(
    username: string,
    confirmationCode: string,
    email: string,
    clientId: string,
    settings?: APISettings,
  ) {
    const data = {
      username,
      confirmationCode,
      email,
      clientId,
    }
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.jpost('/v2/auth/users/confirmSignup', settings, { data, authenticated: false })
    this.#setCredentials(null)
  }

  /**
   * Check SSO status for email address, returns either NATIVE or SSO
   *
   * @param email Email address to check
   * @param settings Additional settings to adjust the generated API request
   */
  async federate(email: string, settings?: APISettings) {
    const data = {
      email,
    }
    // If this fails, an exception will be thrown. Otherwise we succeeded
    const response = await this.api.jpost('/v2/auth/federate', settings, {
      data,
      authenticated: false,
      schema: federateSchema,
    })
    this.#setCredentials(null)

    return response.body.data
  }

  /**
   * Used with 2FA where it will be called with an object containing the password and oneTimePassword.
   * User is already logged in by this stage, so auth methods will already have run.
   *
   * @param data The username, password, and OTP of the user authenticating
   * @param settings Additional settings to adjust the generated API request
   * @returns A credentials object containing access information about the current user
   */
  async inAuth(data: TwoFactorLogin, settings?: APISettings) {
    const response = await this.api.post('/v3/login', settings, {
      data,
      authenticated: false,
      schema: credentialsSchema,
    })
    this.#setCredentials(response.body.data)
    return response.body.data
  }

  /**
   * Logout the currently authenticated user
   * @param settings Additional settings to adjust the generated API request
   */
  async logout(settings?: APISettings) {
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.get('/v2/logout', settings)
    this.#setCredentials(null)
  }

  /**
   * Request a password reset token to be emailed to you
   * @param emailOrUsername An email/username of a user to send a reset request to
   * @param settings Additional settings to adjust the generated API request
   */
  async passwordRequest(emailOrUsername: string, settings?: APISettings) {
    const data = {
      email: emailOrUsername,
      emailOrUsername,
    }
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.post('/v2/password/reset/request', settings, { data, authenticated: false })
    this.#setCredentials(null)
  }

  /**
   * Reset the password by supplying user's email, the token that was emailed
   * to them through a passwordRequest call, and their new password.
   *
   * @param emailOrUsername The email/username of the user having their password reset
   * @param resetToken The reset token sent to the user in an email
   * @param password The new password for the user
   * @param settings Additional settings to adjust the generated API request
   */
  async passwordReset(emailOrUsername: string, resetToken: string, password: string, settings?: APISettings) {
    const data = {
      email: emailOrUsername,
      emailOrUsername,
      resetToken,
      encodedPassword: base64Encode(password),
    }
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.post('/v2/password/reset', settings, { data, authenticated: false })
    this.#setCredentials(null)
  }

  /**
   * Register a new user in the system.
   * @param details Details about the user
   * @param settings Additional settings to adjust the generated API request
   */
  async register(details: RegisterPayload, settings?: APISettings) {
    const { password, ...otherParams } = details
    const data = {
      encodedPassword: base64Encode(password),
      ...otherParams,
    }

    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.post('/v2/social/registration', settings, { data, authenticated: false })
    this.#setCredentials(null)
  }

  /**
   * Re-trigger the confirmation email for a new user
   * or for changing an existing users email address
   *
   * @param email Email address to send email to
   * @param settings Additional settings to adjust the generated API request
   */
  async retriggerConfirmationEmail(email: string, settings?: APISettings) {
    const data = {
      email,
    }
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.jpost('/v2/auth/users/retriggerConfirmationEmail', settings, { data, authenticated: false })
    this.#setCredentials(null)
  }

  //#endregion
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  //#region OTHER API CALLS

  /**
   * Respond to a VXC approval request
   *
   * @param orderUid The UID of the VXC order
   * @param data Is the request approved/denied and what VLAN should it use if approved
   * @param settings Additional settings to adjust the generated API request
   */
  async approveVxc(orderUid: string, data: ApproveVXCPayload, settings?: APISettings) {
    // If this fails, an exception will be thrown. Otherwise we succeeded
    await this.api.put(`/v2/order/vxc/${orderUid}`, settings, { data })
  }

  /**
   * Get the list of entitlements for a company.
   * @param productType The product type to get entitlements for
   * @param status The status of the entitlements to get
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of entitlements
   */
  async awsEntitlements(productType?: string, status?: string, settings?: APISettings) {
    const params = { productType, status }
    const response = await this.api.get('/portal/v1/entitlements/aws', settings, { params, schema: entitlementsSchema })
    return response.body.data
  }

  /**
   * Onboard a user with AWS.
   * @param data The onboarding information
   * @param settings Additional settings to adjust the generated API request
   */
  async awsOnboarding(data: AwsOnboardingPayload, settings?: APISettings) {
    await this.api.jpost('/portal/v1/entitlements/aws/onboarding', settings, { data, authenticated: false })
  }

  /**
   * Filtered list of enabled markets
   * @param settings Additional settings to adjust the generated API request
   * @returns List of enabled market ID's for the current user
   */
  async enabledMarkets(settings?: APISettings) {
    const params = {
      marketEnabled: true,
    }
    const response = await this.api.get('/v2/locations', settings, { params, schema: enabledMarketsSchema })
    return response.body.data
  }

  /**
   * Returns a list of peers attached to IX in order of most data
   *
   * @param ixProductUid The UID of the IX being queried
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of peers on the IX and their traffic volume
   */
  async ixPeers(ixProductUid: string, settings?: APISettings) {
    const response = await this.api.get(`/v2/product/ix/${ixProductUid}/telemetry/flow/peers`, settings, {
      schema: ixPeersSchema,
    })
    return response.body
  }

  /**
   * List all the IX types at the specified location. Location Id is retrieved from the locations endpoint
   *
   * @param locationId The location to check for IX types
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of IX types at the given location
   */
  async ixTypes(locationId: number, settings?: APISettings) {
    const params = {
      locationId,
    }
    const response = await this.api.get('/v2/product/ix/types', settings, { params, schema: ixTypesSchema })
    return response.body.data
  }

  /**
   * Get a list containing RTT (Round-Trip Time) destination data for a location based on provided parameters
   * @param srcLocation Location Id for the aEnd service
   * @param year Year to get the RTT values for
   * @param month Month to get the RTT values for
   * @param settings Additional settings to adjust the generated API request
   * @returns A list containing RTT data for all relevant destinations
   */
  async locationsRTT(srcLocation: number, year: number, month: number, settings?: APISettings) {
    const params = {
      srcLocation,
      year,
      month,
    }
    const response = await this.api.get('v2/locations/rtt', settings, { params, schema: locationsRttSchema })
    return response.body.data
  }

  /**
   * Get a list of available fixed bandwidths for Alibaba ports
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of all valid bandwidths for Alibaba partner ports
   */
  async lists(name: 'alibaba', settings?: APISettings): Promise<ListsCache['alibaba']>
  /**
   * Get a list of available fixed bandwidths for AWSHC ports
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of all valid bandwidths for AWSHC partner ports
   */
  async lists(name: 'awshc', settings?: APISettings): Promise<ListsCache['awshc']>
  /**
   * Get a list of megaport service locations
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of megaport locations, including address information and megaport service availability
   */
  async lists(name: 'locations', settings?: APISettings): Promise<ListsCache['locations']>
  /**
   * Get a list of the supported market suppliers
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of all megaport billing markets
   */
  async lists(name: 'markets', settings?: APISettings): Promise<ListsCache['markets']>
  /**
   * Get a list of partner megaports
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of all marketplace or cloud ports available for creating a VXC against
   */
  async lists(name: 'partnerPorts', settings?: APISettings): Promise<ListsCache['partnerPorts']>

  async lists(name: keyof ListsCache, settings?: APISettings) {
    if (typeof this.#listsCache[name] !== 'object') {
      let url: string
      let schema: ZodTypeAny

      switch (name) {
        case 'alibaba':
          url = '/v2/secure/alibaba'
          schema = productBandwidthsSchema
          break
        case 'awshc':
          url = '/v2/secure/awshc'
          schema = productBandwidthsSchema
          break
        case 'locations':
          url = '/v2/locations'
          schema = locationsListSchema
          break
        case 'markets':
          // Gets a list of the supported market suppliers
          url = '/v2/supplier'
          schema = marketsListSchema
          break
        case 'partnerPorts':
          url = '/v2/dropdowns/partner/megaports'
          schema = partnerPortsListSchema
          break
        default:
          throw new TypeError(
            'Invalid list name. Valid options: "alibaba", "awshc", "locations", "markets", "partnerPorts"',
          )
      }

      const response = await this.api.get(url, settings, { schema })
      this.#listsCache[name] = response.body.data
    }

    return this.#listsCache[name]
  }

  /**
   * Returns a list of all products for the authenticated user's company, and
   * their associated services, with the option to filter out ports by provisioning status.
   * Although the endpoint accepts a second argument (incResources) to specify whether resources should be included,
   * there's currently no need to make this a parameter of this function since at the moment resources are always expected.
   *
   * @param provisioningStatus An array of provisioning statuses to filter out the results by
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of all the current company's Megaports/MCRs/MVEs and their associated VXCs and IXs
   */
  async ports(provisioningStatus: ProvisioningStatus[] = [], settings?: APISettings) {
    const params = {
      provisioningStatus,
      incResources: true,
    }
    const response = await this.api.get('/v2/products', settings, { params, schema: productsSchema })
    return response.body.data
  }

  /**
   * Retrieve the information about a promo code. Will fail if the
   * promo code doesn't exist, or return data about it if it does
   *
   * @param promoCode The promo code to check
   * @param settings Additional settings to adjust the generated API request
   * @returns Data about the given promo code, including a
   * description and a list of product types it is valid for
   */
  async promoCode(promoCode: string, settings?: APISettings) {
    const params = {
      promoCode,
    }
    const response = await this.api.get('/v2/promocode', settings, { params, schema: promoCodeSchema })
    return response.body.data
  }

  /**
   * Returns a supplier tax rule data
   *
   * @param supplierId The ID of the Supplier or Country being queried
   * @param settings Additional settings to adjust the generated API request
   * @returns An object of tax rules of supplier market
   */
  async supplierTaxRules(supplierId: string, settings?: APISettings) {
    const response = await this.api.get(`/v2/dropdowns/suppliers/${supplierId}/taxRules`, settings, {
      schema: supplierTaxRulesSchema,
    })
    return response.body
  }

  /**
   * Get the telemetry metadata for all the different types of service.
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of telemetry data types that can be queried
   */
  async telemetryDataTypes(settings?: APISettings) {
    const response = await this.api.get('/v2/products/telemetry', settings, { schema: telemetryDataTypesSchema })
    return response.body
  }

  /**
   * Get the telemetry flow metadata for all the different types of service.
   * @param settings Additional settings to adjust the generated API request
   * @returns A list of telemetry data types that can be queried
   */
  async telemetryFlowDataTypes(settings?: APISettings) {
    const response = await this.api.get('/v2/products/telemetry/flow', settings, { schema: telemetryDataTypesSchema })
    return response.body
  }

  //#endregion
  //////////////////////////////////////////////////

  //////////////////////////////////////////////////
  //#region RESOURCES

  /**
   * API key operations
   */
  apiKeys() {
    return new APIKeysResource(this.api)
  }

  /**
   * Search all companies
   */
  companies() {
    return new CompaniesResource(this.api)
  }

  /**
   * Company operations
   *
   * @param companyUid
   */
  company(companyUid?: string) {
    // If not passed in, get it from the user credentials.
    return new CompanyResource(this.api, companyUid ?? this.credentials?.companyUid)
  }

  /**
   * For a manager to update details on an employee
   *
   * @param id Employee ID
   */
  employee(id?: number) {
    // If not passed in, fallback to the current user's ID.
    return new EmployeeResource(this.api, id ?? this.credentials?.personId)
  }

  /**
   * Attachment to a company
   */
  employment() {
    return new EmploymentResource(this.api)
  }

  /**
   * Get invoices for either the specified company or the company you are logged in under.
   *
   * @param companyUid
   */
  invoices(companyUid?: string) {
    return new InvoicesResource(this.api, companyUid ?? this.credentials?.companyUid)
  }

  /**
   * Megaport partner managed companies
   */
  managedCompanies() {
    return new ManagedCompaniesResource(this.api)
  }

  /**
   * Megaport exchange
   */
  marketplace() {
    return new MarketplaceResource(this.api)
  }

  /**
   * Billing markets that the company is operating in
   *
   * @param marketId
   */
  markets(marketId?: number) {
    return new MarketsResource(this.api, marketId)
  }

  /**
   * MCR Prefix Lists
   * @param productUid MCR product UID
   */
  mcrPrefixLists(productUid: string) {
    return new MCRPrefixListsResource(this.api, productUid)
  }

  /**
   * MCR Packet Filter Lists
   * @param productUid MCR product UID
   */
  mcrPacketFilterLists(productUid: string) {
    return new MCRPacketFilterListsResource(this.api, productUid)
  }

  /**
   * Route diagnostics
   * @param productUid The UUID for the MCR
   */
  mcrRouteDiagnostics(productUid: string) {
    return new MCRRouteDiagnosticsResource(this.api, productUid)
  }

  /**
   * Returns network design operations
   */
  networkDesign() {
    return new NetworkDesignResource(this.api)
  }

  /**
   * Get and set notification preferences
   */
  notificationPreferences() {
    return new NotificationPreferencesResource(this.api)
  }

  /**
   * Megaport vantage partner
   */
  partnerVantage() {
    return new PartnerVantageResource(this.api)
  }

  /**
   * Pricing information. Note that since pricing should not change during a customer session
   * (and rarely changes at all), any looked up pricing is cached and the cached value used
   * where possible instead of doing another lookup.
   */
  priceBook() {
    return new PriceBookResource(this.api, this.#priceBookCache)
  }

  /**
   * Get information on the various products
   *
   * @param productUid
   */
  product(productUid: string) {
    return new ProductResource(this.api, productUid)
  }

  /**
   * Work with the user's profile.
   */
  profile() {
    return new ProfileResource(this.api)
  }

  /**
   * Order a service or services. This allows the shopping cart to be stored on the server
   * and deleted or deployed.
   *
   * @param serviceOrderUid Required for delete or deploy. See methods for more info
   * @param companyUid Used for save and get operations, but automatically overrides to user's company if not specified
   */
  serviceOrder(serviceOrderUid?: string, companyUid?: string) {
    return new ServiceOrderResource(this.api, serviceOrderUid, companyUid ?? this.credentials?.companyUid)
  }

  /**
   * Services Statuses
   */
  servicesStatus() {
    return new ServicesStatusResource(this.api)
  }

  /**
   * SSO configuration management
   */
  sso() {
    return new SSOResource(this.api)
  }

  /**
   * Returns object for dealing with all aspects of stripe payments.
   *
   * Note that when we talk about 'supplier' below, this is the Megaport
   * branch that supplies the services and bills the user.
   */
  stripe() {
    return new StripeResource(this.api)
  }

  /**
   * G2 Passcode operations
   */
  g2Passcode() {
    return new G2PasscodeResource(this.api)
  }

  //#endregion
  //////////////////////////////////////////////////
}
