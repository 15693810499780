import { GoogleTagManager } from '@/third-party-integrations/googleTagManager'
import { Sentry } from '@/third-party-integrations/sentry'
import { GoogleLogin } from '@/third-party-integrations/googleLogin'
import { Stripe } from '@/third-party-integrations/stripe'
import { AWSAmplify } from '@/third-party-integrations/awsAmplify'
import { SalesforceChat } from '@/third-party-integrations/salesforceEmbeddedMessaging'
import { PostHog } from '@/third-party-integrations/postHog'
import { OneTrust } from '@/third-party-integrations/oneTrust'
import { Segment } from '@/third-party-integrations/segment'
import { G2Review } from '@/third-party-integrations/g2Review'

const integrations = {
  sentry: new Sentry(),
  oneTrust: new OneTrust(),
  salesforceChat: new SalesforceChat(),
  googleTagManager: new GoogleTagManager(),
  googleLogin: new GoogleLogin(),
  awsAmplify: new AWSAmplify(),
  stripe: new Stripe(),
  postHog: new PostHog(),
  segment: new Segment(),
  g2Review: new G2Review(),
}

/**
 * @param {Object} appConfiguration The environment settings loaded from environment.js
 */
export function setupIntegrations(appConfiguration) {
  // Sentry: make this the first to load so that if other integrations need to report errors, they can
  integrations.sentry.init(appConfiguration)
  // OneTrust
  integrations.oneTrust.init()
  // Salesforce Chat
  integrations.salesforceChat.init(appConfiguration)
  // Google Tag Manager
  integrations.googleTagManager.init(appConfiguration)
  // Google Login
  integrations.googleLogin.init()
  // AWS Amplify
  integrations.awsAmplify.init(appConfiguration)
  // Stripe
  integrations.stripe.init()
  // PostHog
  integrations.postHog.init(appConfiguration)
  // Segment
  integrations.segment.init(appConfiguration)
  // G2
  integrations.g2Review.init(appConfiguration)
}

export default integrations
