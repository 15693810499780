import type { APISettings } from '../megaport/types'
import type MegaportAPI from '../megaport/api'

import type { GenerateAPIKeyPayload, UpdateAPIKeyPayload } from './types/apiKeys'
import { getKeysSchema, generateKeySchema, updateKeySchema, getHistorySchema } from './schemas/apiKeys'

/**
 * Generation and management of API keys
 */
export default class APIKeysResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get a list of all the keys for the logged in user.
   *
   * @param key Unique key identifier
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getKeys(key = '', settings?: APISettings) {
    const response = await this.#api.get(`/v2/auth/apikeys/${key}`, settings, { schema: getKeysSchema })
    return response.body.data
  }

  /**
   * Generate a new API key given the name, role, resource server URL and expiry.
   * Returns an API key with the clientId being the key, and the secret.
   *
   * @param data Details for the new API key to be generated
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async generateKey(data: GenerateAPIKeyPayload, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/auth/apikeys', settings, { data, schema: generateKeySchema })
    return response.body.data
  }

  /**
   * Update the key with a new name and expiry
   *
   * @param key Unique key identifier
   * @param data Updated details for API key
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async updateKey(key: string, data: UpdateAPIKeyPayload, settings?: APISettings) {
    const response = await this.#api.put(`/v2/auth/apikeys/${key}`, settings, { data, schema: updateKeySchema })
    return response.body.data
  }

  /**
   * Delete the specified key
   *
   * @param key Unique key identifier
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async deleteKey(key: string, settings?: APISettings) {
    await this.#api.delete(`/v2/auth/apikeys/${key}`, settings)
  }

  /**
   * Get the history for the specified key
   *
   * @param key Unique key identifier
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getHistory(key: string, settings?: APISettings) {
    const response = await this.#api.get(`/v2/auth/apikeys/${key}/history`, settings, { schema: getHistorySchema })
    return response.body.data
  }
}
