import store from '@/store/Store.js'

export class GoogleLogin {
  #disabled = import.meta.env.VITE_DISABLE_INTEGRATION_GOOGLE_LOGIN === 'true'
  #storedLanguage = localStorage.getItem('mp_lang_region') || localStorage.getItem('mp_lang') || 'en-US'

  /**
   * Use this to install the script at startup.
   */
  init(language) {
    if (this.#disabled) return

    const languageParam = `?hl=${language || this.#storedLanguage}`

    const script = document.createElement('script')
    script.id = 'google-login-client'
    script.src = `https://accounts.google.com/gsi/client${languageParam}`
    script.async = true
    script.defer = true
    script.onload = () => {
      store.commit('Auth/setGoogleSignInReady', true)
    }

    document.body.appendChild(script)
  }
}
