import type { APISettings } from '../megaport/types'
import type MegaportAPI from '../megaport/api'

/**
 * Managed Company operations
 */
export default class ManagedCompaniesResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * As a Megaport Partner create a new managed company in the Megaport system
   * ..../managedCompanies
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createManagedCompany(data: any, settings?: Omit<APISettings, 'useEffectiveUid'>) {
    const usedSettings = {
      ...settings,
      useEffectiveUid: false,
    }
    const response = await this.#api.jpost('/v2/managedCompanies', usedSettings, { data })
    return response.body.data || response.body
  }

  /**
   * Update the managed company information. The object can contain any of the fields
   * as read from the get (then) operation below.
   * @param managedCompanyUid
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(managedCompanyUid: string, data: any, settings?: Omit<APISettings, 'useEffectiveUid'>) {
    const usedSettings = {
      ...settings,
      useEffectiveUid: false,
    }
    const response = await this.#api.put(`/v2/managedCompanies/${managedCompanyUid}`, usedSettings, { data })
    return response.body
  }

  /**
   * Get the information about the company.
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getManagedCompanies(settings?: Omit<APISettings, 'useEffectiveUid'>) {
    const usedSettings = {
      ...settings,
      useEffectiveUid: false,
    }
    const response = await this.#api.get('/v2/managedCompanies', usedSettings)
    return response.body.data || response.body
  }
}
