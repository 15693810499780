import sdk from '@/api-sdk'
import i18n from '@/i18n/i18n.ts'
import { AUTO_RENEW_SETTING_KEY, PUBLIC_COMPANY_UID } from '@/Globals.js'
import captureSentryError from '@/utils/CaptureSentryError.js'

// Initial state
const coreState = {
  data: {
    companyUid: null,
    markets: [],
    techSupportEmail: null,
    techSupportPhone: null,
  },
  // The default auto-renew values for the company
  autoRenewTerms: {
    // The consolidated setting (used when ordering new services)
    consolidatedSettings: null,
    // The override setting for the company (used to set the default for direct and partner accounts)
    companyOverrides: null,
    // The child default setting for the company (used to set the default for managed accounts)
    childDefaults: null,
  },
  partnerBranding: null,
  isLoading: false,
  companyReady: false,
}

const coreGetters = {
  /**
   * They are not deemed to have a company if there is no id or the id is
   * the default company or the trading name is blank.
   */
  hasCompany: state => {
    const isNotPublicCompany = state.data.companyUid !== null && state.data.companyUid !== PUBLIC_COMPANY_UID
    const isNotBlankTradingName = state.data.tradingName !== null && state.data.tradingName !== ''

    return isNotPublicCompany && isNotBlankTradingName
  },
  companySupplyRegions: state => {
    return state.data.supplies ? state.data.supplies.map(s => s.region) || [] : []
  },
  companyId: state => state.data.companyId,
  companyUid: (state, getters, rootState) => state.data.companyUid || rootState.Auth.data.companyUid,
  techSupportDetails: state => {
    return {
      techSupportEmail: state.data.techSupportEmail ?? null,
      techSupportPhone: state.data.techSupportPhone ?? null,
    }
  },
  megaportContactDetails: state => ({
    accountManager: {
      name: state.data.accountManager?.name ?? null,
      email: state.data.accountManager?.email ?? null,
      active: state.data.accountManager?.active ?? false,
    },
    customerSuccessManager: {
      name: state.data.customerSuccessManager?.name ?? null,
      email: state.data.customerSuccessManager?.email ?? null,
      active: state.data.customerSuccessManager?.active ?? false,
    },
  }),
  autoRenewTerms: state => state.autoRenewTerms,
  tradingName: state => state.data.tradingName,
  companyData: state => state.data,
}

const actions = {
  /**
   * Refresh the local data with the data from the API
   *
   * @param {object} context (store context)
   */
  syncCompany(context) {
    if (!context.rootState.Auth.data.accessToken && !context.rootState.Auth.data.session) {
      return
    }
    context.commit('setLoading', true)
    sdk.instance
      .company()
      .get()
      .then(res => {
        context.commit('setCompany', res)
        if (!context.getters.hasCompany) {
          context.commit('setTradingName', null)
        }
      })
      .catch(e => {
        // TODO: Improve error processing
        console.warn(e)
      })
      .finally(() => {
        context.commit('setLoading', false)
      })
  },
  /**
   * Update current company
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  async updateCompany(context, payload) {
    context.commit('setLoading', true)
    try {
      await sdk.instance.company().update(payload)
      context.commit('updateCompany', payload)
    } catch (error) {
      captureSentryError(error)
      return Promise.reject(error)
    } finally {
      context.commit('setLoading', false)
    }
  },
  /**
   * Update the company data via the API and mutate the
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  postCompany(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      const accessToken = context.rootGetters['Auth/accessToken']
      // If the user's company is the default company, then upgrade them
      // to an actual company, and store the data in the local store.
      if (context.rootState.Auth.data.companyUid === PUBLIC_COMPANY_UID) {
        sdk.instance
          .company()
          .upgrade({
            tradingName: payload.tradingName,
          })
          .then(res => {
            context.commit(
              'Notifications/notifyMessage',
              {
                title: i18n.t('general.success-creating', {
                  thing: i18n.t('menu.company-profile'),
                }),
                message: i18n.t('authentication.registered-company', { name: res.legalName }),
                type: 'success',
              },
              { root: true },
            )

            // move cart content to the new companyUid
            context.dispatch('Services/moveCartContents', res.companyUid, { root: true })
            // We need to force the user to logout as a new JWT token has been generated with the new company
            context.dispatch('Auth/logout', null, { root: true })
            return resolve()
          })
          .catch(err => {
            console.error(err)
            // TODO: Improve error processing
            if (err.data?.message) {
              context.commit(
                'Notifications/notifyMessage',
                {
                  title: i18n.t('general.error-creating', {
                    thing: i18n.t('menu.company-profile'),
                  }),
                  message: err.data.message,
                  type: 'error',
                },
                { root: true },
              )
            }

            return reject(err)
          })
      } else {
        sdk.instance
          .company()
          .update({
            tradingName: payload.tradingName,
          })
          .then(() => {
            context.commit('setCompany', payload)
            context.dispatch('Auth/login', { payload: { accessToken } }, { root: true }).then(() => {
              context.commit(
                'Notifications/notifyMessage',
                {
                  title: i18n.t('general.success-updating', {
                    thing: i18n.t('menu.company-profile'),
                  }),
                  message: i18n.t('authentication.updated-company', {
                    name: payload.tradingName,
                  }),
                  type: 'success',
                },
                { root: true },
              )

              return resolve()
            })
          })
          .catch(err => {
            // Only show an error if the error is unhandled
            if (!err.handled) {
              context.commit(
                'Notifications/notifyMessage',
                {
                  title: i18n.t('general.error-updating', {
                    thing: i18n.t('menu.company-profile'),
                  }),
                  message: err.data.message,
                  type: 'error',
                },
                { root: true },
              )
            }
            return reject(err)
          })
      }
    })
  },
  /**
   * Update the company Tech Support Details via the API and mutate the
   * @param {object} context (store context)
   * @param {*} payload - the company data to store
   */
  updateTechSupportDetails(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('setLoading', true)
      sdk.instance
        .company()
        .update({
          techSupportEmail: payload.techSupportEmail,
          techSupportPhone: payload.techSupportPhone,
        })
        .then(() => {
          context.commit('updateCompany', payload)
          return resolve()
        })
        .catch(err => {
          return reject(err)
        })
        .finally(() => {
          context.commit('setLoading', false)
        })
    })
  },
  async loadPartnerBrandingProfile(context) {
    context.commit('setLoading', true)
    try {
      const data = await sdk.instance.company().getPartnerBrandingProfile()

      context.commit('setPartnerBranding', data)
    } catch (error) {
      captureSentryError(error)
      throw error
    } finally {
      context.commit('setLoading', false)
    }
  },
  clearPartnerBranding(context) {
    context.commit('setPartnerBranding', null)
  },
  /**
   * Fetch and commit the current company's auto-renew setting from the API
   * @param {Object} getters The getters object for the store
   * @param {Function} commit The commit function for the store
   * @param {boolean} isBaseSettings Whether to fetch using the base settings endpoint or not.
   *  Base settings is used when updating company settings.
   * @param {boolean} isUpdatingManagedAccounts Whether to use the auto-renew setting for updating all
   *  managed accounts or not. Only used when isBaseSettings is true.
   * @param {string} companyUid The company UID to fetch the auto-renew setting for
   */
  async fetchCompanyAutoRenewSetting(
    { commit, getters },
    { isBaseSettings = false, isUpdatingManagedAccounts = false, companyUid = '' } = {},
  ) {
    try {
      const sdkFunctionName = isBaseSettings ? 'getCompanyBaseSettings' : 'getCompanySettings'
      const response = await sdk.instance.company(companyUid || getters.companyUid)[sdkFunctionName]()

      if (isBaseSettings) {
        // If we are fetching the base settings, we need to find the correct setting based on the account type
        const fieldName = isUpdatingManagedAccounts ? 'childDefaults' : 'companyOverrides'
        const autoRenewTermValue = response[fieldName].groups.BILLING_CONFIGURATION.settings.AUTO_RENEW_TERM.value
        commit('setAutoRenewTerm', { key: fieldName, value: autoRenewTermValue })
      } else {
        // If we are fetching the consolidated settings, we only need to find the consolidated setting
        const autoRenewTermConsolidatedSetting = response.consolidatedSettings.find(
          setting => setting.key === AUTO_RENEW_SETTING_KEY,
        )

        // This should not happen, but just in case
        if (!autoRenewTermConsolidatedSetting) {
          throw new Error(`${AUTO_RENEW_SETTING_KEY} setting not found`)
        }

        commit('setAutoRenewTerm', { key: 'consolidatedSettings', value: autoRenewTermConsolidatedSetting.value })
      }
    } catch (error) {
      const errorStr = error.data?.message ?? error
      commit(
        'Notifications/notifyMessage',
        {
          title: i18n.t('company.auto-renew-error'),
          message: errorStr,
          type: 'error',
        },
        { root: true },
      )
      captureSentryError(error)
      console.error(error)
    }
  },
  /**
   * Update the current company's auto-renew setting via the API
   * @param {Function} commit The commit function for the store
   * @param {boolean} updateManagedAccounts Whether to update the auto-renew setting for all managed accounts at once
   * @param {string} companyUid The company UID to update the auto-renew setting for
   * @param {boolean} autoRenewTerm The new auto-renew setting
   */
  async updateCompanyAutoRenewSetting({ commit }, { updateManagedAccounts, companyUid, autoRenewTerm }) {
    const fieldName = updateManagedAccounts ? 'childDefaults' : 'companyOverrides'
    const payload = {
      [fieldName]: [
        {
          key: AUTO_RENEW_SETTING_KEY,
          value: autoRenewTerm,
        },
      ],
    }

    try {
      await sdk.instance.company(companyUid).postCompanySettings(payload)

      commit(
        'Notifications/notifyMessage',
        {
          title: i18n.t('general.success-updating', {
            thing: i18n.t('company.auto-renew-term'),
          }),
          type: 'success',
        },
        { root: true },
      )

      commit('setAutoRenewTerm', { key: fieldName, value: autoRenewTerm })
    } catch (error) {
      const errorStr = error.data?.message ?? error
      commit(
        'Notifications/notifyMessage',
        {
          title: i18n.t('general.save-error'),
          message: errorStr,
          type: 'error',
        },
        { root: true },
      )

      console.error(error)
    }
  },
}

const mutations = {
  setCompany(state, payload) {
    state.data = {
      ...payload,
    }
    state.isLoading = false
  },
  updateCompany(state, payload) {
    state.data = { ...state.data, ...payload }
  },
  setTradingName(state, tradingName) {
    state.data.tradingName = tradingName
  },
  /**
   * Set the desired auto-renew terms for the company
   * @param {object} state The state object for the store
   * @param {consolidatedSettings | companyOverrides | childDefaults} key The auto-renew setting key to update
   * @param {boolean} value The new auto-renew setting value
   */
  setAutoRenewTerm(state, { key, value }) {
    state.autoRenewTerms[key] = value
  },
  logout(state) {
    state.data = { companyUid: null }
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading
    if (!isLoading) {
      state.companyReady = true
    }
  },
  setPartnerBranding(state, result) {
    state.partnerBranding = result
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters: coreGetters,
  actions,
  mutations,
}
