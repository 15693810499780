import type MegaportAPI from '../megaport/api'
import { stringifyObject } from '../megaport/utils'
import type { APIData, APISettings, PriceBookCache, URLParams } from '../megaport/types'

import type {
  PriceRequestMegaport,
  PriceRequestMCR,
  PriceRequestMVE,
  PriceRequestVXC,
  PriceRequestIX,
  TransitMarketsRequest,
  AllTermPriceRequestVXC,
} from './types/pricebook'
import type { allTermPriceRequestVXCSchema } from './schemas/pricebook'
import { transitMarketsSchema } from './schemas/pricebook'

/**
 * Pricing information. Note that since pricing should not change during a customer session
 * (and rarely changes at all), any looked up pricing is cached and the cached value used
 * where possible instead of doing another lookup.
 */
export default class PriceBookResource {
  #api: MegaportAPI
  #cache: PriceBookCache

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param cache A cache of pricebook calls to use to save on API requests
   */
  constructor(api: MegaportAPI, cache: PriceBookCache) {
    this.#api = api
    this.#cache = cache
  }

  /** Helper function for performing get requests while caching the results */
  async #cachedGet(url: string, params: URLParams, settings?: APISettings) {
    // With the introduction of the new customer-based pricing,
    // we need to include the relevant company UUID in the cache key for context (callContext)
    // to ensure that the correct pricing is returned for each customer
    const paramString = stringifyObject({ ...params, url, callContext: this.#api.callContext })

    if (!this.#cache.has(paramString)) {
      // By saving the promise to the cache we can deduplicate requests
      this.#cache.set(paramString, this.#api.get(url, settings, { params }))
    }
    try {
      // If the price has already returned, the saved promise will immediately resolve with the price
      const response = await this.#cache.get(paramString)!
      return response.body.data || response.body
    } catch (error) {
      // Upon error we need to wipe this cache entry so all future identical requests won't immediately fail
      this.#cache.delete(paramString)
      throw error
    }
  }

  /**
   * Get a price for a Port. Note that the prices returned include any discounts that are applied for that service or company.
   *
   * If you don't specify buyout port, it will still work. If you don't specify productUid or specify
   * one that isn't valid, it will treat it as a new purchase.
   *
   * @param params Information about the Port being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async megaport(params: PriceRequestMegaport, settings?: APISettings) {
    return await this.#cachedGet('/v2/pricebook/megaport', params, settings)
  }

  /**
   * Get a price for a an MCR. Note that the prices returned include any discounts that are applied for that service or company.
   *
   * If you don't specify productUid or specify one that isn't valid, it will treat it as a new purchase.
   *
   * @param params Information about the MCR being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async mcr(params: PriceRequestMCR, settings?: APISettings) {
    return await this.#cachedGet('/v2/pricebook/mcr2', params, settings)
  }

  /**
   * Get a price for an MVE.
   * @param params Information about the MVE being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async mve(params: PriceRequestMVE, settings?: APISettings) {
    return await this.#cachedGet('/v3/pricebook/mve', params, settings)
  }

  /**
   * Get price for a VXC. Note that the prices returned include any discounts that are applied for that service or company.
   *
   * If you don't specify buyout port, it will still work. If you don't specify productUid or specify
   * one that isn't valid, it will treat it as a new purchase.
   *
   * @param params Information about the VXC being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async vxc(params: PriceRequestVXC, settings?: APISettings) {
    return await this.#cachedGet('/v3/pricebook/vxc', params, settings)
  }

  /**
   * Get prices for a VXC at every possible term. Note that the prices returned include any discounts that are applied for that service or company.
   *
   * @param params Information about the VXC being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async vxcAllTerms(
    params: AllTermPriceRequestVXC,
    settings?: APISettings,
  ): Promise<APIData<typeof allTermPriceRequestVXCSchema>> {
    return await this.#cachedGet('/v3/pricebook/vxc/allterms', params, settings)
  }

  /**
   * Get price for an IX connection. Note that the prices returned include any discounts that are applied for that service or company.
   *
   * If you don't specify buyout port, it will still work. If you don't specify productUid or specify
   * one that isn't valid, it will treat it as a new purchase.
   *
   * @param params Information about the IX being price checked
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async ix(params: PriceRequestIX, settings?: APISettings) {
    return await this.#cachedGet('/v2/pricebook/ix', params, settings)
  }

  /**
   * Get markets enabled for transit services
   *
   * This is used for MCR and Port services to determine if transit VXC is available
   *
   * @param params The parameters to use to filter the list of markets
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async transitMarkets(params?: TransitMarketsRequest, settings?: APISettings) {
    const response = await this.#api.get('/v3/pricebook/transit/markets', settings, {
      params,
      schema: transitMarketsSchema,
    })
    return response.body.data
  }
}
