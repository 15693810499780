import type { LocaleMessageDictionary } from 'vue-i18n'

import en from '@/i18n/setup/en.js'

export interface MpLanguage {
  code: string
  region: string
  iso: string
  name: string
  load?: () => Promise<{ default: LocaleMessageDictionary<unknown> }>
}

/**
 * The default language for the Portal. This is used as a fallback if the user's language cannot be determined, and is
 * used as the fallback language for any messages missing in other languages.
 */
export const defaultLanguage = { code: 'en', region: 'US', iso: 'en-US', name: 'English' } satisfies MpLanguage

/**
 * The default language messages. Since we always load the default language, we don't need to import these dynamically.
 */
export const defaultMessages = en

/**
 * This is the master ordered list of languages for the user to select from. This is used internally
 * and in the language selection in the UI.
 */
export const languages: MpLanguage[] = [
  defaultLanguage,
  { code: 'de', region: 'DE', iso: 'de-DE', name: 'Deutsch', load: () => import('@/i18n/setup/de.js') },
  { code: 'fr', region: 'FR', iso: 'fr-FR', name: 'Français', load: () => import('@/i18n/setup/fr.js') },
  { code: 'ja', region: 'JP', iso: 'ja-JP', name: '日本語 (Japanese)', load: () => import('@/i18n/setup/ja.js') },
  { code: 'es', region: 'ES', iso: 'es-ES', name: 'Español', load: () => import('@/i18n/setup/es.js') },
  { code: 'pt', region: 'BR', iso: 'pt-BR', name: 'Português Brasileiro', load: () => import('@/i18n/setup/pt-BR.js') },
]
