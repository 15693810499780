import { array, number, object, record, string } from 'zod'

import { apiResponse } from '../../megaport/schemas'

export const allTermPriceRequestVXCSchema = object({
  data: record(
    number(),
    object({
      monthlyRate: number(),
      currency: string(),
    }),
  ),
})

export const transitMarketsSchema = apiResponse({
  markets: array(
    object({
      aConnectType: string(),
      countryCode: string(),
      distanceBand: string(),
    }),
  ),
})
