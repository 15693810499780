<template>
  <section>
    <h4>{{ $t('marketplace.review-request', pendingApproval.length) }}</h4>
    <p>{{ $t('services.approve-connections-list') }}</p>

    <el-card
      v-for="service in pendingApproval"
      :key="service._link"
      shadow="never"
      class="mb-1"
      data-name="pending-item"
      :data-approval-name="service.productName">
      <div>
        <h5>{{ service.secondaryName || service.productName }}</h5>
        <p>{{ descriptionText(service) }}</p>

        <div
          v-if="canApproveOrder"
          class="d-flex mt-1">
          <!-- Deny Button -->
          <el-button
            v-if="service.vxcApproval.type !== 'SPEED_CHANGE'"
            class="full-width"
            @click="denyConnection(service)">
            <i
              class="fas fa-xmark pr-0-25"
              aria-hidden="true" />
            {{ $t('general.deny-label') }}
          </el-button>

          <!-- Configure Button -->
          <el-button
            type="primary"
            class="full-width"
            @click="$router.push(service._link)">
            <i
              class="fas fa-check pr-0-25"
              aria-hidden="true" />
            {{ $t('general.configure') }}
          </el-button>
        </div>
      </div>
    </el-card>
  </section>
</template>

<script>
// External tools
import { defineComponent } from 'vue'
import { mapActions, mapGetters } from 'vuex'
// Internal tools
import { ElMessageBox } from 'element-plus'

import { resolveServicesPage } from '@/utils/MapDataUtils'

export default defineComponent({
  name: 'PendingApproval',

  props: {
    pendingApproval: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapGetters('Auth', ['hasAuth']),
    canApproveOrder() {
      return this.hasAuth('approve_order') && this.hasAuth('modify_service')
    },
  },

  methods: {
    ...mapActions('Services', ['removeServiceOrConnection', 'reviewVXCConnection']),
    /**
     * Denies the connection request.
     * NOTE: This only applies to incoming marketplace connections.
     * @param connection The connection to deny.
     */
    denyConnection(connection) {
      ElMessageBox.confirm(this.$t('connections.deny-question'), this.$t('connections.deny-title'), {
        confirmButtonText: this.$t('general.deny-label'),
        cancelButtonText: this.$t('general.cancel'),
        type: 'warning',
      })
        .then(() => {
          this.reviewVXCConnection({ connection, isApproved: false }).then(() => {
            this.removeServiceOrConnection({ productUid: connection.productUid })

            // If the user is on the configure/approve page for the connection they're now denying,
            // redirect them back to the services page.
            if (
              this.$route.meta.title === 'page-titles.approve-connection' &&
              this.$route.params.productUid === connection.productUid
            ) {
              this.$router.push(resolveServicesPage())
            }
          })
        })
        .catch(() => {
          /* Do nothing (error is handled in store) */
        })
    },
    /**
     * Returns the relevant description text for the connection.
     * @param connection The connection to display the description for.
     */
    descriptionText(connection) {
      if (!this.hasAuth('approve_order') || !this.hasAuth('modify_service')) {
        return this.$t('connections.approve-permission-denied')
      } else {
        return connection.request
      }
    },
  },
})
</script>

<style lang="scss" scoped>
h4 {
  text-align: center;
  background-color: var(--color-info-light-9);
  padding: 10px 0;
}
</style>
