<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Email"
      transform="translate(-29.000000, -59.000000)"
      fill="#080823"
      fill-rule="nonzero">
      <g
        id="Group-3"
        transform="translate(31.296577, 62.000000)">
        <g id="Group-2">
          <g
            id="Group"
            transform="translate(1.703423, 0.000000)">
            <path
              id="Rectangle"
              d="M136.5,-2.0078125 L-2.5,-2.0078125 L-2.5,76.9921875 L136.5,76.9921875 L136.5,-2.0078125 Z M131.5,2.992 L131.5,71.992 L2.5,71.992 L2.5,2.992 L131.5,2.992 Z" />
            <polygon
              id="Path-19"
              points="132.360064 -1.88695811 135.639936 1.88695811 67 61.5412131 -1.63993569 1.88695811 1.63993569 -1.88695811 67 54.917" />
          </g>
          <polygon
            id="Path-20"
            points="39.7460937 36.1701506 43.1529406 39.8298494 3.40684682 76.8298494 -1.42108547e-14 73.1701506" />
          <polygon
            id="Path-20"
            transform="translate(115.576470, 56.500000) scale(-1, 1) translate(-115.576470, -56.500000) "
            points="133.746094 36.1701506 137.152941 39.8298494 97.4068468 76.8298494 94 73.1701506" />
        </g>
      </g>
    </g>
  </g>
</template>

<script>
/**
 * Note that this image has hard coded colors, so not accepting the color props.
 */
export default {
  name: 'EmailIcon',

  viewBox: '0 0 142 80',

  synonyms: ['Email'],
}
</script>
