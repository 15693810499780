<template>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M23.429 5.9536L20.817 1.58845C19.5497 -0.529493 16.4812 -0.529476 15.2139 1.58845L0.468266 26.2317C-0.833854 28.4079 0.73388 31.1729 3.26984 31.1729H12.1588L11.8251 31.836L12.4091 31.1729H32.7611C35.297 31.1729 36.8647 28.4079 35.5626 26.2317L23.7466 6.48447L25.2375 4.15659L23.429 5.9536ZM21.9673 7.40597L19.1008 2.61538C18.6098 1.79487 17.4211 1.79488 16.9301 2.61538L2.18449 27.2587C1.68004 28.1017 2.28739 29.1729 3.26984 29.1729H13.1652L18.0154 19.534H9.76171L21.9673 7.40597ZM22.5417 8.36589L18.0154 15.4334H26.2692L14.1702 29.1729H32.7611C33.7435 29.1729 34.3509 28.1017 33.8464 27.2587L22.5417 8.36589Z"
    :fill="fillColor" />
</template>

<script>
export default {
  name: 'OutagesIcon',

  viewBox: '0 0 37 32',

  synonyms: ['Outages'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
  },
}
</script>
