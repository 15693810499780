import { array, object, string, optional } from 'zod'

import { accountTypeEnum, apiResponseArray } from '../../megaport/schemas'

export const getCompaniesSchema = apiResponseArray({
  name: string(),
  type: accountTypeEnum,
  managedAccounts: optional(
    array(
      object({
        // TODO: Include some fields here (Portal doesn't use them)
      }),
    ),
  ),
  identifier: object({
    uid: string().uuid(),
  }),
})
