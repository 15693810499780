<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="transfer--copy"
      transform="translate(-76.000000, -65.000000)"
      :fill="fillColor"
      fill-rule="nonzero">
      <g
        id="Group-48"
        transform="translate(76.000000, 65.000000)">
        <g
          id="Group-34"
          transform="translate(25.500000, 37.000000) rotate(-90.000000) translate(-25.500000, -37.000000) translate(-11.500000, 11.500000)">
          <g id="Group-17">
            <path
              id="Combined-Shape"
              d="M21.7999539,0.600943412 L22.0051418,37.6549434 L32.7631418,25.618462 L37.2368582,29.6166942 L18.8,50.2461302 L0.363141771,29.6166942 L4.83685823,25.618462 L16.0071418,38.1179434 L15.8000461,0.634212838 L21.7999539,0.600943412 Z" />
          </g>
          <g
            id="Group-17"
            transform="translate(55.000000, 25.500000) scale(-1, -1) translate(-55.000000, -25.500000) translate(36.000000, 0.000000)">
            <path
              id="Combined-Shape"
              d="M21.7999539,0.600943412 L22.0051418,37.6549434 L32.7631418,25.618462 L37.2368582,29.6166942 L18.8,50.2461302 L0.363141771,29.6166942 L4.83685823,25.618462 L16.0071418,38.1179434 L15.8000461,0.634212838 L21.7999539,0.600943412 Z" />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'TransferIcon',

  viewBox: '0 0 51 74',

  synonyms: ['Transfer'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
