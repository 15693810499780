import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

/**
 * Billing markets that the company is operating in
 */
export default class MarketsResource {
  #api: MegaportAPI
  #marketId: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param marketId
   */
  constructor(api: MegaportAPI, marketId?: number) {
    this.#api = api
    this.#marketId = marketId == null ? '' : `${marketId}`
  }

  /**
   * Updates an existing record - the object arg is of the same format as below
   * for the create operation. The marketId and firstPartyId must match.
   *
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(data: any, settings?: APISettings) {
    const response = await this.#api.put(`/v2/market/${this.#marketId}`, settings, { data })
    return response.body
  }

  /**
   * Create a billing market record. The object needs to contain information
   * about the company & person who will be receiving the bills:
   * {
   *  address1: string,
   *  billingContactEmail: string,
   *  billingContactName: string,
   *  billingContactPhone: string in international phone number format,
   *  city: string,
   *  companyLegalIdentifier: string,
   *  companyLegalName: string,
   *  country: two letter country code,
   *  currencyEnum: string, three letter currency code,
   *  firstPartyId: the identity of the "supplier" company, which you can get from lists('markets'),
   *  postcode: string,
   *  state: string,
   * }
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async create(data: any, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/market', settings, { data })
    return response.body.data || response.body
  }

  /**
   * If a market id is supplied, it will get the details of that market.
   * If no market id is supplied then it will get a list of all the enabled
   * markets for that user.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(settings?: APISettings) {
    const response = await this.#api.get(`/v2/market/${this.#marketId}`, settings)
    return response.body.data || response.body
  }
}
