import { string, number } from 'zod'

import { apiResponse, apiResponseArray } from '../../megaport/schemas'

export const mfaQrcodeSchema = apiResponse({
  secret: string(),
  qrcode: string().url(),
})

export const activitySchema = apiResponseArray({
  description: string(), // details of the action
  shortDescription: string(), // name of the action
  eventDate: number(), // API returns the datetime as a number
})
