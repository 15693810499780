<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="MVE-Copy"
      transform="translate(-32.000000, -32.000000)"
      :fill="fillColor">
      <g
        id="MVE-2"
        transform="translate(32.000000, 32.000000)">
        <g id="MVE">
          <g
            id="Combined-Shape-2"
            transform="translate(-0.000000, 0.000000)"
            fill-rule="nonzero">
            <path
              id="Combined-Shape"
              d="M68,2.44434175e-15 C105.555363,1.16427528e-14 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 -9.19841101e-15,105.555363 0,68 C9.19841101e-15,30.444637 30.444637,-6.75406926e-15 68,2.44434175e-15 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z M73.1,33.4785422 L73.1,38.4785422 L60.482,38.478 L48.2770574,55.0076239 C51.8160422,56.9203264 54.4108265,60.3563831 55.1826617,64.4370453 L93.8207675,64.140074 L93.8592325,69.139926 L55.1985089,69.4376762 C54.4844993,73.3496771 52.0968301,76.677436 48.8046709,78.6517832 L60.483,94.4675892 L73.1,94.4665892 L73.1,99.4665892 L57.9598522,99.4665892 L43.8953836,80.4226492 C43.2187204,80.5262599 42.525636,80.58 41.82,80.58 C34.3089274,80.58 28.22,74.4910726 28.22,66.98 C28.22,59.4689274 34.3089274,53.38 41.82,53.38 C42.289271,53.38 42.7529908,53.4037675 43.2100213,53.4501645 L57.9598522,33.4785422 L73.1,33.4785422 Z M41.82,58.38 C37.0703512,58.38 33.22,62.2303512 33.22,66.98 C33.22,71.7296488 37.0703512,75.58 41.82,75.58 C46.5696488,75.58 50.42,71.7296488 50.42,66.98 C50.42,62.2303512 46.5696488,58.38 41.82,58.38 Z" />
          </g>
          <circle
            id="Oval-Copy-17"
            transform="translate(93.840000, 66.980000) rotate(-630.000000) translate(-93.840000, -66.980000) "
            cx="93.84"
            cy="66.98"
            r="10.2" />
          <circle
            id="Oval-Copy-21"
            transform="translate(77.180000, 35.700000) rotate(-630.000000) translate(-77.180000, -35.700000) "
            cx="77.18"
            cy="35.7"
            r="10.2" />
          <circle
            id="Oval-Copy-21"
            transform="translate(77.180000, 98.260000) scale(1, -1) rotate(90.000000) translate(-77.180000, -98.260000) "
            cx="77.18"
            cy="98.26"
            r="10.2" />
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'MVE',

  viewBox: '0 0 136 136',

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
