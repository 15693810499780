import axios, { CanceledError } from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios'
import type { ZodError, ZodTypeAny } from 'zod'

import MegaportError from './error'
import type { APICall, APIResponse, APISettings, xAppHeader } from './types'
import { paramsSerializer } from './utils'

const CONTENT_TYPE_FORM = 'application/x-www-form-urlencoded; charset=UTF-8'
const CONTENT_TYPE_JSON = 'application/json; charset=UTF-8'

/**
 * Generic class for handling all calls to the Megaport API. Ensures requests are formatted correctly and include
 * relevant authentication as required.
 */
export default class MegaportAPI {
  /** The session UUID used to authenticate via the X-Auth-Token request header. Only used if there is no JWT. */
  authToken?: string | null
  /** The JWT used to authenticate via the Authorization request header. */
  bearerToken?: string | null
  /** Used to submit requests on behalf of a managed company. Set to the company's UUID. */
  callContext?: string | null
  /** Is used to track internal applications utilising each api */
  xApp?: xAppHeader
  /** Generic callback for all errors raised while making an API request. */
   
  errorCallback?: ((error: MegaportError) => boolean) | null
  /** Generic callback for all schema parsing errors. Requests still return even if there is a parsing error. */
   
  parsingErrorCallback?: ((error: ZodError, response: AxiosResponse) => void) | null

  #axiosInstance: AxiosInstance

  /**
   * @param baseURL The Megaport API URL you want to connect to
   * @param withCredentials Allow cross-domain cookies to be set from API responses
   */
  constructor(baseURL: string, withCredentials = false) {
    this.#axiosInstance = axios.create({
      baseURL,
      withCredentials,
      // Add timeout if required - currently disabled as it causes problems on accounts with
      // large numbers of services.
      // timeout: 120000,
      paramsSerializer,
    })
  }

  /** The standard internal configuration used by all API requests */
  get config() {
    return this.#axiosInstance.defaults
  }

  /**
   * Deal with a standard request with JSON encoded content and the partial URL
   * @param method HTTP method to use for making the request
   * @param payload Request payload
   */
  async #processStandardRequest<D, S extends ZodTypeAny>(
    method: Method,
    url: string,
    payload: APICall<D, S>,
    settings: APISettings,
    contentType?: string,
  ) {
    const { authenticated = true, data, params, schema } = payload
    const { headers, signal, useEffectiveUid = true } = settings

    const requestData: AxiosRequestConfig<D> = {
      data,
      headers,
      method,
      params,
      signal,
    }
    // This seems a little funky but it's the only way this plays nice with TypeScript
    requestData.headers ??= {}

    if (contentType) {
      requestData.headers['Content-Type'] = contentType
    }
    if (authenticated) {
      if (this.bearerToken) {
        requestData.headers['Authorization'] = `Bearer ${this.bearerToken}`
      } else if (this.authToken) {
        requestData.headers['X-Auth-Token'] = this.authToken
      }
    }
    if (this.callContext && useEffectiveUid) {
      requestData.headers['X-Call-Context'] = this.callContext
    }
    if (this.xApp) {
      requestData.headers['X-App'] = this.xApp
    }

    try {
      const response = await this.#axiosInstance(url, requestData)
      const returnData: APIResponse<S> = {
        headers: response.headers,
        status: response.status,
        body: response.data,
      }

      // Validate the response against the zod schema if it was provided
      // (If there wasn't a schema we'll just return the raw response)
      if (schema) {
        const parseResult = schema.safeParse(response.data)

        if (parseResult.success) {
          returnData.body = parseResult.data
        } else {
          this.parsingErrorCallback?.(parseResult.error, response)
        }
      }

      return returnData
    } catch (error: unknown) {
      // If the response was aborted just use the built in axios error
      if (error instanceof CanceledError) throw error

      const megaportError = new MegaportError(error)
      megaportError.executeCallback(this.errorCallback)
      throw megaportError
    }
  }

  /**
   * Makes a GET request to the Megaport API
   * @param url The URL path on the API to make the request
   * @param settings Extra consumer-provided settings to modify how the call is made
   * @param payload The payload for the API call, including URL parameters and a response validating ZOD schema
   * @returns A response object containing the status, headers, and body of the API response
   */
  async get<D, S extends ZodTypeAny>(url: string, settings: APISettings = {}, payload: APICall<D, S> = {}) {
    return await this.#processStandardRequest('GET', url, payload, settings)
  }

  /**
   * Makes a POST request to the Megaport API, sending the data as a HTML form submission
   * @param url The URL path on the API to make the request
   * @param settings Extra consumer-provided settings to modify how the call is made
   * @param payload The payload for the API call, including the request body, URL parameters, and a response validating ZOD schema
   * @returns A response object containing the status, headers, and body of the API response
   */
  async post<D, S extends ZodTypeAny>(url: string, settings: APISettings = {}, payload: APICall<D, S> = {}) {
    return await this.#processStandardRequest('POST', url, payload, settings, CONTENT_TYPE_FORM)
  }

  /**
   * Makes a POST request to the Megaport API, sending the data as a JSON object
   * @param url The URL path on the API to make the request
   * @param settings Extra consumer-provided settings to modify how the call is made
   * @param payload The payload for the API call, including the request body, URL parameters, and a response validating ZOD schema
   * @returns A response object containing the status, headers, and body of the API response
   */
  async jpost<D, S extends ZodTypeAny>(url: string, settings: APISettings = {}, payload: APICall<D, S> = {}) {
    return await this.#processStandardRequest('POST', url, payload, settings, CONTENT_TYPE_JSON)
  }

  /**
   * Makes a PUT request to the Megaport API, sending the data as a JSON object
   * @param url The URL path on the API to make the request
   * @param settings Extra consumer-provided settings to modify how the call is made
   * @param payload The payload for the API call, including the request body, URL parameters, and a response validating ZOD schema
   * @returns A response object containing the status, headers, and body of the API response
   */
  async put<D, S extends ZodTypeAny>(url: string, settings: APISettings = {}, payload: APICall<D, S> = {}) {
    return await this.#processStandardRequest('PUT', url, payload, settings, CONTENT_TYPE_JSON)
  }

  /**
   * Makes a DELETE request to the Megaport API, sending the data as a JSON object
   * @param url The URL path on the API to make the request
   * @param settings Extra consumer-provided settings to modify how the call is made
   * @param payload The payload for the API call, including the request body, URL parameters, and a response validating ZOD schema
   * @returns A response object containing the status, headers, and body of the API response
   */
  async delete<D, S extends ZodTypeAny>(url: string, settings: APISettings = {}, payload: APICall<D, S> = {}) {
    return await this.#processStandardRequest('DELETE', url, payload, settings, CONTENT_TYPE_JSON)
  }
}
