import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import { getMcrPacketFilterListSchema, listMcrPacketFilterListsSchema } from './schemas/mcrPacketFilterLists'

/**
 * MCR Packet Filter Lists
 */
export default class MCRPacketFilterListsResource {
  #api: MegaportAPI
  #productUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param productUid MCR product UID
   */
  constructor(api: MegaportAPI, productUid: string) {
    this.#api = api
    this.#productUid = productUid
  }

  /**
   * Retrieve the packet filter lists associated with the given MCR
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async list(settings?: APISettings) {
    const response = await this.#api.get(`/v2/product/mcr2/${this.#productUid}/packetFilters`, settings, {
      schema: listMcrPacketFilterListsSchema,
    })
    return response.body.data || response.body
  }

  /**
   * Retrieve a specific packet filter list associated with the given MCR
   *
   * @param {number} packetFilterListId Packet filter list ID
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(packetFilterListId: number, settings?: APISettings) {
    const response = await this.#api.get(
      `/v2/product/mcr2/${this.#productUid}/packetFilter/${packetFilterListId}`,
      settings,
      { schema: getMcrPacketFilterListSchema },
    )
    return response.body.data || response.body
  }

  /**
   * Update a specific packet filter list associated with the given MCR
   *
   * @param {number} packetFilterListId Packet filter list ID
   * @param {object} data Necessary data to update the packet filter list
   * @param {string} [data.description] Name of the packet filter list
   * @param {Array<Object>} data.entries Array of packet filter entries
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(packetFilterListId: number, data: any, settings?: APISettings) {
    const response = await this.#api.put(
      `/v2/product/mcr2/${this.#productUid}/packetFilter/${packetFilterListId}`,
      settings,
      { data },
    )
    return response.body.data || response.body
  }

  /**
   * Delete a specific packet filter list associated with the given MCR
   *
   * @param {number} packetFilterListId Packet filter list ID
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async delete(packetFilterListId: number, settings?: APISettings) {
    const response = await this.#api.delete(
      `/v2/product/mcr2/${this.#productUid}/packetFilter/${packetFilterListId}`,
      settings,
    )
    return response.body.data || response.body
  }

  /**
   * Create a new packet filter list for the given MCR
   *
   * @param {object} data Necessary data to create the packet filter list
   * @param {string} [data.description] Name of the packet filter list
   * @param {Array<Object>} data.entries Array of packet filter entries
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async create(data: any, settings?: APISettings) {
    const response = await this.#api.jpost(`/v2/product/mcr2/${this.#productUid}/packetFilter/`, settings, { data })
    return response.body.data || response.body
  }
}
