import { array, boolean, number, object, string } from 'zod'

import { apiResponse } from '../../megaport/schemas'

const ssoIdentityProvider = {
  id: number().nonnegative(),
  name: string(),
  rawName: string(),
  domains: array(string()),
  metadataUrl: string().url(),
  emailMapping: string(),
}

export const ssoIdentityProviderSchema = apiResponse(ssoIdentityProvider)

export const ssoConfigurationSchema = apiResponse({
  audienceUri: string(),
  idpResponseUrl: string().url(),
  identityProviders: array(object(ssoIdentityProvider)),
  ssoEnforced: boolean(),
  idpLimit: number().nonnegative(),
})

export const ssoCreateIdpSchema = apiResponse({
  id: number().nonnegative(),
})
