import store from '@/store/Store'
import buildProviders from '@/providers/build.js'
import { isFeatureEnabled } from '@/providers/runtime.js'
import { captureEvent } from '@/utils/analyticUtils'

const getAllPreferences = () => {
  const savedServicePagePreferences = localStorage.getItem('MP_ServicePage')
  let allPreferences = {}
  if (savedServicePagePreferences) {
    try {
      allPreferences = JSON.parse(savedServicePagePreferences)
    } catch (error) {
      // If we can't parse it, we should probably write a fresh one
      localStorage.setItem('MP_ServicePage', JSON.stringify({}))
    }
  } else {
    localStorage.setItem('MP_ServicePage', JSON.stringify({}))
  }
  return allPreferences
}

const getMyPreferences = () => {
  const companyUid = store.getters['Company/companyUid']
  const allPreferences = getAllPreferences()
  return allPreferences[companyUid] ?? {}
}

const saveMyPreferences = preferences => {
  const allPreferences = getAllPreferences()
  const companyUid = store.getters['Company/companyUid']
  if (!companyUid) {
    return // This can happen while the user is being logged in
  }
  allPreferences[companyUid] = preferences
  localStorage.setItem('MP_ServicePage', JSON.stringify(allPreferences))
}

/**
 * Work out where to take people when going back to view their services.
 */
export const resolveServicesPage = () => {
  // This is set up as an object so I can store other things in it besides just the services path
  const myPreferences = getMyPreferences()
  let servicesPath = myPreferences.servicesPath ?? '/dashboard'
  if (buildProviders.disabledFeatures.dashboard || !isFeatureEnabled('DASHBOARD_ENABLED')) {
    servicesPath = '/services'
  }

  setServicesPage(servicesPath)
  return servicesPath
}

/**
 *
 * @param {String} servicesPath
 */
export const setServicesPage = servicesPath => {
  if (!['/services', '/dashboard'].includes(servicesPath)) {
    throw new Error(`Invalid services path: ${servicesPath}`)
  }
  const myPreferences = getMyPreferences()
  myPreferences.servicesPath = servicesPath
  saveMyPreferences(myPreferences)
}

export const setFocussedUid = mapFocusUid => {
  const myPreferences = getMyPreferences()
  myPreferences.mapFocusUid = mapFocusUid
  saveMyPreferences(myPreferences)
}

export const getFocussedUid = () => {
  return getMyPreferences().mapFocusUid
}

/**
 * This is the zoom, longitude, latitude and bearing so we can re-establish it later.
 *
 * @param {Object} mapDisplay
 */
export const setMapDisplay = mapDisplay => {
  const myPreferences = getMyPreferences()
  myPreferences.mapDisplay = mapDisplay
  saveMyPreferences(myPreferences)
}

export const getMapDisplay = () => {
  return getMyPreferences().mapDisplay ?? {}
}

export const setMapSize = mapSize => {
  if (!['fullscreen', 'big', 'small'].includes(mapSize)) {
    throw new Error(`Invalid map size: ${mapSize}`)
  }
  const myPreferences = getMyPreferences()
  myPreferences.mapSize = mapSize
  saveMyPreferences(myPreferences)
}

export const getMapSize = () => {
  return getMyPreferences().mapSize || 'small'
}

/**
 * The filter has one required key: type with values of 'all', 'none', 'filtered'.
 * Additional keys may be provided to define the filter.
 *
 * @param {Object} locationsFilter
 */
export const setLocationsFilter = locationsFilter => {
  if (!locationsFilter || !['all', 'none', 'filtered'].includes(locationsFilter.type)) {
    throw new Error('Invalid locations filter')
  }

  const myPreferences = getMyPreferences()
  myPreferences.locationsFilter = locationsFilter
  saveMyPreferences(myPreferences)

  if (locationsFilter.type === 'filtered') {
    captureEvent('dashboard.map.filter', {
      hundredPorts: locationsFilter.hundredPorts,
      mcr: locationsFilter.mcr,
      mve: locationsFilter.mve,
      dcName: locationsFilter.dcName,
    })
  }
}

export const getLocationsFilter = () => {
  return getMyPreferences().locationsFilter || { type: 'all' }
}

export const locationsMatchingFilter = locations => {
  const filter = getLocationsFilter()
  if (filter.type === 'all') {
    return locations
  }
  if (filter.type === 'none') {
    return []
  }

  return locations.filter(location => {
    if (filter.dcName && location.dc.name !== filter.dcName) {
      return false
    }
    if (filter.hundredPorts && !location.products.megaport?.includes(100)) {
      return false
    }
    if (filter.mcr && !location.products.mcr2) {
      return false
    }
    if (filter.mve && !location.products.mve?.some(mve => mve.sizes.length > 0)) {
      return false
    }
    return true
  })
}
