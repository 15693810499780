import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import { getPasscodeSchema } from './schemas/g2passcode'

export default class G2PasscodeResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  async generateG2Passcode(settings?: APISettings) {
    const response = await this.#api.get('/v3/g2passcode', settings, { schema: getPasscodeSchema })
    return response.body.data
  }
}
