import { boolean, number, string, nullable, optional } from 'zod'

import { apiResponseArray } from '../../megaport/schemas'

export const getEmploymentSchema = apiResponseArray({
  active: boolean(),
  companyName: nullable(string()), // Company name returns as null for non-company admins
  email: string(), // Omitting email validation due to zod issue, see https://github.com/colinhacks/zod/issues/2193
  employmentId: number().positive(),
  firstName: string(),
  invitationPending: optional(nullable(boolean())), // Invitation pending returns as null for non-company admins
  lastName: string(),
  mfaEnabled: optional(boolean()),
  name: string(),
  personId: number().positive(),
  personUid: string().uuid(),
  phone: optional(nullable(string())),
  position: string(), // Due to be removed and replaced with securityRoles field
  requireTotp: optional(boolean()),
  ssoStatus: optional(nullable(string())), // The optional bit may not be required when BE SSO work is fully available
  username: string(), // Ideally should be an email but not all users have email usernames
})
