/**
 * Data demo field handler
 * Inputs text from data-demo attribute upon cmd/ctrl clicking a field
 * @param {*} e Event object
 */
const demoCallback = e => {
  // Do nothing if the element is disabled
  if (e.target.disabled) return

  const demoValue = e.target.getAttribute('data-demo')

  // Use cmd key when on mac, ctrl when on windows
  if ((isMac() && e.metaKey) || (!isMac() && e.ctrlKey && demoValue)) {
    e.target.value = demoValue

    const event = new Event('input', { bubbles: true })

    e.target.dispatchEvent(event)
  }
}

document.addEventListener('click', demoCallback)
document.addEventListener('input', demoCallback)

const isMac = function () {
  return navigator.userAgent.indexOf('Mac') >= 0
}
