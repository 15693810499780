<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="key"
      transform="translate(-32.000000, -32.000000)">
      <g
        id="Group-47"
        transform="translate(32.000000, 32.000000)">
        <path
          id="Combined-Shape"
          d="M68,0 C105.555363,0 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 0,105.555363 0,68 C0,30.444637 30.444637,0 68,0 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z M49,53.5 C56.3271961,53.5 62.3850271,58.934798 63.3622476,65.9931488 L102.5,65.9931641 L102.5,80 L97.5,80 L97.5,70.993 L88.5,70.993 L88.5,77.246582 L83.5,77.246582 L83.5,70.993 L63.1906514,70.9934717 C61.8320975,77.4661613 56.156854,82.3506808 49.3153115,82.4966395 L49,82.5 C40.9918711,82.5 34.5,76.0081289 34.5,68 C34.5,59.9918711 40.9918711,53.5 49,53.5 Z M49,58.5 C43.7532949,58.5 39.5,62.7532949 39.5,68 C39.5,73.2467051 43.7532949,77.5 49,77.5 C54.2467051,77.5 58.5,73.2467051 58.5,68 C58.5,62.7532949 54.2467051,58.5 49,58.5 Z"
          :fill="fillColor"
          fill-rule="nonzero" />
        <g
          id="Group-46"
          transform="translate(34.000000, 53.000000)" />
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'ServiceKey',

  viewBox: '0 0 136 136',

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
