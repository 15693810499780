import { array, number, object, optional, string, enum as ZodEnum } from 'zod'
import type { z } from 'zod'

import { apiResponse, apiResponseArray, ipAddressCIDR } from '@/api-sdk/megaport/schemas'

const addressFamilyEnum = ZodEnum(['IPv4', 'IPv6'])
const actionEnum = ZodEnum(['permit', 'deny'])

export const listMcrPrefixListsSchema = apiResponseArray({
  addressFamily: addressFamilyEnum,
  description: string().max(100),
  id: number().positive(),
})

export const getMcrPrefixListSchema = apiResponse({
  addressFamily: addressFamilyEnum,
  description: string().max(100),
  entries: array(
    object({
      action: actionEnum,
      prefix: ipAddressCIDR,
      le: optional(string()),
      ge: optional(string()),
    }),
  ),
  id: number().positive(),
})

export type getMcrPrefixListType = z.infer<typeof getMcrPrefixListSchema>
