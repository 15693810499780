<template>
  <div
    v-if="partnerBranding"
    class="partner-branding-header"
    :class="{ 'show-hamburger': showHamburger }"
    data-name="loggedin">
    <!-- Megaport Logo -->
    <router-link to="/dashboard">
      <img
        src="@/assets/images/megaport-logos/Megaport-Icon.svg"
        :alt="$t('images.megaport-logo')"
        width="40px"
        height="40px" />
    </router-link>
    <!-- Partner Logo -->
    <template v-if="partnerBranding.logoKey">
      <div class="separator" />
      <a
        :href="partnerBranding.web"
        target="_blank"
        rel="noopener">
        <img
          :src="partnerLogoUrl"
          :alt="$t('images.partner-logo')"
          width="40px"
          height="40px"
          @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')" />
      </a>
    </template>
    <!-- Partner Trading Name -->
    <div class="mx-1">
      <p class="company-name">
        {{ partnerBranding.companyName }}
      </p>
      <el-tooltip
        placement="bottom"
        :content="portalInfo"
        :show-after="500">
        <span class="powered-by">
          {{ $t('general.powered-by-megaport') }}
        </span>
      </el-tooltip>
    </div>
  </div>
  <router-link
    v-else
    to="/dashboard"
    class="default-header-logo"
    data-name="loggedin">
    <img
      src="@/assets/images/megaport-logos/Megaport-Logo-Web.svg"
      :alt="$t('images.megaport-logo')"
      width="132"
      height="31" />
    <el-tooltip
      placement="bottom"
      :content="portalInfo"
      :show-after="500">
      <div class="current-env">
        {{ apiName }}
      </div>
    </el-tooltip>
  </router-link>
</template>

<script>
import { mapState } from 'vuex'

import gitVersion from '@/gitVersion.js'
import { setFallbackImage } from '@/utils/fallbackImage'

export default {
  name: 'MegaportLogo',

  inject: ['whitelabelName'],

  props: {
    showHamburger: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      gitVersion,
    }
  },

  computed: {
    ...mapState('Company', ['partnerBranding']),
    apiName() {
      return this.$appConfiguration.apiName
    },
    environmentName() {
      return this.$appConfiguration.environmentName
    },
    portalInfo() {
      return `${this.apiName} ${this.$t('general.version')} ${gitVersion}, environment: ${this.environmentName}, whitelabel: ${this.whitelabelName}`
    },
    partnerLogoUrl() {
      const cacheBuster = `?cacheBuster=${Date.now()}`
      return `${this.$appConfiguration.marketplaceMediaUrl}/${this.partnerBranding.logoKey}${cacheBuster}`
    },
  },

  methods: {
    setFallbackImage,
  },
}
</script>

<style scoped>
.partner-branding-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  max-width: 20%;

  &.show-hamburger {
    max-width: 50%;
  }
}

.default-header-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  text-decoration: none;
}

.current-env {
  align-self: flex-end;
  line-height: 1rem;
  font-size: 1.1rem;
  color: var(--color-text-regular);
}

a {
  display: inline-flex;
}

.width-132px {
  width: 132px;
}

.company-name {
  display: -webkit-box;
  font-size: 1.5rem;
  font-weight: 500;
  margin: auto;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.powered-by {
  margin: auto;
  text-wrap: nowrap;
  font-size: 1.2rem;
  font-weight: 400;
}

.separator {
  width: 1px;
  height: 32px;
  background-color: #ccc;
  margin: 0.6rem;
}
</style>
