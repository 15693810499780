<template>
  <g>
    <rect
      width="27"
      height="26"
      rx="3"
      fill="#70B603" />
    <path
      d="M9.95833 7C7.23447 7 5 9.25325 5 12C5 14.7468 7.23447 17 9.95833 17C11.924 17 13.5633 15.7962 14.4006 14.1429H16.3333V16.2857H20.5833V14.1429H22V9.85714H21.2917H14.4117C13.6223 8.19683 11.9694 7 9.95833 7ZM9.95833 8.42857C11.5735 8.42857 12.8572 9.44357 13.3271 10.8058L13.4917 11.2857H20.5833V12.7143H19.1667V14.8571H17.75V12.7143H13.5125L13.3368 13.1677C12.7988 14.5539 11.4912 15.5714 9.95833 15.5714C8.0072 15.5714 6.41667 13.9675 6.41667 12C6.41667 10.0325 8.0072 8.42857 9.95833 8.42857ZM9.95833 9.85714C8.79312 9.85714 7.83333 10.825 7.83333 12C7.83333 13.175 8.79312 14.1429 9.95833 14.1429C11.1235 14.1429 12.0833 13.175 12.0833 12C12.0833 10.825 11.1235 9.85714 9.95833 9.85714ZM9.95833 11.2857C10.3579 11.2857 10.6667 11.5971 10.6667 12C10.6667 12.4029 10.3579 12.7143 9.95833 12.7143C9.55874 12.7143 9.25 12.4029 9.25 12C9.25 11.5971 9.55874 11.2857 9.95833 11.2857Z"
      fill="white" />
  </g>
</template>

<script>
export default {
  name: 'SingleKey',

  viewBox: '0 0 27 26',
}
</script>
