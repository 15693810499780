<template>
  <div
    class="partner-admin-banner sticky-top"
    :class="{ vantage: isVantageUser && inPartnerAccount }"
    data-name="partnerAdmin-banner">
    <div class="account-info">
      <div>
        <p class="viewing-as">
          {{ $t('partner-admin.viewing-as') }}
        </p>
        <el-tag
          v-if="inPartnerAccount"
          class="partner-tag">
          {{ $t('partner-admin.partner-account') }}
        </el-tag>
        <el-tag
          v-else
          class="managed-tag">
          {{ $t('partner-admin.managed-account', 1) }}
        </el-tag>
      </div>
      <div class="partner-admin-display">
        <div class="partner-admin-account-name">
          {{ displayedAccount.label }}
        </div>
        <div
          v-if="!inPartnerAccount"
          class="partner-admin-account-id">
          {{ $t('partner-admin.managed-accounts-uid') }}:<span class="account-id">{{ displayedAccount.value }}</span>
        </div>
      </div>
    </div>

    <img
      v-if="isVantageUser"
      src="@/assets/images/vantage-bg.svg"
      :alt="$t('images.partner-vantage-logo')"
      class="pv-v" />

    <el-select
      ref="managedCompanySelector"
      :model-value="value"
      filterable
      :filter-method="filterCompany"
      clearable
      :placeholder="placeholderValue"
      placement="bottom-end"
      class="select-partner-admin"
      @change="updateSelectedAccount"
      @visibleChange="placeholderChange">
      <el-option-group
        v-for="group in contextOptions"
        :key="group.label"
        :label="group.label"
        :class="group.class">
        <el-option
          v-for="item in group.options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
          :disabled="item.value === displayedAccount.value">
          <span class="left-option">{{ item.label }}</span>
          <span
            v-if="!item.partnerAccount"
            class="right-option"
            >{{ item.value }}</span
          >
        </el-option>
      </el-option-group>
    </el-select>
  </div>
</template>

<script>
import { ElMessageBox } from 'element-plus'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'PartnerAdminBanner',

  inject: ['isFeatureEnabled'],

  data() {
    return {
      value: '',
      selectedAccount: null,
      currentAccount: null,
      textFilter: '',
      placeholderValue: this.$t('partner-admin.select-account'),
    }
  },

  computed: {
    ...mapState('Auth', ['data']),
    ...mapState('ManagedCompanies', ['managedCompaniesList']),
    ...mapGetters('ApplicationContext', ['companyUid']),
    inPartnerAccount() {
      if (!this.selectedAccount) return true
      return this.displayedAccount.partnerAccount
    },
    partnerAccount() {
      return { value: this.data.companyName, label: this.data.companyName, partnerAccount: true }
    },
    managedAccountList() {
      return this.managedCompaniesList
        .map(company => {
          return { value: company.accountRef, label: company.accountName, partnerAccount: false }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
    },
    contextOptionsFiltered() {
      if (!this.textFilter) return [this.partnerAccount, ...this.managedAccountList]
      return [this.partnerAccount, ...this.managedAccountList].filter(option => {
        return Object.values(option).join(' ').toLowerCase().includes(this.textFilter.toLowerCase())
      })
    },
    contextOptions() {
      const partnerOptions = this.contextOptionsFiltered.filter(option => option.partnerAccount)
      const managedOptions = this.contextOptionsFiltered.filter(option => !option.partnerAccount)
      const result = []
      if (partnerOptions.length) {
        result.push({ label: this.$t('partner-admin.partner'), options: partnerOptions, class: 'acct-label partner' })
      }
      if (managedOptions.length) {
        result.push({
          label: this.$t('partner-admin.managed-account', 2),
          options: managedOptions,
          class: 'acct-label managed',
        })
      }
      return result
    },
    displayedAccount() {
      if (!this.selectedAccount) return this.partnerAccount
      return this.selectedAccount
    },
    isVantageUser() {
      // check if the vantage partner & vantage managed account flags are present
      return this.isFeatureEnabled('VANTAGE_PARTNER')
    },
  },

  async created() {
    try {
      await this.getManagedCompanies()
    } catch (error) {
      /* Do nothing */
    }
  },

  mounted() {
    if (this.companyUid) {
      const selectedCompany = this.managedCompaniesList.find(company => company.companyUid === this.companyUid)
      if (selectedCompany) {
        this.value = selectedCompany.accountRef
        this.selectedAccount = {
          value: selectedCompany.accountRef,
          label: selectedCompany.accountName,
          partnerAccount: false,
        }
      }
    }
  },

  methods: {
    ...mapActions('ManagedCompanies', ['getManagedCompanies']),
    updateSelectedAccount(val) {
      this.value = val
      this.currentAccount = { ...this.displayedAccount }
      const chosenAccount = this.contextOptions
        .map(account => account.options)
        .flat()
        .find(accountValue => accountValue.value === val)
      if (chosenAccount) {
        this.routeTrigger(chosenAccount)
      }
    },
    getAccountType(isPartner) {
      return isPartner ? this.$t('partner-admin.partner-account') : this.$t('partner-admin.managed-account', 1)
    },
    async routeTrigger(chosenAccount) {
      const { partnerAccount: fromPartnerAccount, label: fromCompany } = this.currentAccount
      const { partnerAccount: toPartnerAccount, label: toCompany, value: toCompanyRef } = chosenAccount

      const accountTypeFrom = this.getAccountType(fromPartnerAccount)
      const accountTypeTo = this.getAccountType(toPartnerAccount)

      const toCompanyUid = toPartnerAccount
        ? null
        : this.managedCompaniesList.find(company => company.accountRef === toCompanyRef).companyUid

      const msgProps = { accountTypeFrom, fromCompany, accountTypeTo, toCompany }

      try {
        await ElMessageBox.confirm(
          this.$t('general.context-switching-message', msgProps),
          this.$t('general.context-switching-title'),
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: this.$t('general.confirm'),
            cancelButtonText: this.$t('general.cancel'),
            showClose: false,
            closeOnClickModal: false,
          },
        )

        // Only switch accounts if user accepts
        this.selectedAccount = chosenAccount
        this.$router
          .push({
            query: {
              managedCompanyUid: toCompanyUid,
              companyName: toCompany || this.data.companyName,
            },
          })
          .catch(() => {})
      } catch (error) {
        this.value = this.currentAccount.value
      } finally {
        this.$refs.managedCompanySelector.blur()
      }
    },
    filterCompany(value) {
      this.textFilter = value
    },
    placeholderChange(value) {
      if (!value) this.textFilter = ''
      this.placeholderValue = value ? this.$t('partner-admin.account-filter') : this.$t('partner-admin.select-account')
    },
  },
}
</script>

<style lang="scss" scoped>
.partner-admin-banner {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin: 0;
  padding: 0px 70px;
  background-color: #c6c6c6;
  z-index: 4;
  height: 118px;
  box-shadow: 0 0px 9px 2px rgb(0 0 0 / 55%);
  &.vantage {
    background-color: var(--color-vantage-blue);
  }
}

.account-info {
  display: flex;
  margin-bottom: 2rem;
  .viewing-as {
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 2px 0;
    color: #414141;
  }
  .el-tag {
    font-size: 14px;
    width: 176px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  // These are the standard ones
  .partner-tag {
    background-color: var(--color-vantage-blue);
    border-color: var(--color-vantage-blue);
    color: var(--color-white);
  }
  .managed-tag {
    background-color: var(--color-megaport-red-80);
    border-color: var(--color-megaport-red-80);
    color: var(--color-white);
  }
  .partner-admin-display {
    margin: 19px 10px 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    word-break: break-all;
    color: #414141;
    .partner-admin-account-name {
      font-size: 16px;
      font-weight: 700;
    }
    .partner-admin-account-id {
      font-size: 13px;
      font-weight: 400;
      .account-id {
        font-weight: bold;
        padding: 0px 5px;
      }
    }
  }
}

.vantage .account-info {
  .viewing-as {
    color: white;
  }
  .partner-tag {
    background-color: var(--color-white);
    border-color: var(--color-white);
    color: var(--color-vantage-blue);
  }
  .partner-admin-display {
    color: var(--color-white);
  }
}

.pv-v {
  margin-left: auto;
  height: 100%;
  width: auto;
}

.select-partner-admin {
  width: 310px;
  margin-bottom: 2rem;
}
.left-option {
  float: left;
  padding: 0px 5px;
}
.right-option {
  float: right;
}
.acct-label {
  font-weight: 700;
  &.partner :deep(.el-select-group__title) {
    font-size: 15px;
    color: var(--color-vantage-blue);
  }
  &.managed :deep(.el-select-group__title) {
    font-size: 15px;
    color: var(--color-megaport-red-80);
  }
}
</style>
