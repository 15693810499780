<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="VXC-Copy-2"
      transform="translate(-31.000000, -31.000000)">
      <g
        id="VXC-2-Copy"
        transform="translate(31.000000, 31.000000)">
        <g id="VXC">
          <g id="Group-13-Copy">
            <g id="Group-7">
              <g
                id="Group-14"
                transform="translate(0.200000, 0.400000)">
                <g id="Group-6">
                  <circle
                    id="Oval-Copy-3"
                    :fill="fillColor"
                    transform="translate(96.000000, 68.600000) rotate(-270.000000) translate(-96.000000, -68.600000) "
                    cx="96"
                    cy="68.6"
                    r="13.6" />
                  <path
                    id="Combined-Shape"
                    d="M68.8,0.6 C106.355363,0.6 136.8,31.044637 136.8,68.6 C136.8,106.155363 106.355363,136.6 68.8,136.6 C31.244637,136.6 0.8,106.155363 0.8,68.6 C0.8,31.044637 31.244637,0.6 68.8,0.6 Z M68.8,5.6 C34.0060608,5.6 5.8,33.8060608 5.8,68.6 C5.8,103.393939 34.0060608,131.6 68.8,131.6 C103.593939,131.6 131.8,103.393939 131.8,68.6 C131.8,33.8060608 103.593939,5.6 68.8,5.6 Z M41.6,55 C48.43143,55 54.0864377,60.0368629 55.0538961,66.5994617 L96.8,66.6 L96.8,71.6 L54.8680332,71.5999421 C53.5019825,77.6676297 48.0802872,82.2 41.6,82.2 C34.0889274,82.2 28,76.1110726 28,68.6 C28,61.0889274 34.0889274,55 41.6,55 Z M41.6,60 C36.8503512,60 33,63.8503512 33,68.6 C33,73.3496488 36.8503512,77.2 41.6,77.2 C46.3496488,77.2 50.2,73.3496488 50.2,68.6 C50.2,63.8503512 46.3496488,60 41.6,60 Z"
                    :fill="fillColor"
                    fill-rule="nonzero" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'VXC',

  viewBox: '0 0 138 138',

  synonyms: ['CXC'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
