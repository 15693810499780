import Schema from 'async-validator'

// Suppress async-validator's pointless and noisy console warnings
Schema.warning = () => {}
window.ASYNC_VALIDATOR_NO_WARNING = 1

// TODO: Drop this from the codebase! Monkey patching is bad practice and can be broken by future ECMAScript versions.
Array.prototype.unique = function () {
  return this.reduce((accumulator, current) => {
    if (accumulator.indexOf(current) < 0) {
      accumulator.push(current)
    }
    return accumulator
  }, [])
}
