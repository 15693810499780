import { array, boolean, number, object, record, string } from 'zod'

import { apiResponse, apiResponseArray } from '../../megaport/schemas'

const serviceProviderType = array(
  object({
    id: number().positive(),
    description: string(),
  }),
)

// These endpoints return some of the most inconsistent data I've ever seen
// It's as if nothing was ever properly validated or required
const marketplaceProfile = {
  companyUid: string().uuid(),
  active: boolean(),
  // NOTE: This returns as a stringed boolean due to complicated reasons relating
  // to the PUT request for this field containing image data
  image: string().nullable(),
  companyName: string(),
  companyBio: string().nullable(),
  email: string(),
  www: string(), // Can't use url() as the data is consistently awful
  phone: string().nullable(),
  street1: string().nullable(),
  street2: string().nullable(),
  city: string().nullable(),
  state: string().nullable(),
  country: string().nullable(),
  postcode: string().nullable(),
  facebook: string().nullable(), // Can't use url() as the data is consistently awful
  twitter: string().nullable(), // Can't use url() as the data is consistently awful
  linkedin: string().nullable(), // Can't use url() as the data is consistently awful
  providerTypes: serviceProviderType,
}

// Common schema for services
const serviceSchema = {
  title: string(),
  description: string().nullable(),
  contactEmail: string().nullable(),
  serviceTypes: serviceProviderType,
}

// Returning all the marketplace profiles
export const marketplaceProfilesSchema = apiResponseArray({
  ...marketplaceProfile,
  services: record(string().uuid(), object(serviceSchema)).optional().nullable(),
})

// Returning only the user profile
export const marketplaceUserProfileSchema = apiResponse({
  ...marketplaceProfile,
  services: record(
    string().uuid(),
    object({
      ...serviceSchema,
      // User profiles contain an extra field for determining visibility
      marketplaceVisible: boolean().optional(),
    }),
  ).optional(),
})

export const providerTypesSchema = apiResponseArray({
  id: number().positive(),
  description: string(),
  serviceTypes: serviceProviderType,
})
