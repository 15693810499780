import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import { marketplaceUserProfileSchema, marketplaceProfilesSchema, providerTypesSchema } from './schemas/marketplace'
import type { UpdateContactPayload, UpdateProfilePayload } from './types/marketplace'

/**
 * Megaport exchange
 */
export default class MarketplaceResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource.
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get all the marketplaces with or without the services.
   *
   * @param includeServices Includes the list of services associated with each marketplace profile.
   * @param settings Additional settings to adjust the generated API request.
   * @returns The list of all marketplace profiles.
   */
  async get(includeServices: boolean, settings?: APISettings) {
    const response = await this.#api.get('/v2/marketplace/profiles', settings, {
      params: { includeServices },
      schema: marketplaceProfilesSchema,
    })
    return response.body.data
  }

  /**
   * Update the profile for a marketplace object. Use the marketplace object
   * as retrieved through the get method above and change whatever needs to
   * be updated.
   *
   * @param data The newly updated profile data to send to the API.
   * @param settings Additional settings to adjust the generated API request.
   * @returns The updated profile details.
   */
  async updateProfile(data: UpdateProfilePayload, settings?: APISettings) {
    const response = await this.#api.put('/v2/marketplace/profile', settings, {
      data,
      schema: marketplaceUserProfileSchema,
    })
    return response.body.data
  }

  /**
   * Get the profile for a given company.
   *
   * @param companyUid The unique identifier for the company to get the profile for.
   * @param settings Additional settings to adjust the generated API request.
   * @returns The profile for the given company.
   */
  async profile(companyUid = '', settings?: APISettings) {
    const response = await this.#api.get(`/v2/marketplace/profile${companyUid}`, settings, {
      schema: marketplaceUserProfileSchema,
    })
    return response.body.data
  }

  /**
   * Get all the provider types.
   *
   * @param settings Additional settings to adjust the generated API request.
   * @returns The list of provider types.
   */
  async providerTypes(settings?: APISettings) {
    const response = await this.#api.get('/v2/marketplace/providerType', settings, { schema: providerTypesSchema })
    return response.body.data
  }

  /**
   * Sends an email containing a custom message to the marketplace profile company contact.
   *
   * @param data The relevant data needed to send the email.
   * @param settings Additional settings to adjust the generated API request.
   */
  async contact(data: UpdateContactPayload, settings?: APISettings) {
    await this.#api.jpost('/v2/marketplace/contact', settings, { data })
  }
}
