<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Powerful-API-Copy"
      transform="translate(-7.000000, -40.000000)">
      <g
        id="powerful-API-2"
        transform="translate(7.000000, 40.000000)">
        <g id="powerful-API">
          <g
            id="Group-19"
            transform="translate(14.000000, 12.000000)"
            fill="#080823"
            fill-rule="nonzero">
            <path
              id="Rectangle-Copy-10"
              d="M157.5,0.5 L0.5,0.5 L0.5,109.5 L157.5,109.5 L157.5,0.5 Z M152.5,5.5 L152.5,104.5 L5.5,104.5 L5.5,5.5 L152.5,5.5 Z" />
            <polygon
              id="Path-13"
              points="155 19.8222656 155 24.8222656 3 24.8222656 3 19.8222656" />
          </g>
          <circle
            id="Oval"
            fill="#979797"
            cx="158.5"
            cy="24.5"
            r="2.5" />
          <circle
            id="Oval-Copy"
            fill="#979797"
            cx="148.5"
            cy="24.5"
            r="2.5" />
          <circle
            id="Oval-Copy-2"
            fill="#979797"
            cx="138.5"
            cy="24.5"
            r="2.5" />
          <g
            id="Group-20"
            transform="translate(46.000000, 50.736816)"
            fill="#080823">
            <path
              id="Path-14"
              d="M9.85039066,9.166721 C10.811915,8.17584452 12.3946486,8.15204991 13.3855251,9.11357426 C14.33332,10.0332932 14.396299,11.5213775 13.5585017,12.5162072 L13.4386718,12.6487087 L3.483,22.907 L13.4386718,33.166721 C14.3583908,34.1145159 14.3766081,35.6038209 13.509284,36.573016 L13.3855251,36.7018554 C12.4377302,37.6215744 10.9484252,37.6397917 9.97923011,36.7724676 L9.85039066,36.6487087 L-3.48356202,22.9077131 L9.85039066,9.166721 Z"
              fill-rule="nonzero" />
            <path
              id="Path-14"
              d="M91.8503907,9.166721 C92.811915,8.17584452 94.3946486,8.15204991 95.3855251,9.11357426 C96.33332,10.0332932 96.396299,11.5213775 95.5585017,12.5162072 L95.4386718,12.6487087 L85.483,22.907 L95.4386718,33.166721 C96.3583908,34.1145159 96.3766081,35.6038209 95.509284,36.573016 L95.3855251,36.7018554 C94.4377302,37.6215744 92.9484252,37.6397917 91.9792301,36.7724676 L91.8503907,36.6487087 L78.516438,22.9077131 L91.8503907,9.166721 Z"
              fill-rule="nonzero"
              transform="translate(87.330486, 22.907715) scale(-1, 1) translate(-87.330486, -22.907715) " />
            <path
              id="Path-15"
              d="M33.2218084,-0.680174057 C33.6023122,-2.00742012 34.9867171,-2.77490635 36.3139631,-2.39440253 C37.5881193,-2.02911886 38.3464009,-0.738640029 38.0683793,0.538276199 L38.0281916,0.697752182 L25.2781916,45.171385 C24.8976878,46.4986311 23.5132829,47.2661173 22.1860369,46.8856135 C20.9118807,46.5203298 20.1535991,45.229851 20.4316207,43.9529347 L20.4718084,43.7934588 L33.2218084,-0.680174057 Z"
              fill-rule="nonzero" />
            <path
              id="Clip-6-Copy-11"
              d="M52.7073171,22.5763435 L52.7073171,16.2319149 L55.9997862,13.02682 L59.2926829,16.2319149 L59.2926829,22.5957667 L56.0100484,25.7904563 L52.7073171,22.5763435 Z M55.9896016,0.263183594 L53.2963329,2.10130884 L53.2963329,9.33203385 L47.1464458,15.019802 L47.1464458,23.4184692 L41,29.1036081 L41,37.42492 L43.6935679,39.2631836 L46.3877343,37.42492 L46.3877343,31.1667421 L49.8436046,27.9699845 L53.3002229,31.1667421 L53.3002229,37.42492 L56.000374,39.2631836 L58.6999267,37.42492 L58.6999267,31.1667421 L62.156545,27.9699845 L65.6130137,31.1667421 L65.6130137,37.42492 L68.3058336,39.2631836 L71,37.42492 L71,29.1036081 L64.8332062,23.3989574 L64.8332062,15.019802 L58.6831695,9.33231062 L58.6831695,2.10130884 L55.9896016,0.263183594 Z" />
          </g>
          <g
            id="Group-21"
            fill="#979797"
            fill-rule="nonzero">
            <polygon
              id="Path-11"
              points="22.7539062 0.5 22.7539062 5.5 5.5 5.5 5.5 21.5078125 0.5 21.5078125 0.5 0.5" />
            <polygon
              id="Path-11"
              transform="translate(170.626953, 11.003906) scale(-1, 1) translate(-170.626953, -11.003906) "
              points="181.753906 0.5 181.753906 5.5 164.5 5.5 164.5 21.5078125 159.5 21.5078125 159.5 0.5" />
          </g>
          <g
            id="Group-21"
            transform="translate(92.500000, 123.500000) scale(1, -1) translate(-92.500000, -123.500000) translate(3.000000, 114.000000)"
            fill="#979797"
            fill-rule="nonzero">
            <polygon
              id="Path-11"
              points="19.7539062 -2.5 19.7539062 2.5 2.5 2.5 2.5 18.5078125 -2.5 18.5078125 -2.5 -2.5" />
            <polygon
              id="Path-11"
              transform="translate(167.626953, 8.003906) scale(-1, 1) translate(-167.626953, -8.003906) "
              points="178.753906 -2.5 178.753906 2.5 161.5 2.5 161.5 18.5078125 156.5 18.5078125 156.5 -2.5" />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
/**
 * Note that this image has hard coded colors, so not accepting the color props.
 */
export default {
  name: 'PowerfulApi',

  viewBox: '0 0 182 136',
}
</script>
