import { Settings } from 'luxon'

import i18n from '@/i18n/i18n.ts'
import { getSavedLanguage, loadLanguageMessages, setSavedLanguage } from '@/i18n/helpers.ts'
import integrations from '@/third-party-integrations/integrations.js'
import captureSentryError from '@/utils/CaptureSentryError.js'

// onLogout is registered at the top level so that it can easily clean up
// the entire data tree.
const onLogout = async ({ commit, dispatch }) => {
  await commit('Auth/logout')
  commit('Company/logout')
  commit('Marketplace/logout')
  commit('Markets/logout')
  commit('Notifications/logout')
  dispatch('ServiceFilters/logout')
  commit('Services/logout')
  dispatch('ServiceStatus/logout')
  commit('UserNotifications/logout')
  commit('UserProfile/logout')
  commit('Users/logout')
  commit('ApplicationContext/logout')
  commit('MVE/logout')
}

const onLogin = async ({ commit, dispatch, getters }) => {
  // Note: services are loaded as part of the login calls so we know where to redirect people to.
  const isManagedContext = getters['ApplicationContext/isManagedContext']
  const isAdminContext = getters['ApplicationContext/isAdminContext']
  const rootAccountType = getters['Auth/rootAccountType']
  const isPartnerBrandingEnabled = getters['Auth/isPartnerBrandingEnabled']

  // These actions should be completed before the user is marked as login, to minimize flashing when loading into the portal.
  const beforeLoginActions = [
    dispatch('Services/loadLocations'),
    // Fetch transit-enabled markets for Ports and MCRs (MVE is transit-enabled in all markets offering MVEs)
    dispatch('Services/fetchTransitEnabledMarkets'),
    // We need to load the marketplace profiles before we can load the partner ports
    // as the partner ports function handles calculating and setting the friendly marketplace title.
    // Let's not do this for Portal v7 please 😒
    dispatch('Marketplace/loadMarketplaceProfiles')
      .then(async () => {
        await dispatch('Services/loadPartnerPorts')
      })
      .catch(captureSentryError),
  ]

  // Load the partner branding data for PARTNER accounts
  if (!isManagedContext && rootAccountType === 'PARTNER' && isPartnerBrandingEnabled) {
    beforeLoginActions.push(dispatch('Company/loadPartnerBrandingProfile'))
  }

  if (!isAdminContext && !isManagedContext) {
    // This is assumed to be loaded by the services store in many places, so needs to be loaded before services.
    beforeLoginActions.push(dispatch('Company/syncCompany'))
  }

  await Promise.allSettled(beforeLoginActions)

  // Background Actions, lesser priority, probably should be fetched on demand instead of on login, but here we are.
  dispatch('ServiceStatus/fetchEnsEvents')
  dispatch('Services/cartsFromServer')
  dispatch('Markets/syncMarkets')
  dispatch('Users/syncUsers')
  dispatch('Markets/syncSupplies').catch(e => {
    // This can fail because you don't have the "marketing" permission, so we'll catch
    // that but for anything else, rethrow it to be picked up by sentry
    if (e.status !== 403) {
      captureSentryError(e)
    }
  })

  if (!isManagedContext && !isAdminContext) {
    dispatch('UserProfile/syncProfile')
  }

  commit('MegaportCloudRouter/populatePayloadSchema')
}

// Actions to fire off during initial setup - mainly data that doesn't change often.
const onInit = context => {
  context.dispatch('IXTypes/refreshIxTypes')
}

/**
 * Sets the current language for the Portal, loading the new languages messages if they are not already loaded. The
 * payload should be a Language record from `languages` in `@/i18n/languages.js`. If not provided, the saved language
 * will be used.
 */
const changeLanguage = async ({ state, commit }, language = getSavedLanguage()) => {
  // Exit early if the language is already set everywhere it needs to be
  if (
    [state.language.iso, i18n.locale.value, Settings.defaultLocale, document.documentElement.lang].every(
      locale => locale === language.iso,
    )
  ) {
    return
  }

  // Load the selected language messages (if they are not already loaded)
  await loadLanguageMessages(language)

  // Set the new language everywhere that tracks the language
  // i18n
  i18n.locale.value = language.iso
  // PostHog
  integrations.postHog.setSessionData({ language: language.iso })
  integrations.postHog.setUserData({ 'Latest Language': language.iso })
  // Luxon dates
  Settings.defaultLocale = language.iso
  Settings.resetCaches()
  // Html
  document.documentElement.lang = language.iso
  // Cookies
  setSavedLanguage(language)

  // Finally commit the new language to the store
  commit('setLanguage', language)
}

export default {
  onLogout,
  onLogin,
  onInit,
  changeLanguage,
}
