<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Scalable-Connectivity-Copy"
      transform="translate(-7.000000, -17.000000)">
      <g
        id="scalable-conectivity-copy-2"
        transform="translate(10.000000, 20.000000)">
        <g id="scalable-conectivity-copy">
          <g
            id="Group-39"
            transform="translate(28.656716, 18.805970)"
            fill="#080823">
            <g id="Group-38">
              <g id="Group-37">
                <g id="Group-36">
                  <g id="Group-2">
                    <path
                      id="Oval-Copy-9"
                      d="M60.8955224,0 C94.5271907,0 121.791045,27.263854 121.791045,60.8955224 C121.791045,94.5271907 94.5271907,121.791045 60.8955224,121.791045 C27.263854,121.791045 0,94.5271907 0,60.8955224 C0,27.263854 27.263854,0 60.8955224,0 Z M60.8955224,5 C30.0252778,5 5,30.0252778 5,60.8955224 C5,91.765767 30.0252778,116.791045 60.8955224,116.791045 C91.765767,116.791045 116.791045,91.765767 116.791045,60.8955224 C116.791045,30.0252778 91.765767,5 60.8955224,5 Z"
                      fill-rule="nonzero" />
                    <path
                      id="Combined-Shape-Copy-2"
                      d="M60.8955224,20.1014346 C66.1057983,20.1014346 71.1852307,21.0800761 75.9299506,22.9620587 C78.6652354,24.0470033 81.2713294,25.4254348 83.7053712,27.0697867 L83.8561017,27.171868 C80.9147242,28.5965494 76.5057781,30.7587713 71.812277,33.1552925 C70.7238456,32.4259281 69.9221146,31.9931074 68.1675975,31.2971821 C64.1138565,29.6892748 59.7738026,28.8530874 55.3130094,28.8530874 C36.4316357,28.8530874 20.7310025,41.0719073 20.1199921,59.7506912 L20.1054346,60.3024346 L20.1068998,60.2209176 C20.4671439,38.0021135 38.5908693,20.1014346 60.8955224,20.1014346 Z"
                      fill-rule="nonzero" />
                    <path
                      id="Combined-Shape-Copy-3"
                      d="M89.8652369,30.1521519 C89.8652369,30.1521519 76.0037641,64.6284042 70.8907892,69.9298651 C65.7543818,75.2264889 57.4498982,75.2264889 52.3369233,69.9298651 C47.1958294,64.6284042 47.1958294,56.0619013 52.3275503,50.7604404 C57.4498982,45.4880021 89.8652369,30.1521519 89.8652369,30.1521519 Z M60.8955224,53.8008984 C56.9772698,53.8008984 53.8008984,56.9772698 53.8008984,60.8955224 C53.8008984,64.813775 56.9772698,67.9901464 60.8955224,67.9901464 C64.813775,67.9901464 67.9901464,64.813775 67.9901464,60.8955224 C67.9901464,56.9772698 64.813775,53.8008984 60.8955224,53.8008984 Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group-6"
            transform="translate(0.000000, 0.895522)"
            fill="#979797"
            fill-rule="nonzero">
            <g id="Group-5">
              <g id="Group-4">
                <g id="Group-3">
                  <polygon
                    id="Path-2"
                    points="1.7736088 -1.76190574 32.845787 29.5166624 29.2985694 33.0404738 -1.7736088 1.76190574" />
                  <polygon
                    id="Path-3"
                    points="20.8619986 -2.11695429 20.8619986 2.88304571 2.676 2.883 2.67665578 20.8104011 -2.32334422 20.8104011 -2.32334422 -2.11695429" />
                </g>
              </g>
              <g
                id="Group-4"
                transform="translate(162.385611, 16.000000) scale(-1, 1) translate(-162.385611, -16.000000) translate(146.385611, 0.000000)">
                <g id="Group-3">
                  <g id="Group-13">
                    <polygon
                      id="Path-2"
                      points="1.7736088 -1.76190574 32.845787 29.5166624 29.2985694 33.0404738 -1.7736088 1.76190574" />
                    <polygon
                      id="Path-3"
                      points="20.8619986 -2.11695429 20.8619986 2.88304571 2.676 2.883 2.67665578 20.8104011 -2.32334422 20.8104011 -2.32334422 -2.11695429" />
                  </g>
                </g>
              </g>
            </g>
            <g
              id="Group-5"
              transform="translate(89.500000, 141.994986) scale(1, -1) translate(-89.500000, -141.994986) translate(0.000000, 125.994986)">
              <g id="Group-4">
                <g id="Group-3">
                  <polygon
                    id="Path-2"
                    points="1.7736088 -1.76190574 32.845787 29.5166624 29.2985694 33.0404738 -1.7736088 1.76190574" />
                  <polygon
                    id="Path-3"
                    points="20.8619986 -2.11695429 20.8619986 2.88304571 2.676 2.883 2.67665578 20.8104011 -2.32334422 20.8104011 -2.32334422 -2.11695429" />
                </g>
              </g>
              <g
                id="Group-4"
                transform="translate(162.385611, 16.000000) scale(-1, 1) translate(-162.385611, -16.000000) translate(146.385611, 0.000000)">
                <g id="Group-3">
                  <g id="Group-14">
                    <polygon
                      id="Path-2"
                      points="1.7736088 -1.76190574 32.845787 29.5166624 29.2985694 33.0404738 -1.7736088 1.76190574" />
                    <polygon
                      id="Path-3"
                      points="20.8619986 -2.11695429 20.8619986 2.88304571 2.676 2.883 2.67665578 20.8104011 -2.32334422 20.8104011 -2.32334422 -2.11695429" />
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g
            id="Group-22"
            transform="translate(48.358209, 0.447761)">
            <line
              id="Path-16"
              x1="2.54522771e-14"
              y1="0.447761194"
              x2="83.1914759"
              y2="0.447761194"
              stroke="#C2C2C2"
              stroke-width="5"
              stroke-dasharray="6.268656716417911" />
            <path
              id="Path-16-Copy"
              d="M2.54522771e-14,161.455224 L6.26865673,161.455224 L6.26865673,156.455224 L2.54522771e-14,156.455224 L2.54522771e-14,161.455224 Z M12.5373135,161.455224 L18.8059702,161.455224 L18.8059702,156.455224 L12.5373135,156.455224 L12.5373135,161.455224 Z M25.0746269,161.455224 L31.3432837,161.455224 L31.3432837,156.455224 L25.0746269,156.455224 L25.0746269,161.455224 Z M37.6119404,161.455224 L43.8805971,161.455224 L43.8805971,156.455224 L37.6119404,156.455224 L37.6119404,161.455224 Z M50.1492538,161.455224 L56.4179106,161.455224 L56.4179106,156.455224 L50.1492538,156.455224 L50.1492538,161.455224 Z M62.6865673,161.455224 L68.955224,161.455224 L68.955224,156.455224 L62.6865673,156.455224 L62.6865673,161.455224 Z M75.2238808,161.455224 L81.4925375,161.455224 L81.4925375,156.455224 L75.2238808,156.455224 L75.2238808,161.455224 Z"
              fill="#C2C2C2"
              fill-rule="nonzero" />
          </g>
          <g
            id="Group-22"
            transform="translate(90.052239, 80.507463) rotate(-270.000000) translate(-90.052239, -80.507463) translate(48.052239, -8.992537)"
            fill="#C2C2C2"
            fill-rule="nonzero">
            <path
              id="Path-16-Copy"
              d="M-1.01809108e-13,180.755008 L6.26865673,180.755008 L6.26865673,175.755008 L-1.01809108e-13,175.755008 L-1.01809108e-13,180.755008 Z M12.5373135,180.755008 L18.8059702,180.755008 L18.8059702,175.755008 L12.5373135,175.755008 L12.5373135,180.755008 Z M25.0746269,180.755008 L31.3432837,180.755008 L31.3432837,175.755008 L25.0746269,175.755008 L25.0746269,180.755008 Z M37.6119404,180.755008 L43.8805971,180.755008 L43.8805971,175.755008 L37.6119404,175.755008 L37.6119404,180.755008 Z M50.1492538,180.755008 L56.4179106,180.755008 L56.4179106,175.755008 L50.1492538,175.755008 L50.1492538,180.755008 Z M62.6865673,180.755008 L68.955224,180.755008 L68.955224,175.755008 L62.6865673,175.755008 L62.6865673,180.755008 Z M75.2238808,180.755008 L81.4925375,180.755008 L81.4925375,175.755008 L75.2238808,175.755008 L75.2238808,180.755008 Z" />
            <path
              id="Path-16"
              d="M-5.09045542e-14,3.4415755 L6.26865673,3.4415755 L6.26865673,-1.5584245 L-5.09045542e-14,-1.5584245 L-5.09045542e-14,3.4415755 Z M12.5373135,3.4415755 L18.8059702,3.4415755 L18.8059702,-1.5584245 L12.5373135,-1.5584245 L12.5373135,3.4415755 Z M25.0746269,3.4415755 L31.3432837,3.4415755 L31.3432837,-1.5584245 L25.0746269,-1.5584245 L25.0746269,3.4415755 Z M37.6119404,3.4415755 L43.8805971,3.4415755 L43.8805971,-1.5584245 L37.6119404,-1.5584245 L37.6119404,3.4415755 Z M50.1492538,3.4415755 L56.4179106,3.4415755 L56.4179106,-1.5584245 L50.1492538,-1.5584245 L50.1492538,3.4415755 Z M62.6865673,3.4415755 L68.955224,3.4415755 L68.955224,-1.5584245 L62.6865673,-1.5584245 L62.6865673,3.4415755 Z M75.2238808,3.4415755 L81.4925375,3.4415755 L81.4925375,-1.5584245 L75.2238808,-1.5584245 L75.2238808,3.4415755 Z" />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
/**
 * Note that this image has hard coded colors, so not accepting the color props.
 */
export default {
  name: 'ScalableIcon',

  viewBox: '0 0 185 166',

  synonyms: ['Scalable'],
}
</script>
