import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

/**
 * Network Design
 */
export default class NetworkDesignResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Validates the request and ensures all parameters are within range and acceptable to the provisioning system.
   * A successful validation request returns pricing details.
   * This request does not commit the order
   *
   * @param data configuration details for the Port
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async validate(data: any, settings?: APISettings) {
    const response = await this.#api.jpost('/v3/networkdesign/validate', settings, { data })
    return response.body.data || response.body
  }

  /**
   * Orders the Port and sends the information to the provisioning system for processing.
   * This request commits the order
   *
   * @param data configuration details for the Port
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async buy(data: any, settings?: APISettings) {
    const response = await this.#api.jpost('/v3/networkdesign/buy', settings, { data })
    return response.body.data || response.body
  }
}
