import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type { GetCompaniesPayload } from './types/companies'
import { getCompaniesSchema } from './schemas/companies'

/**
 * Companies operations
 */
export default class CompaniesResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get a list of companies
   * @param params Pass in parameters to filter the data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getCompanies(params?: GetCompaniesPayload, settings?: APISettings) {
    // TODO: Add support for headers schema to MegaportAPI so we can give type safety to returned headers
    const response = await this.#api.get('/v2/companies', settings, { params, schema: getCompaniesSchema })
    return {
      ...response.body,
      headers: {
        'pagination-total-count': response.headers['pagination-total-count'],
      },
    }
  }
}
