<template>
  <g>
    <path
      d="M81.6 68C81.6 75.5111 87.6889 81.6 95.2 81.6C102.711 81.6 108.8 75.5111 108.8 68C108.8 60.4889 102.711 54.4 95.2 54.4C87.6889 54.4 81.6 60.4889 81.6 68Z"
      :fill="fillColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M136 68C136 30.4446 105.555 0 68 0C30.4446 0 0 30.4446 0 68C0 105.555 30.4446 136 68 136C105.555 136 136 105.555 136 68ZM5 68C5 33.2061 33.2061 5 68 5C102.794 5 131 33.2061 131 68C131 102.794 102.794 131 68 131C33.2061 131 5 102.794 5 68ZM54.2539 65.9995C53.2864 59.4369 47.6314 54.4 40.8 54.4C33.2889 54.4 27.2 60.4889 27.2 68C27.2 75.5111 33.2889 81.6 40.8 81.6C47.2803 81.6 52.702 77.0676 54.068 70.9999L64 71V65.9996L54.2539 65.9995ZM73 65.9997V71L96 71V66L73 65.9997ZM32.2 68C32.2 63.2504 36.0504 59.4 40.8 59.4C45.5496 59.4 49.4 63.2504 49.4 68C49.4 72.7496 45.5496 76.6 40.8 76.6C36.0504 76.6 32.2 72.7496 32.2 68Z"
      :fill="fillColor" />
    <line
      x1="70.5602"
      y1="49.684"
      x2="56.8794"
      y2="87.2717"
      :stroke="fillColor"
      stroke-width="4" />
    <line
      x1="79.5602"
      y1="50.684"
      x2="65.8794"
      y2="88.2717"
      :stroke="fillColor"
      stroke-width="4" />
  </g>
</template>

<script>
export default {
  name: 'ThirdPartyVxc',

  viewBox: '0 0 138 138',

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
