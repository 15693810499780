// For Vue 3 upgrade need to change filter usage from {{ value | filterName }} to {{ $filters.filterName(value) }}.
import capitalizeString from '@/filters/capitalize'
import formatDealName from '@/filters/formatDealName'

const install = app => {
  app.config.globalProperties.$filters = {
    formatDealName,
    capitalizeString,
  }
}

export default install
