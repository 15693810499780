<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="cloud"
      transform="translate(-32.000000, -32.000000)"
      :fill="fillColor"
      fill-rule="nonzero">
      <g
        id="cloud-provider"
        transform="translate(32.000000, 32.000000)">
        <path
          id="Oval-Copy-18"
          d="M68,0 C105.555363,0 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 0,105.555363 0,68 C0,30.444637 30.444637,0 68,0 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z" />
        <path
          id="Path-Copy-3"
          d="M68.4700213,39.502972 C56.6649539,39.6771155 46.467592,47.4877318 42.9412291,58.5608572 L42.817,58.96 L42.5105405,58.9861669 C33.5277137,59.8133917 26.5,67.4360799 26.5,76.7058824 C26.5,86.121899 32.4775783,93.5859824 41.3256804,94.4218745 L42.5294938,94.4955193 L42.6791045,94.5 L95.358209,94.5 C103.174416,94.5 109.5,88.1076302 109.5,80.2352941 L109.496638,79.9212016 C109.337766,72.5069111 103.567819,66.477632 96.2886894,66.0009572 L96.093,65.99 L96.079281,65.6416091 C95.3780071,51.0900182 83.4711385,39.5 68.8731343,39.5 L68.4700213,39.502972 Z M68.8731343,44.5 C81.149376,44.5 91.1119403,54.5677497 91.1119403,67 C91.1119403,67.4866119 91.096708,67.9712013 91.0663791,68.4533222 L90.8706676,71.564444 L93.9500493,71.0798926 C94.4110859,71.0073469 94.8815111,70.9705882 95.358209,70.9705882 C100.401169,70.9705882 104.5,75.1126949 104.5,80.2352941 L104.496232,80.5039822 C104.355891,85.5019555 100.312696,89.5 95.358209,89.5 L42.761,89.5 L41.7104225,89.4374371 C35.6612896,88.8644894 31.5,83.6683696 31.5,76.7058824 C31.5,69.6340429 37.1624932,63.9117647 44.1343284,63.9117647 C44.3070381,63.9117647 44.4792651,63.915259 44.6509508,63.9222273 L46.7330689,64.006735 L47.1911763,61.973881 C49.4793926,51.8199076 58.4475306,44.5 68.8731343,44.5 Z" />
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'CloudIcon',

  viewBox: '0 0 136 136',

  synonyms: ['cloud'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
