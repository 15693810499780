<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Warning"
      transform="translate(-45.000000, -46.000000)"
      fill="#080823"
      fill-rule="nonzero">
      <g
        id="Group-2"
        transform="translate(45.000000, 46.000000)">
        <path
          id="Triangle"
          d="M109.140909,103.5 L55,0.632272362 L0.859090717,103.5 L109.140909,103.5 Z M55,11.368 L100.859,98.5 L9.14,98.5 L55,11.368 Z" />
        <polygon
          id="Path-21"
          points="58 39 58 71.9068885 53 71.9068885 53 39" />
        <polygon
          id="Path-21"
          points="58 78.6778846 58 88.6778846 53 88.6778846 53 78.6778846" />
      </g>
    </g>
  </g>
</template>

<script>
/**
 * Note that this image has hard coded colors, so not accepting the color props.
 */
export default {
  name: 'WarningIcon',

  viewBox: '0 0 110 104',

  synonyms: ['Warning'],
}
</script>
