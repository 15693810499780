import type MegaportAPI from '../megaport/api'
import type { APISettings, ProvisioningStatus, URLParams } from '../megaport/types'
import { defaultFromTo } from '../megaport/utils'
import { apiMessage, productBandwidthsSchema } from '../megaport/schemas'

import type {
  CreateServiceKeyPayload,
  UpdateServiceKeyPayload,
  PortlikeFields,
  VXCFields,
  IXFields,
  CancelFeedback,
  ResourceTags,
} from './types/product'
import {
  azureProductSchema,
  googleProductSchema,
  nutanixProductSchema,
  oracleProductSchema,
  checkVlanSchema,
  checkVlanReplacingSchema,
  mveImagesSchema,
  getKeySchema,
  createUpdateKeySchema,
  allResourceTagsSchema,
  productResourceTagsSchema,
} from './schemas/product'

/**
 * Get information on the various products
 */
export default class ProductResource {
  #api: MegaportAPI
  #productUid: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   * @param productUid
   */
  constructor(api: MegaportAPI, productUid: string) {
    this.#api = api
    this.#productUid = productUid
  }

  /**
   * Get information about the azure product.
   * @param serviceKey
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async azure(serviceKey: string, settings?: APISettings) {
    const response = await this.#api.get(`/v2/secure/azure/${serviceKey}`, settings, { schema: azureProductSchema })
    return response.body.data
  }

  /**
   * Get information about the google product.
   * @param pairingKey
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async google(pairingKey: string, settings?: APISettings) {
    const response = await this.#api.get(`/v2/secure/google/${pairingKey}`, settings, { schema: googleProductSchema })
    return response.body.data
  }

  /**
   * Get information about the nutanix product.
   * @param serviceKey
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async nutanix(serviceKey: string, settings?: APISettings) {
    const response = await this.#api.get(`/v2/secure/nutanix/${serviceKey}`, settings, { schema: nutanixProductSchema })
    return response.body.data
  }

  /**
   * Get information about the oracle product.
   * @param serviceUid
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async oracle(serviceUid: string, settings?: APISettings) {
    const response = await this.#api.get(`/v2/secure/oracle/${serviceUid}`, settings, { schema: oracleProductSchema })
    return response.body.data
  }

  /**
   * Get information about the IBM product.
   * @param settings Additional settings to adjust the generated API request
   * @returns Array of available bandwidths
   */
  async ibm(settings?: APISettings) {
    const response = await this.#api.get(`/v2/secure/ibm/${this.#productUid}`, settings, {
      schema: productBandwidthsSchema,
    })
    return response.body.data
  }

  /**
   * Check the availability of the specified VLAN.
   * @param vlan VLAN to check
   * @param replacingVlan Proposed new VLAN to replace an existing VLAN
   * @param innerVlan Inner VLAN to check
   * @param replacingInnerVlan Proposed new inner VLAN to replace an existing inner VLAN
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async checkVlan(
    vlan: number,
    replacingVlan?: number,
    innerVlan?: number,
    replacingInnerVlan?: number,
    settings?: APISettings,
  ) {
    const params: URLParams = {
      vlan: vlan,
    }
    if (replacingVlan) {
      params['replacingVlan'] = replacingVlan
    }
    if (innerVlan) {
      params['innerVlan'] = innerVlan
    }
    if (replacingInnerVlan) {
      params['replacingInnerVlan'] = replacingInnerVlan
    }
    const response = await this.#api.get(`/v2/product/port/${this.#productUid}/vlan`, settings, {
      params,
      schema: replacingVlan || innerVlan || replacingInnerVlan ? checkVlanReplacingSchema : checkVlanSchema, // This is a result of the BE using a deprecated method. It shall be resolved (see ENG-20525).
    })
    return response.body.data
  }

  /**
   * Check whether there is any change in price based on the new price calculated for the
   * specified date.
   * @param year The year to check the price for.
   * @param month The month to check the price for.
   * @param newSpeed The new speed to check the price for.
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async history(year: number, month: number, newSpeed?: number, settings?: APISettings) {
    const params = newSpeed ? { newSpeed } : undefined
    const response = await this.#api.get(`/v2/product/${this.#productUid}/rating/${year}/${month}`, settings, {
      params,
    })
    return response.body.data || response.body
  }

  /**
   * Get graph data between the specified dates.
   * @param from - milliseconds since January 1, 1970 00:00:00 UTC
   * @param to - milliseconds since January 1, 1970 00:00:00 UTC
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async graphMbps(from?: number, to?: number, settings?: APISettings) {
    const params: URLParams = {
      productIdOrUid: this.#productUid,
    }
    defaultFromTo(params, from, to)

    const response = await this.#api.get('/v2/graph/mbps/', settings, { params })
    return response.body.data || response.body
  }

  /**
   * Generic function for getting the telemetry data for a specified service type between the specified dates.
   * @param serviceType The type of service - one of "ix", "mcr2", "megaport", "vxc"
   * @param dataType The type of data you are requesting
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param settings Additional settings to adjust the generated API request
   * @param extraParams Optional, any extra args that need to be passed to the get method
   * @returns
   */
  async #getTelemetryData(
    serviceType: string,
    dataType: string,
    from?: number,
    to?: number,
    settings?: APISettings,
    extraParams?: URLParams,
  ) {
    const params: URLParams = {
      type: dataType,
      ...extraParams,
    }
    defaultFromTo(params, from, to)

    const response = await this.#api.get(`/v2/product/${serviceType}/${this.#productUid}/telemetry`, settings, {
      params,
    })
    return response.body.data || response.body
  }

  /**
   * Get the telemetry data for the specified IX between the specified dates.
   * @param dataType The type of data you are requesting - either "BYTES" or "PACKETS"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param peerUid Optional UID of the peer to get data for
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async ixTelemetry(dataType: string, from?: number, to?: number, peerUid?: string, settings?: APISettings) {
    const extraParams = peerUid ? { peerUid } : undefined
    return await this.#getTelemetryData('ix', dataType, from, to, settings, extraParams)
  }

  /**
   * Get the telemetry data for the specified MCR2 between the specified dates.
   * @param dataType The type of data you are requesting - either "BYTES" or "PACKETS"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async mcr2Telemetry(dataType: string, from?: number, to?: number, settings?: APISettings) {
    return await this.#getTelemetryData('mcr2', dataType, from, to, settings)
  }

  /**
   * Get the telemetry data for the specified MVE between the specified dates.
   * @param dataType The type of data you are requesting - either "BYTES" or "PACKETS"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async mveTelemetry(dataType: string, from?: number, to?: number, settings?: APISettings) {
    return await this.#getTelemetryData('mve', dataType, from, to, settings)
  }

  /**
   * Get the telemetry data for the specified Port between the specified dates.
   * @param dataType The type of data you are requesting - one of: "BYTES", "PACKETS", "ERRORS", "OPTICAL"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async portTelemetry(dataType: string, from?: number, to?: number, settings?: APISettings) {
    return await this.#getTelemetryData('megaport', dataType, from, to, settings)
  }

  /**
   * Get the telemetry data for the specified VXC between the specified dates.
   * @param dataType The type of data you are requesting - one of: "BYTES", "PACKETS", "ERRORS", "OPTICAL"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async vxcTelemetry(dataType: string, from?: number, to?: number, settings?: APISettings) {
    return await this.#getTelemetryData('vxc', dataType, from, to, settings)
  }

  /**
   * Get the IX telemetry FLOW data for a given product and peer
   * @param dataType The type of data you are requesting - one of: "BYTES", "PACKETS", "ERRORS", "OPTICAL"
   * @param from Unix timestamp from date
   * @param to Unix timestamp to date
   * @param peerUid peer uuid
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async ixTelemetryFlow(dataType: string, from?: number, to?: number, peerUid?: string, settings?: APISettings) {
    const params = {
      type: dataType,
      from,
      to,
      peerUid,
    }
    const response = await this.#api.get(`/v2/product/ix/${this.#productUid}/telemetry/flow`, settings, { params })
    return response.body.data || response.body
  }

  /**
   * Get log information for the specified product.
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async logs(settings?: APISettings) {
    const response = await this.#api.get(`/v2/product/${this.#productUid}/logs`, settings)
    return response.body.data || response.body
  }

  /**
   * If the key is passed in, it will retrieve the details of that key.
   * If no key is passed in, it will retrieve the list of all the keys
   * associated with that product.
   * @param key The key to retrieve details for
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getKey(key?: string, settings?: APISettings) {
    const params: URLParams = {}
    if (key) {
      params.key = key
    }
    if (this.#productUid) {
      params.productIdOrUid = this.#productUid
    }

    const response = await this.#api.get('/v2/service/key', settings, { params, schema: getKeySchema })
    return response.body.data
  }

  /**
   * Create an access key
   * @param data The data to create the key with
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async createKey(data: CreateServiceKeyPayload, settings?: APISettings) {
    data.productUid = this.#productUid
    const response = await this.#api.jpost('/v2/service/key', settings, { data, schema: createUpdateKeySchema })
    return response.body.data
  }

  /**
   * Update the key. Options are as for the create operation, but must
   * also have a key of "key" with the value of the key as returned by
   * the create or get operations.
   *
   * You only need to specify the attributes you want to change, with
   * the exception at the moment if you don't include maxSpeed, it will
   * reset it to the VXC speed.
   * @param data The updated data to update the key with
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async updateKey(data: UpdateServiceKeyPayload, settings?: APISettings) {
    data.productUid = this.#productUid
    const response = await this.#api.put('/v2/service/key', settings, { data, schema: createUpdateKeySchema })
    return response.body.data
  }

  /**
   * Cancel a product.
   * @param now true for immediate or false for after 30 days
   * @param data An optional object containing a reason for cancellation
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async cancel(now: boolean, data?: CancelFeedback, settings?: APISettings) {
    const response = await this.#api.jpost(
      `/v3/product/${this.#productUid}/action/${now ? 'CANCEL_NOW' : 'CANCEL'}`,
      settings,
      { data, schema: apiMessage },
    )
    return response.body
  }

  /**
   * Reverse a cancel operation.
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async uncancel(settings?: APISettings) {
    const response = await this.#api.jpost(`/v3/product/${this.#productUid}/action/UN_CANCEL`, settings)
    return response.body.data || response.body
  }

  /**
   * Cancel the charges for a product. Typically this is done when cancelling the
   * product itself.
   * @param now If true, cancel the current charges and return them. If false, cancel future charges and return them.
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async cancelCharges(now: boolean, settings?: APISettings) {
    const response = await this.#api.get(
      `/v2/product/${this.#productUid}/action/${now ? 'CANCEL_NOW' : 'CANCEL'}/charges`,
      settings,
    )
    return response.body.data || response.body
  }

  /**
   * Update the product. The object that is passed in to this method is dependent on
   * the type of product. If changing rateLimit be sure to check the price using pricebook first
   * @param data The fields to be updated on the product
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async update(data: Partial<PortlikeFields | VXCFields | IXFields>, settings?: APISettings) {
    const productObj = await this.get()
    const productType = productObj.productType.toLowerCase()
    const apiVersion = productType === 'vxc' ? 3 : 2
    const response = await this.#api.put(`/v${apiVersion}/product/${productType}/${this.#productUid}`, settings, {
      data,
    })
    return response.body.data || response.body
  }

  /**
   * Get product information about a specific product.
   * @param incResources Should data for associated resources also be returned?
   * @param incProvisioningStatus an array of provisioning statuses to filter by (this filters associatedVxc + associatedIx)
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(incResources = false, incProvisioningStatus: ProvisioningStatus[] = [], settings?: APISettings) {
    const params = {
      incProvisioningStatus,
      incResources,
    }
    const response = await this.#api.get(`/v2/product/${this.#productUid}`, settings, { params })
    return response.body.data || response.body
  }

  /**
   * Lock product so it can't be edited (only company admin can lock or unlock)
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async lock(settings?: APISettings) {
    const response = await this.#api.post(`/v2/product/${this.#productUid}/lock`, settings)
    return response.body.data || response.body
  }

  /**
   * Unlock a product to allow it to be edited (only company admin can lock or unlock)
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async unlock(settings?: APISettings) {
    const response = await this.#api.delete(`/v2/product/${this.#productUid}/lock`, settings)
    return response.body.data || response.body
  }

  /**
   * Get all the available port terms and discounts as per ENG-9210
   * @param speed The new speed of the service if its speed is being increased during reterming
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getPortTerms(speed?: number, settings?: APISettings) {
    // Omit the parameter if it's not passed in
    const params = speed != null ? { speed } : {}
    const response = await this.#api.get(`/v2/product/portTerms/${this.#productUid}`, settings, { params })
    return response.body.data || response.body
  }

  /**
   * Get information for all available MVE images.
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getMveImages(settings?: APISettings) {
    const response = await this.#api.get('/v3/product/mve/images', settings, { schema: mveImagesSchema })
    return response.body.data || response.body
  }

  /**
   * Supports adding, removing, updating services.
   * By making this request you are overriding all tags, so if you don't include tags, it will remove them.
   * Updates the existing resource tags list with the new list provided
   * @param resourceTags list of tags to be associated with the product
   * @param settings Additional settings to adjust the generated API request
   * @returns The updated resource tags list
   */
  async updateResourceTags(resourceTags: ResourceTags[], settings?: APISettings) {
    const data = { resourceTags }
    await this.#api.put(`/v2/product/${this.#productUid}/tags`, settings, { data })
  }

  /**
   * Fetches a list of all the resource tags a user has associated with their services.
   * @param settings Additional settings to adjust the generated API request
   * @returns The resource tags list for products
   */
  async getAllResourceTags(settings?: APISettings) {
    const response = await this.#api.get(`/v2/products/tags`, settings, { schema: allResourceTagsSchema })
    return response.body.data
  }

  /**
   * Fetches resource tags for a specific service.
   * If you don't have access to the service it will respond with an empty list
   * @param settings Additional settings to adjust the generated API request
   * @returns The resource tags list of a specific service
   */
  async getProductResourceTags(settings?: APISettings) {
    const response = await this.#api.get(`/v2/product/${this.#productUid}/tags`, settings, {
      schema: productResourceTagsSchema,
    })
    return response.body.data
  }
}
