import { boolean, object, string, number, nullable } from 'zod'

import { apiResponse, apiResponseArray } from '../../megaport/schemas'

export const getPaymentsSchema = apiResponseArray({
  created: number().positive(),
  amount: number().positive(),
  description: string(),
})

export const getCardsSchema = apiResponse({
  id: number().positive(),
  autoPay: boolean(),
  last4: string().length(4),
})

export const updateCardSchema = apiResponse({
  card: object({
    autoPay: boolean(),
  }),
})

export const makePaymentSchema = apiResponse({
  intentStatus: string(), // Ideally this should be an enum but no idea what the failure response should be
})

const card = object({
  autoPay: boolean(),
  brand: string(),
  country: string(),
  expMonth: number().positive(),
  expYear: number().positive(),
  id: number().positive(),
  last4: string().length(4),
  name: nullable(string()),
})

export const registerCardSchema = apiResponse({
  card,
  setupIntent: object({
    intentStatus: string(), // Ideally this should be an enum but no idea what the failure response should be
  }),
})

export const registerCardConfirmationSchema = apiResponse({
  card,
  setupIntent: object({
    confirmed: boolean(),
    requiresAuth: boolean(),
  }),
})

export const paymentConfirmationSchema = apiResponse({
  confirmed: boolean(),
  intentStatus: string(), // Ideally this should be an enum but no idea what the failure response should be
})
