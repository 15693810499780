import API from './IconApi.vue'
import Cloud from './IconCloud.vue'
import CloudRouter from './IconCloudRouter.vue'
import Csv from './IconCsv.vue'
import DlrPort from './IconDlrPort.vue'
import DlrVxc from './IconDlrVxc.vue'
import Documentation from './IconDocumentation.vue'
import Email from './IconEmail.vue'
import GettingStarted from './IconGettingStarted.vue'
import Globe from './IconGlobe.vue'
import Google from './IconGoogle.vue'
import Ix from './IconIx.vue'
import Key from './IconKey.vue'
import LagMegaport from './IconLagMegaport.vue'
import MarketPlace from './IconMarketPlace.vue'
import MarketPlaceLabel from './IconMarketPlaceLabel.vue'
import MCR from './IconMcr.vue'
import MEGAPORT from './IconMegaport.vue'
import MegaportInternet from './IconMegaportInternet.vue'
import MissingDc from './IconMissingDc.vue'
import MultipleKey from './IconMultipleKey.vue'
import MVE from './iconMve.vue'
import Outages from './IconOutages.vue'
import Pdf from './IconPdf.vue'
import PowerfulApi from './IconPowerfulApi.vue'
import PrivateVXC from './IconPrivateVxc.vue'
import RealTime from './IconRealTime.vue'
import Scalable from './IconScalable.vue'
import SingleKey from './IconSingleKey.vue'
import ThirdPartyVxc from './IconThirdPartyVxc.vue'
import Transfer from './IconTransfer.vue'
import VXC from './IconVxc.vue'
import Warning from './IconWarning.vue'

// export all the icons
export default {
  API,
  Cloud,
  CloudRouter,
  Csv,
  DlrPort,
  DlrVxc,
  Documentation,
  Email,
  GettingStarted,
  Globe,
  Google,
  Ix,
  Key,
  LagMegaport,
  MarketPlace,
  MarketPlaceLabel,
  MCR,
  MEGAPORT,
  MegaportInternet,
  MissingDc,
  MultipleKey,
  MVE,
  Outages,
  Pdf,
  PowerfulApi,
  PrivateVXC,
  RealTime,
  Scalable,
  SingleKey,
  ThirdPartyVxc,
  Transfer,
  VXC,
  Warning,
}
