import type MegaportAPI from '../megaport/api'
import type { APISettings, URLParams } from '../megaport/types'

import type { PortlikeObject } from './types/serviceOrder'

/**
 * Order a service or services. This allows the shopping cart to be stored on the server
 * and deleted or deployed.
 *
 * @param serviceOrderUid Required for delete or deploy. See methods for more info
 * @param companyUid Used for save and get operations, but automatically overrides to user's company if not specified
 */
export default class ServiceOrderResource {
  #api: MegaportAPI
  #serviceOrderUid?: string
  #companyUid?: string

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI, serviceOrderUid?: string, companyUid?: string) {
    this.#api = api
    this.#serviceOrderUid = serviceOrderUid
    this.#companyUid = companyUid
  }

  /**
   * Save all the order information under the specified title. If the serviceOrder object is created
   * with a serviceOrderUid, then the order is updated - otherwise it's saved as a new order
   * @param title
   * @param serviceRequestObject An array of services you want to order
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async save(title: string, serviceRequestObject: PortlikeObject[], settings?: APISettings) {
    const data = {
      companyUid: this.#api.callContext ?? this.#companyUid,
      title,
      serviceRequestObject,
    }

    let response
    if (typeof this.#serviceOrderUid === 'string') {
      response = await this.#api.put(`/v2/serviceorder/${this.#serviceOrderUid}`, settings, { data })
    } else {
      response = await this.#api.jpost('/v2/serviceorder', settings, { data })
    }
    return response.body.data || response.body
  }

  /**
   * Delete a service order
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async delete(settings?: APISettings) {
    // TODO: Enforce serviceOrderUid being not nullish
    const response = await this.#api.delete(`/v2/serviceorder/${this.#serviceOrderUid}`, settings)
    return response.body.data || response.body
  }

  /**
   * Deploy a service order
   *
   * @param promoCodes
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async deploy(promoCodes: any, settings?: APISettings) {
    // TODO: Enforce serviceOrderUid being not nullish
    const data = {
      serviceOrderId: this.#serviceOrderUid,
      serviceOrderStatus: 'ACCEPTED',
      promoCodes: JSON.stringify(promoCodes),
    }
    const response = await this.#api.post('/v2/serviceorder/process', settings, { data })
    return response.body.data || response.body
  }

  /**
   * Get either the information about a specific order if the orderUid was used in the constructor,
   * or all the orders for that company (either company passed in or logged in company)
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(settings?: APISettings) {
    let url: string,
      params: URLParams = {}

    if (typeof this.#serviceOrderUid === 'string') {
      url = `/v2/serviceorder/${this.#serviceOrderUid}`
    } else {
      url = '/v2/serviceorder'
      if (!this.#api.callContext) params = { companyUid: this.#companyUid }
    }
    const response = await this.#api.get(url, settings, { params })
    return response.body.data || response.body
  }
}
