import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type { AddEmployeePayload } from './types/employment'
import { getEmploymentSchema } from './schemas/employment'

/**
 * Attachment to a company
 */
export default class EmploymentResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get all employees for a company
   *
   * NOTE: You can optionally pass in an employmentId which will return the details
   * of the specified employment record (not currently needed for portal).
   *
   * @param settings Additional settings to adjust the generated API request
   * @returns List of all employees for a company
   */
  async get(settings?: APISettings) {
    const response = await this.#api.get('/v2/employment', settings, { schema: getEmploymentSchema })
    return response.body.data
  }

  /**
   * Add employee
   *
   * @param data New employee data
   * @param settings Additional settings to adjust the generated API request
   */
  async employ(data: AddEmployeePayload, settings?: APISettings) {
    await this.#api.jpost('/v2/employment', settings, { data })
  }
}
