import { array, nullable, number, object, string, enum as ZodEnum } from 'zod'
import type { z } from 'zod'

import { apiResponse, apiResponseArray, ipAddressCIDR } from '@/api-sdk/megaport/schemas'

const actionEnum = ZodEnum(['permit', 'deny'])

export type actionEnumType = z.infer<typeof actionEnum>

export const listMcrPacketFilterListsSchema = apiResponseArray({
  description: string().max(100),
  id: number().positive(),
})

export const getMcrPacketFilterListSchema = apiResponse({
  description: string().max(100),
  entries: array(
    object({
      action: actionEnum,
      description: nullable(string().max(100)),
      destinationAddress: ipAddressCIDR,
      destinationPorts: nullable(string()),
      ipProtocol: nullable(number().nonnegative()),
      sourceAddress: ipAddressCIDR,
      sourcePorts: nullable(string()),
    }),
  ),
  id: number().positive(),
})

export type getMcrPacketFilterListType = z.infer<typeof getMcrPacketFilterListSchema>
