import providers from '@/providers'

const entries = Object.entries(providers)

const install = app => {
  // In Vue 3 provide values need to be registered individually
  for (const [key, value] of entries) {
    app.provide(key, value)
  }
}

export default install
