<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="VXC-Copy-3"
      transform="translate(-32.000000, -32.000000)">
      <g
        id="Group-3"
        transform="translate(32.000000, 32.000000)">
        <g id="Group-2">
          <g
            id="VXC-2-Copy"
            transform="translate(27.000000, 54.000000)">
            <g id="VXC">
              <g id="Group-13-Copy">
                <g id="Group-7">
                  <g
                    id="Group-14"
                    transform="translate(0.200000, 0.400000)">
                    <g id="Group-6">
                      <circle
                        id="Oval-Copy-3"
                        :fill="fillColor"
                        transform="translate(68.000000, 13.600000) rotate(-270.000000) translate(-68.000000, -13.600000) "
                        cx="68"
                        cy="13.6"
                        r="13.6" />
                      <circle
                        id="Oval-Copy-4"
                        :stroke="strokeColor"
                        stroke-width="5"
                        transform="translate(13.600000, 13.600000) rotate(-270.000000) translate(-13.600000, -13.600000) "
                        cx="13.6"
                        cy="13.6"
                        r="11.1" />
                      <line
                        id="Path-8-Copy"
                        x1="68.334815"
                        y1="13.94"
                        x2="23.76"
                        y2="13.94"
                        :stroke="strokeColor"
                        stroke-width="5" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <path
            id="Combined-Shape"
            d="M68,0 C105.555363,0 136,30.444637 136,68 C136,73.154332 135.426529,78.1747226 134.33991,83.000848 L129.203,83 L129.216501,82.9508605 C130.3971,78.0987798 131,73.0911184 131,68 C131,33.2060608 102.793939,5 68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C78.3365394,131 88.1732567,128.489259 96.8709561,124.001345 L106.583551,124.001435 C95.6212635,131.568541 82.3281431,136 68,136 C30.444637,136 0,105.555363 0,68 C0,30.444637 30.444637,0 68,0 Z"
            :fill="fillColor"
            fill-rule="nonzero" />
          <g
            id="Group"
            transform="translate(106.000000, 87.000000)">
            <rect
              id="Rectangle"
              :fill="fillColor"
              x="0"
              y="14"
              width="28"
              height="20" />
            <path
              id="Rectangle"
              d="M14,0 C17.8659932,-7.10171439e-16 21,3.13400675 21,7 L21,19 L21,19 L7,19 L7,7 C7,3.13400675 10.1340068,7.10171439e-16 14,0 Z"
              :stroke="strokeColor"
              stroke-width="3" />
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'PrivateVXC',

  viewBox: '0 0 136 136',

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
