<template>
  <el-footer
    id="site-footer"
    height="20px"
    class="footer">
    <a
      href="https://www.megaport.com/legal/privacy-policy/"
      target="_blank"
      rel="noopener"
      >{{ $t('general.privacy-policy') }}</a
    >
    <a
      href="https://trust.megaport.com/"
      target="_blank"
      rel="noopener"
      >{{ $t('general.trust-center') }}</a
    >
    <a
      href="https://www.megaport.com/legal/website-terms/"
      target="_blank"
      rel="noopener"
      >{{ $t('general.site-terms') }}</a
    >
    <a
      href="https://www.megaport.com/legal/global-services-agreement/"
      target="_blank"
      rel="noopener"
      >{{ $t('general.gsa') }}</a
    >
    <a
      href="https://www.megaport.com/legal/acceptable-use-policy/"
      target="_blank"
      rel="noopener"
      >{{ $t('general.acceptable-use') }}</a
    >
    <router-link
      v-if="isLoggedIn"
      to="/debug">
      <i
        class="fas fa-bug"
        aria-hidden="true" />
    </router-link>
  </el-footer>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  name: 'PageFooter',

  computed: {
    ...mapGetters('Auth', ['isLoggedIn']),
  },
})
</script>

<style lang="scss" scoped>
.footer {
  border-top: 1px solid var(--card-border-color);
  background-color: var(--mp-footer-background-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 2000;
  height: 20px;

  a {
    text-decoration: none;
    font-size: 1rem;
    color: var(--color-text-secondary);
    margin-left: 1rem;
  }
}
</style>
