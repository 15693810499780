import { string, enum as zodEnum, boolean, optional } from 'zod'

import { apiResponseArray, apiResponse } from '../../megaport/schemas'

const rolesEnum = zodEnum(['companyAdmin', 'readOnlyUser', 'readOnlyApiUser', 'api'])

export const getKeysSchema = apiResponseArray({
  active: boolean(),
  clientId: string(),
  name: string(),
  role: rolesEnum,
  createdBy: string().uuid(),
  createDate: string().datetime(),
  resourceServerUrl: string().url(),
})

export const generateKeySchema = apiResponse({
  clientId: string(),
  clientSecret: string(),
})

export const updateKeySchema = apiResponse({
  clientId: string(),
})

export const getHistorySchema = apiResponseArray({
  name: string(),
  role: rolesEnum,
  revisionType: zodEnum(['CREATE', 'UPDATE']),
  createdBy: string().uuid(),
  createDate: string().datetime(),
  updatedBy: optional(string().uuid()),
  lastUpdate: optional(string().datetime()),
})
