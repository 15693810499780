import { boolean, number, object, string, array, optional, literal } from 'zod'

import { ipAddressCIDR } from '../../megaport/schemas'

const nextHopSchema = object({
  ip: optional(string().ip().or(literal('::'))), // The IP address can sometimes return as '::'
  vxc: optional(
    object({
      id: string().uuid(),
      name: string(),
    }),
  ),
})

const ipSchema = object({
  distance: optional(number().nonnegative()),
  metric: optional(number()),
  prefix: ipAddressCIDR,
  protocol: optional(string()),
  nextHop: nextHopSchema,
})

const bgpSchema = object({
  prefix: ipAddressCIDR,
  best: boolean(),
  communities: optional(array(string())),
  external: boolean(),
  med: optional(number()),
  origin: string(),
  since: optional(string()),
  source: string().ip(),
  valid: boolean(),
  advertisedTo: optional(array(string().ip())),
  asPath: string(),
  localPref: number(),
  nextHop: nextHopSchema,
})

// This response can be either a 202 with no data object, or a 200 with the data object
// being one of two different types, so we have to use data: optional() here.
// NOTE: The order has to be bgpSchema or ipSchema since inverting them would cause ipSchema to always be the one used
// given that its required fields are also required fields in bgpSchema.
export const getResultsSchema = object({ data: optional(array(bgpSchema.or(ipSchema))) })
export const requestResultSchema = object({ data: string().uuid() })
