import type { URLParams } from './types'

/***********************************/
/* Miscellaneous utility functions */
/***********************************/

/**
 * Base64 encodes a string
 * @param string The string to be encoded
 * @returns The Base64 encoding of string
 */
export const base64Encode = (string: string) => Buffer.from(string, 'utf-8').toString('base64')

/**
 * Generic function for defaulting the from and to parameters if they are not provided
 * @param params The parameter object to default the values inside. Is mutated by the function
 * @param from The from datetime. If not provided defaults to one day before the to date
 * @param to The to datetime. If not provided defaults to the current datetime
 */
export const defaultFromTo = (params: URLParams, from?: number, to?: number) => {
  params.to = to ?? new Date().getTime()
  params.from = from ?? params.to - 24 * 60 * 60 * 1000
}

/**
 * Transforms the passed URL params into a key-pair string
 * @param params The object containing the query params
 * @returns The params encoded in a string
 */
export const paramsSerializer = (params: URLParams) => {
  const paramStrings: string[] = []
  for (const [param, value] of Object.entries(params)) {
    if (value == null) {
      paramStrings.push(`${encodeURIComponent(param)}=`)
    } else if (Array.isArray(value)) {
      // If the value comes in as an array, we need to unwrap the value and add the param once for each element
      for (const item of value) {
        paramStrings.push(`${encodeURIComponent(param)}=${encodeURIComponent(item)}`)
      }
    } else {
      paramStrings.push(`${encodeURIComponent(param)}=${encodeURIComponent(value)}`)
    }
  }

  return paramStrings.join('&')
}

/**
 * Turns an arbitrary object into a stable string for easy equality comparison
 * @param object
 */
export const stringifyObject = (object: Record<string, unknown>) => {
  // Second param controls not only which keys are included in the JSON string but also their order, so passing in all
  // of the objects keys but sorted will ensure they appear sorted in the final string
  return (
    JSON.stringify(object, Object.keys(object).sort())
      // Strip out redundant characters to make the string more stable
      .replaceAll(/[{}"]/g, '')
  )
}
