import { array, object, string, optional } from 'zod'

import { apiResponseArray, ensEventStateEnum } from '../../megaport/schemas'

export const maintenanceEventsSchema = apiResponseArray({
  cancelReason: string().optional(),
  endTime: string(),
  eventId: string(),
  impact: string(),
  purpose: string(),
  services: array(string()),
  startTime: string(),
  state: ensEventStateEnum,
})

export const outagesEventsSchema = apiResponseArray({
  endTime: string().optional(),
  eventId: string(),
  outageId: string(),
  services: array(string()),
  startTime: string(),
  state: ensEventStateEnum,
  resolution: string().optional(),
  rootCause: string().optional(),
  mitigationActions: string().optional(),
  notices: optional(
    array(
      object({
        createdDate: string(),
        description: string(),
        nextUpdate: string().optional(),
      }),
    ),
  ),
})
