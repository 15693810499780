/**
 * Global object for holding the application environment configuration
 * @type {Record<string, any>}
 */
export const appConfiguration = {}
export default appConfiguration

/**
 * Helper function for setting the value of the application environment configuration. This should be used instead of
 * manually mutating `appConfig` so that computed config values can be generated.
 * @param {Record<string, any>} loadedConfig The loaded environment configuration as an object.
 */
export const setAppConfiguration = loadedConfig => {
  Object.assign(appConfiguration, loadedConfig, {
    isProduction: loadedConfig.environmentName === 'production',
  })
}
