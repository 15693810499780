<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="MCR-Copy"
      transform="translate(-32.000000, -32.000000)">
      <g
        id="MCR-2"
        transform="translate(32.000000, 32.000000)">
        <g id="MCR">
          <g
            id="Group-12"
            :fill="fillColor">
            <g id="Group-13">
              <g
                id="Group-11"
                transform="translate(0.000000, -0.000000)">
                <path
                  id="Combined-Shape"
                  d="M68,2.57918605e-14 C105.555363,3.26906687e-14 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 -2.84217094e-14,105.555363 0,68 C0,30.444637 30.444637,1.88930522e-14 68,2.57918605e-14 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z M64.6990291,71.3009709 L64.6990291,91.1067961 L57.13,83.537 L47.227,93.44 L54.7961165,101.009709 L34.9902913,101.009709 L34.9902913,81.2038835 L42.559,88.772 L52.462,78.869 L44.8932039,71.3009709 L64.6990291,71.3009709 Z M91.1067961,71.3009709 L83.537,78.869 L93.44,88.772 L101.009709,81.2038835 L101.009709,101.009709 L81.2038835,101.009709 L88.772,93.44 L78.869,83.537 L71.3009709,91.1067961 L71.3009709,71.3009709 L91.1067961,71.3009709 Z M54.7961165,34.9902913 L47.227,42.559 L57.13,52.462 L64.6990291,44.8932039 L64.6990291,64.6990291 L44.8932039,64.6990291 L52.462,57.13 L42.559,47.227 L34.9902913,54.7961165 L34.9902913,34.9902913 L54.7961165,34.9902913 Z M101.009709,34.9902913 L101.009709,54.7961165 L93.44,47.227 L83.537,57.13 L91.1067961,64.6990291 L71.3009709,64.6990291 L71.3009709,44.8932039 L78.869,52.462 L88.772,42.559 L81.2038835,34.9902913 L101.009709,34.9902913 Z" />
              </g>
            </g>
          </g>
          <g
            id="Group-5"
            transform="translate(34.990291, 34.990291)">
            <g id="Group-4">
              <g id="Group" />
              <g
                id="Group"
                transform="translate(51.165049, 14.854369) scale(-1, 1) translate(-51.165049, -14.854369) translate(36.310680, 0.000000)" />
            </g>
            <g
              id="Group-4"
              transform="translate(33.500000, 51.019417) scale(1, -1) translate(-33.500000, -51.019417) translate(0.000000, 36.019417)">
              <g id="Group" />
              <g
                id="Group"
                transform="translate(51.165049, 14.854369) scale(-1, 1) translate(-51.165049, -14.854369) translate(36.310680, 0.000000)" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'MCR',

  viewBox: '0 0 136 136',

  synonyms: ['MCR2'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
