import sdk from '@/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'

// Initial state
const coreState = {
  notificationPreferences: {},
}

const getters = {
  getGroupedUserNotifications: state => {
    // Handle v2 endpoint data structure
    if (state.notificationPreferences.destinations) {
      const emailNotifications = state.notificationPreferences.destinations.EMAIL || []
      return emailNotifications.reduce((acc, notification) => {
        const group = notification.messageGroup
        if (!acc[group]) {
          acc[group] = []
        }
        // Transform v2 format to match v3 format
        acc[group].push({
          subscriptionKey: notification.messageGroup,
          subscribed: notification.subscribed,
          messageGroupName: notification.messageGroup,
          description: notification.details,
        })
        return acc
      }, {})
    }

    // Handle v3 endpoint data structure (existing code)
    const emailNotifications = state.notificationPreferences.emails || []

    return emailNotifications.reduce((acc, notification) => {
      const group = notification.messageGroupName

      if (!acc[group]) {
        acc[group] = []
      }

      acc[group].push(notification)
      return acc
    }, {})
  },
}

const actions = {
  async getNotificationPreferences(context, apiVersion3 = false) {
    try {
      const res = await sdk.instance.notificationPreferences().get(apiVersion3)

      context.commit('setNotificationPreferences', res)
    } catch (error) {
      captureSentryError(error)
    }
  },

  async updateNotificationPreferences(context, payload) {
    try {
      // Update store immediately for UI feedback
      context.commit('patchNotificationPreferences', payload.emails)

      const res = await sdk.instance.notificationPreferences().update(payload, true)

      context.commit('setNotificationPreferences', res)
      context.commit(
        'Notifications/notifyMessage',
        {
          title: `Notification preferences successfully updated.`,
          message: res.message,
          type: 'success',
        },
        { root: true },
      )

      return res
    } catch (error) {
      // If the update fails, revert the store state
      context.dispatch('getNotificationPreferences')

      context.commit(
        'Notifications/notifyMessage',
        {
          title: `Failed to update notification preferences.`,
          message: error.message,
          type: 'error',
        },
        { root: true },
      )
      captureSentryError(error)
    }
  },

  // V2 API update
  async updateV2Preferences(context, payload) {
    await context.commit('setNotificationPreferences', payload)
    return new Promise((resolve, reject) => {
      sdk.instance
        .notificationPreferences()
        .update(payload)
        .then(res => {
          context.commit(
            'Notifications/notifyMessage',
            {
              title: 'Email notifications successfully updated.',
              message: res.message,
              type: 'success',
            },
            { root: true },
          )

          return resolve()
        })
        .catch(err => {
          console.warn(err)
          context.commit(
            'Notifications/notifyMessage',
            {
              title: 'Email notifications update failed.',
              message: err.message,
              type: 'error',
            },
            { root: true },
          )

          return reject(err)
        })
    })
  },
}

const mutations = {
  logout(state) {
    state.notificationPreferences = {}
  },
  setNotificationPreferences(state, payload) {
    state.notificationPreferences = payload
  },

  patchNotificationPreferences(state, payload) {
    state.notificationPreferences.emails = state.notificationPreferences.emails.map(notification => {
      const update = payload.find(p => p.subscriptionKey === notification.subscriptionKey)

      return update ? { ...notification, subscribed: update.subscribed } : notification
    })
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
