<template>
  <svg
    v-if="iconComponentName"
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :aria-labelledby="icon"
    :viewBox="viewBox"
    role="img"
    data-name="mega-icon">
    <title
      :data-icon-name="icon"
      lang="en">
      {{ icon }} icon
    </title>
    <component
      :is="iconComponentName"
      :fill-color="fillColor"
      :stroke-color="strokeColor" />
  </svg>
</template>

<script>
import icons from './icons'

export default {
  name: 'MuMegaIcon',

  components: {
    ...icons,
  },

  props: {
    /**
     * The name of the icon to display. Note that the icons have a base name associated
     * with them and also synonyms. This allows them to be referenced by any of their
     * synonym names. This is useful for things like VXC where the same icon is also
     * used for CXC, and we want to reference based on data coming from the API which
     * could use either of these names. Icon names are case insensitive.
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * You can pass in a whitelabel prefix. If an icon exist with the prefix,
     * it will render that icon. Otherwise, it will return the default icon
     * without the prefix.
     */
    whitelabelPrefix: {
      type: String,
      default: '',
    },
    /**
     * You can pass in the width that you want the icon displayed. This can be
     * a number of pixels or any CSS width specification.
     */
    width: {
      type: [Number, String],
      default: '100%',
    },
    /**
     * You can pass in the height that you want the icon displayed. This can be
     * a number of pixels or any CSS height specification.
     */
    height: {
      type: [Number, String],
      default: '100%',
    },
    /**
     * If you want to explicitly set the fill color of the image, pass in the CSS
     * color specification here. If not defined, it will use the current color.
     */
    fillColor: {
      type: String,
      default: 'currentColor',
    },
    /**
     * If you want to explicitly set the stroke color of the image, pass in the CSS
     * color specification here. If not defined, it will use the current color.
     */
    strokeColor: {
      type: String,
      default: 'currentColor',
    },
  },

  computed: {
    /**
     * Get the information about the icons, including their name synonyms and their
     * viewBox for display.
     */
    iconsInfo() {
      const iconFiles = Object.getOwnPropertyNames(icons)
      const iconsInfo = {}
      for (const file of iconFiles) {
        // All the synonyms that could be used to refer to the file
        const synonyms = []
        if (icons[file].name) {
          synonyms.push(icons[file].name.toLowerCase())
        }
        const fileSynonyms = icons[file].synonyms ?? []
        for (const synonym of fileSynonyms) {
          synonyms.push(synonym.toLowerCase())
        }

        // Get the viewbox from the component so it can be used at this level
        const viewBox = icons[file].viewBox ?? '0 0 200 200'

        iconsInfo[file] = {
          synonyms,
          viewBox,
        }
      }
      return iconsInfo
    },
    iconComponentName() {
      // From the supplied information, find the component that will render that icon
      // First, try to find icon with whitelabel prefix
      const lookup = `${this.whitelabelPrefix}${this.icon}`.toLowerCase()
      const iconNames = Object.getOwnPropertyNames(this.iconsInfo)
      for (const iconName of iconNames) {
        if (this.iconsInfo[iconName].synonyms.includes(lookup)) {
          return iconName
        }
      }

      // If prefix supplied & not found above, try to find default icon
      if (this.whitelabelPrefix.length) {
        const defaultLookup = this.icon.toLowerCase()
        for (const iconName of iconNames) {
          if (this.iconsInfo[iconName].synonyms.includes(defaultLookup)) {
            return iconName
          }
        }
      }

      return null
    },
    viewBox() {
      return this.iconsInfo[this.iconComponentName].viewBox
    },
  },
}
</script>
