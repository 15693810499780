import { computed, inject } from 'vue'
import { useStore } from 'vuex'
import type { RouteMeta, RouteRecordRaw } from 'vue-router'
import { cloneDeep } from 'lodash'

type ComplexCondition = {
  requiresNotifications?: () => boolean
  requiresCompanySupport?: () => boolean
  requiresMarketplaceUserProfile?: () => boolean
  auth?: (param: string) => boolean
  requiresFeatureFlag?: (param: string) => boolean
  requiresRuntimeFlag?: (param: string) => boolean
}

export function useRouteFiltering() {
  const store = useStore()
  const isFeatureEnabled = inject('isFeatureEnabled') as (feature: string) => boolean

  // Computed properties from store
  const hasCompany = computed(() => store.getters['Company/hasCompany'])
  const hasAuth = computed(() => store.getters['Auth/hasAuth'] as (role: string) => boolean)
  const isPartnerAccount = computed(() => store.getters['Auth/isPartnerAccount'] as boolean)
  const isPartnerSupported = computed(() => store.getters['Auth/isPartnerSupported'] as boolean)
  const isManagedAccount = computed(() => store.getters['Auth/isManagedAccount'] as boolean)
  const isManagedContext = computed(() => store.getters['ApplicationContext/isManagedContext'] as boolean)
  const isDirectAccount = computed(() => store.getters['Auth/isDirectAccount'] as boolean)
  const hasFeatureFlag = computed(() => store.getters['Auth/hasFeatureFlag'] as (feature: string) => boolean)
  const marketplaceUserProfile = computed(() => store.getters['Marketplace/marketplaceUserProfile'])

  const filterRoutesByMeta = (routes: RouteRecordRaw[]) => {
    // Simple conditions
    const metaConditions = {
      requiresCompany: hasCompany.value,
      requiresPartnerAccount: isPartnerAccount.value,
      requiresDirectAccount: isDirectAccount.value,
      requiresPartnerSupported: isPartnerSupported.value,
      requiresManagedAccount: isManagedAccount.value,
      excludeManagedContext: !isManagedContext.value,
    }

    // Complex conditions
    const complexConditions: ComplexCondition = {
      requiresRuntimeFlag: (param: string) => isFeatureEnabled(param),
      requiresNotifications: () => {
        if (isManagedContext.value) return false
        if (isManagedAccount.value) return isFeatureEnabled('NOTIFICATIONS_ENABLED')
        return true
      },
      requiresCompanySupport: () =>
        ((isPartnerAccount.value || isPartnerSupported.value || isManagedAccount.value) && !isManagedContext.value) ||
        isDirectAccount.value,
      requiresMarketplaceUserProfile: () => {
        if (marketplaceUserProfile.value?.companyName) {
          return true // we have a populated profile
        }

        return (
          hasAuth.value('company_admin') && isFeatureEnabled('MARKETPLACE_PROFILE_ENABLED') && !isManagedContext.value
        )
      },
      auth: req => hasAuth.value(req),
      requiresFeatureFlag: req => hasFeatureFlag.value(req),
    }

    const routesCopy = cloneDeep(routes)

    return routesCopy.filter(route => {
      // Check simple conditions
      for (const [condition, value] of Object.entries(metaConditions)) {
        if (route.meta?.[condition as keyof RouteMeta] && !value) return false
      }

      // Check complex conditions
      for (const [condition, check] of Object.entries(complexConditions)) {
        const metaValue = route.meta?.[condition as keyof RouteMeta]
        if (metaValue && typeof metaValue !== 'object' && !check(metaValue as string)) return false
      }

      // Handle children recursively
      if (route.children?.length) {
        route.children = filterRoutesByMeta(route.children)
        return !!(route.meta?.groupName || route.meta?.menuName)
      }

      return true
    })
  }

  return {
    filterRoutesByMeta,
  }
}
