import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type {
  UpdateCardPayload,
  PaymentPayload,
  OneOffPaymentPayload,
  RegisterCardPayload,
  ConfirmationPayload,
} from './types/stripe'
import {
  getPaymentsSchema,
  getCardsSchema,
  registerCardSchema,
  updateCardSchema,
  makePaymentSchema,
  registerCardConfirmationSchema,
  paymentConfirmationSchema,
} from './schemas/stripe'

/**
 * Returns object for dealing with all aspects of stripe payments.
 *
 * Note that when we talk about 'supplier' below, this is the Megaport
 * branch that supplies the services and bills the user.
 */
export default class StripeResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get all payments by the user with the supplied market supplier id
   * @param supplierId
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getPayments(supplierId: number, settings?: APISettings) {
    const params = {
      supplierId: supplierId,
    }
    const response = await this.#api.get('/v2/stripe/payments', settings, { params, schema: getPaymentsSchema })
    return response.body.data
  }

  /**
   * Get cards for this user with the specified market supplier id
   * @param supplierId
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async getCards(supplierId: number, settings?: APISettings) {
    const params = {
      supplierId,
    }
    const response = await this.#api.get('/v2/stripe/card', settings, { params, schema: getCardsSchema })
    return response.body.data
  }

  /**
   * Update the details of a card
   * @param cardObj The updated fields for the card. Excluded fields will not be changed
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async updateCard({ id, ...data }: UpdateCardPayload, settings?: APISettings) {
    const response = await this.#api.put(`/v2/stripe/card/${id}`, settings, { data, schema: updateCardSchema })
    return response.body.data
  }

  /**
   * Delete the card with the specified Id
   * @param cardId
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async deleteCard(cardId: number, settings?: APISettings) {
    const response = await this.#api.delete(`/v2/stripe/card/${cardId}`, settings)
    return response.body.data || response.body
  }

  /**
   * To register the card for recurring payments
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async registerCard(data: RegisterCardPayload, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/stripe/card', settings, { data, schema: registerCardSchema })
    return response.body.data
  }

  /**
   * To make payment using the registered card
   * @param cardId
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async makeRegisteredCardPayment(cardId: number, data: PaymentPayload, settings?: APISettings) {
    const response = await this.#api.jpost(`/v2/stripe/card/${cardId}/pay`, settings, {
      data,
      schema: makePaymentSchema,
    })
    return response.body.data
  }

  /**
   * To make one-off payment without registering the card
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async makeOneOffPayment(data: OneOffPaymentPayload, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/stripe/pay', settings, { data, schema: makePaymentSchema })
    return response.body.data
  }

  /**
   * To confirm card registration
   * @param cardId
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async registerCardConfirmation(cardId: number, data: ConfirmationPayload, settings?: APISettings) {
    const response = await this.#api.put(`/v2/stripe/card/${cardId}/confirm`, settings, {
      data,
      schema: registerCardConfirmationSchema,
    })
    return response.body.data
  }

  /**
   * To confirm payment
   * @param cardId
   * @param data
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async paymentConfirmation(cardId: number, data: ConfirmationPayload, settings?: APISettings) {
    const response = await this.#api.put(`/v2/stripe/pay/${cardId}/confirm`, settings, {
      data,
      schema: paymentConfirmationSchema,
    })
    return response.body.data
  }
}
