import { string, number, boolean, optional } from 'zod'

import { apiResponse } from '../../megaport/schemas'

export const getEmployeeSchema = apiResponse({
  partyId: number().positive(),
  active: boolean(),
  firstName: string(),
  lastName: string(),
  email: string(), // Omitting email validation due to zod issue, see https://github.com/colinhacks/zod/issues/2193
  position: string(), // Due to be removed and replaced with securityRoles field
  username: string(), // Ideally should be an email but not all users have email usernames
  requireTotp: boolean(),
  newsletter: boolean(),
  promotions: boolean(),
  mfaEnabled: boolean(),
  phone: optional(string()), // Only returned when there is a value otherwise field is omitted entirely
})
