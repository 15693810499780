<template>
  <g>
    <path
      d="M13.6003 15.4066L1.70004 15.4066L1.70004 4.25008L4.37498e-06 4.25008L3.88731e-06 15.4066C3.84882e-06 16.2872 0.76077 17.0004 1.70004 17.0004L13.6003 17.0004L13.6003 15.4066Z"
      fill="#949494" />
    <path
      d="M2.54968 2C2.54968 0.895432 3.44511 0 4.54968 0H15C16.1046 0 17 0.895431 17 2V12.4503C17 13.5549 16.1046 14.4503 15 14.4503H2.54968V2Z"
      fill="#949494" />
    <path
      d="M7.87936 3.89047C6.42156 3.89047 5.22568 5.14279 5.22568 6.66938C5.22568 8.19598 6.42156 9.44829 7.87936 9.44829C8.93136 9.44829 9.80875 8.77925 10.2569 7.86034H11.2912V9.0513H13.5658V7.86034H14.324V5.47842H13.9449H10.2628C9.84029 4.55565 8.95568 3.89047 7.87936 3.89047ZM7.87936 4.68445C8.7438 4.68445 9.43083 5.24857 9.6823 6.00567L9.77041 6.27239H13.5658V7.06637H12.8076V8.25733H12.0494V7.06637H9.78152L9.68748 7.31836C9.39959 8.08881 8.69977 8.65432 7.87936 8.65432C6.83512 8.65432 5.98387 7.7629 5.98387 6.66938C5.98387 5.57586 6.83512 4.68445 7.87936 4.68445ZM7.87936 5.47842C7.25574 5.47842 6.74207 6.01633 6.74207 6.66938C6.74207 7.32243 7.25574 7.86034 7.87936 7.86034C8.50298 7.86034 9.01666 7.32243 9.01666 6.66938C9.01666 6.01633 8.50298 5.47842 7.87936 5.47842ZM7.87936 6.27239C8.09322 6.27239 8.25846 6.44543 8.25846 6.66938C8.25846 6.89333 8.09322 7.06637 7.87936 7.06637C7.6655 7.06637 7.50027 6.89333 7.50027 6.66938C7.50027 6.44543 7.6655 6.27239 7.87936 6.27239Z"
      fill="white" />
  </g>
</template>

<script>
export default {
  name: 'MultipleKey',

  viewBox: '0 0 17 17',
}
</script>
