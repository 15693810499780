import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

/**
 * Read and write notification preferences.
 */
export default class NotificationPreferencesResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Returns an object containing message destinations, each one with an array of groups.
   * Each group has an array of details and whether the user is subscribed
   *
   * @param version3 - Whether to use the v3 API endpoint
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async get(version3: boolean = false, settings?: APISettings) {
    const response = await this.#api.get(`/v${version3 ? '3' : '2'}/notificationPreferences`, settings)

    return response.body.data || response.body
  }

  /**
   * Update the notification preferences. The format of the data is exactly the same as for the get command above.
   * Just change the subscription status as required.
   *
   * @param data
   * @param version3 - Whether to use the v3 API endpoint
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async update(data: any, version3: boolean = false, settings?: APISettings) {
    const response = await this.#api.put(`/v${version3 ? '3' : '2'}/notificationPreferences`, settings, { data })
    return response.body.data || response.body
  }
}
