import type MegaportAPI from '../megaport/api'
import type { APISettings } from '../megaport/types'

import type { UpdateSsoPayload, CreateConnectionPayload } from './types/sso'
import { ssoConfigurationSchema, ssoCreateIdpSchema } from './schemas/sso'

/**
 * Returns object for dealing with all aspects of SSO.
 */

export default class SSOResource {
  #api: MegaportAPI

  /**
   * @param api The instance of MegaportAPI used to make API calls within the resource
   */
  constructor(api: MegaportAPI) {
    this.#api = api
  }

  /**
   * Get SSO configuration
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async config(settings?: APISettings) {
    const response = await this.#api.get('/v2/auth/sso/configuration', settings, { schema: ssoConfigurationSchema })
    return response.body.data
  }

  /**
   * Create new IdP for company
   * @param data IdP details object
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async create(data: CreateConnectionPayload, settings?: APISettings) {
    const response = await this.#api.jpost('/v2/auth/sso/idp/saml', settings, { data, schema: ssoCreateIdpSchema })
    return response.body.data
  }

  /**
   * Delete IdP from company
   * @param idpId
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async delete(idpId: number, settings?: APISettings) {
    await this.#api.delete(`/v2/auth/sso/idp/${idpId}`, settings)
  }

  /**
   * Update existing IdP for company
   * @param data The updated fields for the IdP
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async update({ idpId, ...data }: UpdateSsoPayload, settings?: APISettings) {
    await this.#api.put(`/v2/auth/sso/idp/saml/${idpId}`, settings, { data })
  }

  /**
   * Toggle SSO Enforcement
   * @param ssoEnforced
   * @param settings Additional settings to adjust the generated API request
   * @returns
   */
  async setEnforced(ssoEnforced: boolean, settings?: APISettings) {
    const data = { ssoEnforced }
    await this.#api.put(`/v2/auth/sso/configuration`, settings, { data })
  }
}
