<template>
  <g
    id="Page-1"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd">
    <g
      id="Megaport-Copy"
      transform="translate(-32.000000, -32.000000)"
      :fill="fillColor">
      <g
        id="Group"
        transform="translate(32.000000, 32.000000)">
        <path
          id="Oval-Copy-9"
          d="M68,0 C105.555363,0 136,30.444637 136,68 C136,105.555363 105.555363,136 68,136 C30.444637,136 0,105.555363 0,68 C0,30.444637 30.444637,0 68,0 Z M68,5 C33.2060608,5 5,33.2060608 5,68 C5,102.793939 33.2060608,131 68,131 C102.793939,131 131,102.793939 131,68 C131,33.2060608 102.793939,5 68,5 Z"
          fill-rule="nonzero" />
        <path
          id="Combined-Shape-Copy-2"
          d="M68,22.4466019 C73.8181415,22.4466019 79.4901743,23.5394184 84.7884448,25.6409656 C87.8428462,26.852487 90.7529845,28.3917355 93.4709979,30.2279285 L93.639729,30.341718 C90.3549489,31.9327269 85.4309492,34.3475368 80.18922,37.0240002 C78.9746061,36.2087615 78.0793064,35.7254826 76.1204839,34.94852 C71.5938065,33.1530235 66.747413,32.2192809 61.7661939,32.2192809 C40.6819932,32.2192809 23.1496195,45.8636298 22.4673246,66.7216052 L22.4516019,67.3396019 L22.4527048,67.2466913 C22.8549773,42.4356934 43.0931374,22.4466019 68,22.4466019 Z"
          fill-rule="nonzero" />
        <path
          id="Combined-Shape-Copy-3"
          d="M100.349515,33.6699029 C100.349515,33.6699029 84.8708699,72.1683847 79.1613813,78.0883494 C73.4257263,84.0029127 64.1523864,84.0029127 58.4428977,78.0883494 C52.7020095,72.1683847 52.7020095,62.6024564 58.4324312,56.6824917 C64.1523864,50.7949356 100.349515,33.6699029 100.349515,33.6699029 Z M68,60.0776699 C63.6246179,60.0776699 60.0776699,63.6246179 60.0776699,68 C60.0776699,72.3753821 63.6246179,75.9223301 68,75.9223301 C72.3753821,75.9223301 75.9223301,72.3753821 75.9223301,68 C75.9223301,63.6246179 72.3753821,60.0776699 68,60.0776699 Z" />
      </g>
    </g>
  </g>
</template>

<script>
export default {
  name: 'PortIcon',

  viewBox: '0 0 136 136',

  synonyms: ['megaport', 'port'],

  props: {
    fillColor: {
      type: String,
      required: true,
    },
    strokeColor: {
      type: String,
      required: true,
    },
  },
}
</script>
