<template>
  <el-dialog
    :model-value="modelValue"
    :before-close="handleClose"
    :title="$t(dialogTitle)"
    :modal-append-to-body="false"
    data-testid="cancellation-dialog"
    width="55%">
    <div
      v-if="loadingCharges && !statusIsDesign"
      v-loading="loadingCharges"
      class="cancel-service-item terminating height-40px" />
    <!-- Dialog Body -->
    <template v-else>
      <!-- Service still in design with an 'ALL' scope -->
      <div v-if="statusIsDesign && serviceScopeIsAll">
        {{ $t('cancel.clear-configured') }}
      </div>
      <!-- Service still in design -->
      <template v-else-if="statusIsDesign">
        <!-- Confirmation of removal question -->
        <div>{{ $t('cancel.remove-from-configured') }}</div>
        <!-- Service Card -->
        <div class="cancel-service-item">
          <MuMegaIcon
            :icon="cancellingService.serviceIcon"
            class="service-type-icon" />
          <div>{{ cancellingService.productName }}</div>
        </div>
      </template>
      <!-- Service no longer in design -->
      <template v-else>
        <el-form
          ref="cancelForm"
          :model="cancelForm"
          :rules="cancelRules">
          <!-- Service is a port and has not been cancelled -->
          <div
            v-if="statusIsNotCancelled && canTerminate"
            class="mb-1">
            <el-form-item
              prop="cancelNow"
              class="cancel-content">
              <el-radio-group
                v-if="serviceIsPort"
                v-model="cancelForm.cancelNow"
                name="terminate-services-now-later">
                <!-- Later termination available -->
                <el-radio-button
                  v-if="statusIsLive"
                  :value="false"
                  class="inverse-padding"
                  data-name="terminateLater">
                  <el-tooltip
                    placement="top"
                    :content="$t('cancel.end-of-contract')"
                    :show-after="500">
                    <span>{{ $t('cancel.later') }}</span>
                  </el-tooltip>
                </el-radio-button>
                <el-radio-button
                  :value="true"
                  class="inverse-padding"
                  data-name="terminateNow">
                  <el-tooltip
                    placement="top"
                    :content="$t('cancel.terminate-immediately')"
                    :show-after="500">
                    <span>{{ $t('cancel.now') }}</span>
                  </el-tooltip>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
          </div>

          <div class="min-height-40px py-0-5">
            <transition
              name="switch-content"
              mode="out-in">
              <p
                v-if="!canTerminate"
                class="my-0">
                {{ $t('cancel.unable-to-cancel') }}
              </p>
              <p
                v-else-if="cancelForm.cancelNow === true"
                key="cnow"
                class="my-0">
                {{ $t('cancel.now-confirmation') }}
              </p>
              <p
                v-else-if="cancelForm.cancelNow === false"
                key="clater"
                class="my-0">
                {{ $t('cancel.later-confirmation') }}
              </p>
            </transition>
          </div>

          <div
            v-for="(charge, index) in charges"
            :key="`charge-${index}`">
            <div
              class="cancel-service-item terminating"
              data-testid="cancellation-service-item"
              :class="charge.terminateMessage ? 'forbidden' : ''">
              <MuMegaIcon
                v-if="charge.serviceIcon"
                :icon="charge.serviceIcon"
                class="service-type-icon" />
              <div
                v-if="charge.productName"
                class="service-content">
                <div>{{ charge.productName }}</div>
                <div
                  v-if="canTerminate"
                  class="min-height-2-2rem">
                  <transition
                    name="switch-content"
                    mode="out-in">
                    <div
                      v-if="cancelForm.cancelNow === false"
                      key="cancellater"
                      class="fs-1-3rem">
                      {{ terminateDate(charge.terminationDate) }}
                    </div>
                    <div
                      v-else-if="cancelForm.cancelNow === true"
                      key="cancelnow"
                      class="fs-1-3rem">
                      {{ $t('cancel.immediate') }}
                    </div>
                  </transition>
                </div>
              </div>
              <el-input
                v-if="showPricing"
                :model-value="charge.displayMoney"
                size="small"
                class="service-cancellation"
                data-testid="cancellation-fee"
                disabled />
              <div
                v-if="charge.terminateMessage"
                class="terminate-forbidden">
                <p>
                  <span class="font-weight-500">
                    {{ $t('cancel.unable') }}
                  </span>
                  {{ charge.terminateMessage }}
                </p>
              </div>
            </div>
          </div>
          <template v-if="canTerminate">
            <div
              v-for="(message, index) in extra"
              :key="`extra-${index}`"
              class="flex-row-centered mt-1">
              <div>
                <i
                  class="fas fa-exclamation-triangle color-warning mr-1 fs-2-5rem"
                  aria-hidden="true" />
              </div>
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="message" />
            </div>

            <el-form-item
              prop="reason"
              :label="$t('cancel-reason.label')"
              label-width="200px"
              class="mt-3">
              <el-select
                v-model="cancelForm.reason"
                class="width-4/5"
                data-testid="select-cancellation-reason">
                <el-option
                  v-for="reason in cancelReasons"
                  :key="reason.code"
                  :label="$t(reason.translationPath)"
                  :value="reason.code"
                  data-testid="cancellation-reason" />
              </el-select>
            </el-form-item>

            <el-form-item
              prop="comment"
              :label="$t('general.comments')"
              label-width="200px">
              <el-input
                v-model="cancelForm.comment"
                type="textarea"
                :rows="5"
                class="width-4/5"
                data-testid="cancellation-comments" />
              <p class="feedback-prompt">
                {{ $t('cancel-reason.feedback-prompt') }}
              </p>
            </el-form-item>
          </template>
        </el-form>
      </template>
    </template>

    <!-- Dialog Footer -->
    <template
      v-if="statusIsDesign"
      #footer>
      <el-button
        data-testid="keep-configured-button"
        @click="setVisible(false)">
        {{ $t(serviceScopeIsAll ? 'cancel.keep-configured-services' : 'cancel.keep-configured-service') }}
      </el-button>
      <el-button
        data-testid="remove-configuration-button"
        type="primary"
        @click="cancelServices">
        {{ $t('cancel.remove-configuration') }}
      </el-button>
    </template>
    <template
      v-else
      #footer>
      <el-button
        data-testid="close-button"
        @click="setVisible(false)">
        {{ canTerminate ? $t('cancel.keep-services') : $t('general.close') }}
      </el-button>
      <el-button
        v-if="charges.length > 0 && cancelForm.cancelNow !== null && canTerminate"
        :disabled="cancellingActive"
        data-testid="terminate-services-button"
        type="primary"
        @click="cancelServices">
        {{ $t('services.terminate-count', { count: charges.length }, charges.length) }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
// External tools
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { ElMessageBox, ElNotification } from 'element-plus'
import { shuffle } from 'lodash'

// Internal tools
import sdk from '@/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { moneyFilter, mpDate, deepClone } from '@/helpers.js'
import { CLOUD_ITEMS, CANCEL_REASONS } from '@/Globals.js'
// Integrations
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'
// Components
import MuMegaIcon from '@/mega-ui/components/leafs/mega-icon/MuMegaIcon.vue'

// This is set up to use v-model for toggling visibility, so when the dialog needs to close it emits the update message.
export default {
  name: 'CancelServices',

  components: {
    MuMegaIcon,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    modelValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    serviceUid: {
      type: String,
      required: true,
    },
  },
  emits: ['update:modelValue'],

  data() {
    return {
      cancelForm: {
        cancelNow: null,
        reason: null,
        comment: '',
      },
      cancelRules: {
        cancelNow: {
          required: true,
          message: this.$t('general.please-select'),
          trigger: 'change',
        },
        reason: {
          required: true,
          message: this.$t('validations.please-select', { thing: this.$t('cancel-reason.label') }),
          trigger: ['blur', 'change'],
        },
        comment: {
          min: 0,
          max: 400,
          message: this.$t('validations.max-length', { max: 400 }, 400),
        },
      },
      cancellingActive: false,
      nowCharges: [],
      laterCharges: [],
      loadingChargesValue: 0,
      destroyed: false, // Protect against processing the promise response if component is going away
      cancelReasons: shuffle(CANCEL_REASONS),
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapState('Services', ['services']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Services', ['getServicesById', 'myPorts', 'findPort', 'allServicesUidDictionary']),

    showPricing() {
      return (
        !this.disabledFeatures.showPrices &&
        this.isFeatureEnabled('PRICING_VISIBLE') &&
        this.cancelForm.cancelNow !== null &&
        this.canTerminate
      )
    },
    charges() {
      return this.cancelForm.cancelNow ? this.nowCharges : this.laterCharges
    },
    loadingCharges() {
      return this.loadingChargesValue < 2
    },
    cancellingService() {
      // Clearing all in-DESIGN services by clicking on the top level bin icon in the Configured Services sidebar
      if (this.serviceUid === 'DESIGNED') {
        return { provisioningStatus: this.G_PROVISIONING_DESIGN, scope: 'ALL' }
      }

      // Clearing an individual in-DESIGN service
      const service = this.allServicesUidDictionary[this.serviceUid] || false

      if (service) {
        const serviceIcon =
          service.productType === this.G_PRODUCT_TYPE_VXC ? this.getVxcIconFor(service) : service.productType

        return { ...service, serviceIcon }
      } else {
        return false
      }
    },
    extra() {
      const messages = []
      if (this.cancellingService && this.cancellingService.productType === this.G_PRODUCT_TYPE_VXC) {
        const b = this.findPort(this.cancellingService.bEnd.productUid)

        if (b && ['ALIBABA', 'AZURE', 'ORACLE', 'NUTANIX'].includes(b.connectType)) {
          messages.push(this.$t('connections.cancel-a-end', { companyName: b.companyName }))
        }
      }
      return messages
    },
    canTerminate() {
      return this.charges.every(charge => !charge.terminateMessage)
    },
    serviceIsPort() {
      return this.cancellingService.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    serviceScopeIsAll() {
      return this.cancellingService.scope === 'ALL'
    },
    serviceStatus() {
      return this.cancellingService?.provisioningStatus
    },
    statusIsDesign() {
      return this.serviceStatus === this.G_PROVISIONING_DESIGN
    },
    statusIsNotCancelled() {
      return this.serviceStatus !== this.G_PROVISIONING_CANCELLED
    },
    statusIsLive() {
      return this.serviceStatus === this.G_PROVISIONING_LIVE
    },
    dialogTitle() {
      return this.statusIsDesign ? 'cancel.remove-configured' : 'cancel.terminate'
    },
    /**
     * Check if any IBM connections are to be cancelled to display modal after
     * cancellation reminding users to confirm connection in IBM portal
     */
    serviceContainsIbm() {
      // Check if we're cancelling an IBM VXC
      // NOTE: The API returns csp_connection as an object when it contains a single connection
      // and as an array when it contains multiple, so we need to check its structure.
      const cspConnection = this.cancellingService.resources?.csp_connection

      const cancelIbmConnection = Array.isArray(cspConnection)
        ? cspConnection.some(({ csp_name }) => csp_name === 'IBM')
        : cspConnection?.csp_name === 'IBM'

      if (cancelIbmConnection) return true

      // Check if we're cancelling a service containing any IBM VXCs
      const ibmCloudItem = CLOUD_ITEMS.find(item => item.title === 'IBM Cloud')

      const cancelIbmService = this.cancellingService.associatedVxcs
        ? this.cancellingService.associatedVxcs.some(({ bEnd: { ownerUid } = {} }) =>
            ibmCloudItem.companyUids.includes(ownerUid),
          )
        : false

      return cancelIbmService
    },
  },

  mounted() {
    this.cancelForm.cancelNow = this.serviceIsPort ? null : true

    if (this.serviceStatus && !this.statusIsDesign) {
      this.loadCancelNowCharges()
      if (this.statusIsLive) {
        this.loadCancelLaterCharges()
      } else {
        this.loadingChargesValue++
        this.cancelForm.cancelNow = true
      }
    }

    if (!this.statusIsNotCancelled) {
      this.updateCancelNow(true)
    }
  },

  beforeUnmount() {
    this.destroyed = true
  },

  methods: {
    ...mapActions('Services', [
      'editService',
      'clearCart',
      'getMyServices',
      'fetchServiceOrConnection',
      'removeServiceOrConnection',
    ]),
    ...mapActions('Notifications', ['showIbmReminderModal']),
    ...mapMutations('Notifications', ['notifyMessage']),

    setVisible(newValue) {
      this.$emit('update:modelValue', newValue)
    },
    handleClose(done) {
      this.setVisible(false)
      done()
    },
    updateCancelNow(tf) {
      this.cancelForm.cancelNow = tf
    },
    loadCancelNowCharges() {
      this.loadingChargesValue = 0
      sdk.instance
        .product(this.serviceUid)
        .cancelCharges(true)
        .then(charges => {
          this.nowCharges = this.chargeConcat(charges)
          this.loadingChargesValue++
        })
        .catch(e => {
          if (!this.destroyed) {
            const service = this.allServicesUidDictionary[this.serviceUid] || false

            if (e.status === 409) {
              if (e.data?.message) {
                this.nowCharges = [
                  {
                    terminateMessage: e.data.message,
                    ...(service && {
                      productName: service.productName,
                      productType: service.productType,
                    }),
                  },
                ]
              }
            } else if (e.status === 403) {
              this.nowCharges = [
                {
                  terminateMessage: this.$t('cancel.no-permission'),
                  ...(service && {
                    productName: service.productName,
                    productType: service.productType,
                  }),
                },
              ]
            } else {
              this.nowCharges = [
                {
                  terminateMessage: this.$t('cancel.error'),
                  ...(service && {
                    productName: service.productName,
                    productType: service.productType,
                  }),
                },
              ]
              captureSentryError(e)
            }
            this.loadingChargesValue++
          }
        })
    },
    loadCancelLaterCharges() {
      sdk.instance
        .product(this.serviceUid)
        .cancelCharges(false)
        .then(charges => {
          this.laterCharges = this.chargeConcat(charges)
          this.loadingChargesValue++
        })
        .catch(e => {
          if (!this.destroyed) {
            const service = this.allServicesUidDictionary[this.serviceUid] || false

            if (e.status === 409) {
              if (e.data?.message) {
                this.laterCharges = [
                  {
                    terminateMessage: e.data.message,
                    ...(service && {
                      productName: service.productName,
                      productType: service.productType,
                    }),
                  },
                ]
              }
            } else if (e.status === 403) {
              this.laterCharges = [
                {
                  terminateMessage: this.$t('cancel.no-permission'),
                  ...(service && {
                    productName: service.productName,
                    productType: service.productType,
                  }),
                },
              ]
            } else {
              this.laterCharges = [
                {
                  terminateMessage: this.$t('cancel.error'),
                  ...(service && {
                    productName: service.productName,
                    productType: service.productType,
                  }),
                },
              ]
              captureSentryError(e)
            }

            this.loadingChargesValue++
          }
        })
    },
    terminateDate(value) {
      return mpDate(value)
    },
    extractRelevantCancellationData(charge) {
      const { productId, total, currency, productName, terminationDate, terminateMessage } = charge

      const displayMoney = moneyFilter(total, currency)

      let serviceIcon = charge.productType

      const service = this.getServicesById(productId)?.[0]

      if (service?.productType === this.G_PRODUCT_TYPE_VXC) {
        serviceIcon = this.getVxcIconFor(service)
      }

      return { displayMoney, productName, serviceIcon, terminationDate, terminateMessage }
    },
    chargeConcat(charge) {
      const list = []
      list.push(this.extractRelevantCancellationData(charge))
      if (charge.sublagPriceCheck?.length) {
        for (const subCharge of charge.sublagPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }
      if (charge.ixPriceCheck?.length) {
        for (const subCharge of charge.ixPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }
      if (charge.vxcPriceCheck?.length) {
        for (const subCharge of charge.vxcPriceCheck) {
          list.push(this.extractRelevantCancellationData(subCharge))
        }
      }

      return list
    },
    getVxcIconFor(connection) {
      let icon = this.G_PRODUCT_TYPE_VXC

      const aEndDetails = this.findPort(connection.aEnd.productUid)
      const bEndDetails = this.findPort(connection.bEnd.productUid)

      if (aEndDetails?.companyUid && bEndDetails?.companyUid) {
        const foreignEnd = [aEndDetails, bEndDetails].find(end => end.companyUid !== this.companyUid)

        if (foreignEnd) {
          const marketplaceProfile = this.marketplaceData.find(profile => profile.companyUid === foreignEnd.companyUid)

          if (marketplaceProfile) icon = 'Marketplace'

          if (foreignEnd.connectType === 'TRANSIT') icon = 'MegaportInternet'
        }
      }

      return icon
    },
    async blockForDiverseLinks() {
      const h = this.$createElement

      const others = []
      const relevantPorts = []

      for (const port of this.myPorts) {
        if (port.provisioningStatus === this.G_PROVISIONING_DESIGN && port.uiConfig?.diverseFrom === this.serviceUid) {
          others.push(h('li', null, port.productName))
          relevantPorts.push(port)
        }
      }

      if (others.length) {
        try {
          const message = h('div', { ref: 'diverseConfirmation' }, [
            h('p', null, this.$t('ports.delete-diverse-from', others.length)),
            h('ul', null, others),
            h('p', null, this.$t('ports.reset-diversity', others.length)),
            h('p', null, this.$t('general.action-confirmation')),
          ])
          await ElMessageBox.confirm(message, this.$t('cancel.diversity'), {
            confirmButtonText: this.$t('general.yes'),
            cancelButtonText: this.$t('general.no'),
            type: 'warning',
            showClose: false,
            closeOnClickModal: false,
          })

          // Now do the actual tidy up work
          for (const port of relevantPorts) {
            const service = deepClone(port)
            service.config.diversityZone = null
            service.config.diverseFrom = null

            // This will find the existing one and update it
            this.editService({ service })
          }
        } catch (error) {
          return true
        }
      }
      return false
    },
    async cancelServices() {
      if (await this.blockForDiverseLinks()) {
        return false
      }
      this.cancellingActive = true
      if (!this.statusIsDesign) {
        this.$_cancelLiveServices()
      } else if (this.serviceScopeIsAll) {
        this.$_removeAllConfiguredServices()
      } else {
        this.$_removeSingleConfiguredService()
      }
    },
    $_cancelLiveServices() {
      this.$refs.cancelForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            type: 'error',
            duration: 3000,
          }

          ElNotification(props)
          this.cancellingActive = false

          return false
        }

        // Capture the terminate event once a valid confirmation is submitted
        captureEvent(
          `cancel-${productTypeToEvent(this.cancellingService.productType)}.terminate.click`,
          this.cancelForm,
        )

        // Show IBM modal if an IBM connection or a service containing an IBM connection is cancelled
        if (this.serviceContainsIbm) {
          this.showIbmReminderModal()
        }

        sdk.instance
          .product(this.serviceUid)
          .cancel(this.cancelForm.cancelNow, {
            cancellation_reason: this.cancelForm.reason,
            cancellation_comment: this.cancelForm.comment,
          })
          .then(data => {
            // Wait until the service has been successfully cancelled at an API level before removing the local record
            if (this.cancelForm.cancelNow) {
              this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
            }

            this.notifyMessage({
              title: this.$t(
                this.cancelForm.cancelNow ? 'cancel.terminated' : 'cancel.terminating',
                { count: this.charges.length },
                this.charges.length,
              ),
              message: data.message,
              type: 'success',
            })

            if (!this.cancelForm.cancelNow) {
              this.getMyServices()
            }

            this.setVisible(false)
          })
          .catch(e => {
            if (!this.destroyed) {
              if (e.data && e.data.message) {
                this.notifyMessage({
                  title: this.$t('cancel.issues-terminating', { count: this.charges.length }, this.charges.length),
                  message: e.data.message,
                  type: 'error',
                })
              } else {
                this.notifyMessage({
                  title: this.$t('cancel.issues-terminating', { count: this.charges.length }, this.charges.length),
                  message: this.$t('general.unknown-error'),
                  type: 'error',
                })

                captureSentryError(e)
              }
              this.fetchServiceOrConnection({ productUid: this.serviceUid }).catch(() => {
                // empty function is intentional
              })
            }
          })
          .finally(() => {
            this.cancellingActive = false
          })
      })
    },
    $_removeAllConfiguredServices() {
      captureEvent('cancel-all.remove-config.click')
      this.clearCart()
      this.setVisible(false)
    },
    $_removeSingleConfiguredService() {
      captureEvent(`cancel-${productTypeToEvent(this.cancellingService.productType)}.remove-config.click`)

      // Cancelling a single configured service. Need to find out if it was a port, and if it was a LAG primary, and if so,
      // transfer the LAG primary status to another port if one is available.

      // We know the service id, so can find it in the services
      const port = this.services.find(p => p.productUid === this.serviceUid)
      if (port && port.lagPrimary && port._subLags && port._subLags.length) {
        const service = { ...port._subLags[0] }
        service.lagPrimary = true
        this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
        this.editService({ service })
      } else {
        this.removeServiceOrConnection({ productUid: this.serviceUid, cartCleanup: true })
      }
      this.setVisible(false)
    },
  },
}
</script>

<style lang="scss" scoped>
.cancel-content {
  width: fit-content;
  margin: auto;
}

.hr-spacing {
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}

.cancel-service-item {
  &.terminating {
    width: unset;
    padding: 1.3rem;
    margin: 1rem auto;
  }
  &.forbidden {
    background-color: var(--color-danger-light-8);
  }
  .terminate-forbidden {
    margin-left: auto;
    p {
      white-space: normal;
      word-break: break-word;
      line-height: 1.3rem;
      margin: 0.2rem 0;
    }
  }
}

.switch-content-enter-active,
.switch-content-leave-active {
  transition: opacity 0.3s ease;
}
.switch-content-enter,
.switch-content-leave-to {
  opacity: 0;
}

.service-content {
  white-space: normal;
  word-break: normal;
  line-height: normal;
}

.service-cancellation {
  width: 15rem;
  margin-left: auto;
}

// This allows us to put popovers on individual radio buttons and have them
// display on hover anywhere on the button.
.inverse-padding {
  .el-radio-button__inner {
    padding: 12px 0;
  }
}

.fs-2-5rem {
  font-size: 2.5rem;
}

.feedback-prompt {
  box-sizing: border-box;
  width: 80%;
  line-height: 1.2em;
  margin-bottom: 0.5rem;
}
</style>

<style lang="scss">
.service-cancellation.el-input.is-disabled .el-input__inner {
  color: var(--color-text-regular);
  text-align: right;
}
</style>
