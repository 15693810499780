import { defaultLanguage, languages, type MpLanguage } from '@/i18n/languages'
import i18n from '@/i18n/i18n'

/**
 * Gets the selected language from cookies or localStorage. Falls back to defaultLanguage if not found.
 *
 * @returns The selected language record from `languages` in `@/i18n/languages.js`.
 */
export const getSavedLanguage = () => {
  const cookies = new URLSearchParams(document.cookie.replace(/; /g, '&'))
  let resolvedLanguage

  // Look for the language entry that includes the region, fall back to just the base language entry
  const resolvedIso = cookies.get('mp_lang_region') ?? window.localStorage.getItem('mp_lang_region')
  if (resolvedIso) resolvedLanguage = languages.find(lang => lang.iso === resolvedIso)
  if (!resolvedLanguage) {
    const resolvedCode = cookies.get('mp_lang') ?? window.localStorage.getItem('mp_lang')
    if (resolvedCode) resolvedLanguage = languages.find(lang => lang.code === resolvedCode)
  }

  // Avoid the possibility of it being set to something we don't know how to translate.
  return resolvedLanguage ?? defaultLanguage
}

/**
 * Set the selected language in cookies and localStorage.
 *
 * @param language Language record from `languages` in `@/i18n/languages.js`.
 */
export const setSavedLanguage = ({ code, iso }: MpLanguage) => {
  // Set lang code
  document.cookie = `mp_lang=${code}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang', code)
  // Set lang iso
  document.cookie = `mp_lang_region=${iso}; domain=megaport.com; path=/;`
  window.localStorage.setItem('mp_lang_region', iso)
}

/**
 * Loads the language messages for the given language (if they are not already loaded).
 *
 * @param language Language record from `languages` in `@/i18n/languages.js`.
 */
export const loadLanguageMessages = async ({ iso, load }: MpLanguage) => {
  if (!i18n.availableLocales.includes(iso)) {
    if (!load) throw new Error(`Unable to load messages for language ${iso}`)
    const { default: message } = await load()
    i18n.setLocaleMessage(iso, message)
  }
}
