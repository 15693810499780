import sdk from '@/api-sdk'

// Initial state
const coreState = {
  prefixLists: [],
  packetFilterLists: [],
}

const getters = {
  prefixLists(state) {
    return state.prefixLists || []
  },
}

const actions = {
  async getPrefixLists(context, payload) {
    context.commit('setPrefixLists', [])

    if (!payload.productUid) return

    try {
      const lists = await sdk.instance.mcrPrefixLists(payload.productUid).list()

      context.commit('setPrefixLists', lists)
    } catch (error) {
      console.warn(error)

      throw error
    }
  },
  async getPacketFilterLists(context, payload) {
    context.commit('setPacketFilterLists', [])

    if (!payload.productUid) return

    try {
      const lists = await sdk.instance.mcrPacketFilterLists(payload.productUid).list()

      context.commit('setPacketFilterLists', lists)
    } catch (error) {
      console.warn(error)

      throw error
    }
  },
}

const mutations = {
  setPrefixLists(state, prefixLists) {
    state.prefixLists = prefixLists
  },
  setPacketFilterLists(state, packetFilterLists) {
    state.packetFilterLists = packetFilterLists
  },
}

export default {
  namespaced: true,
  state: coreState,
  getters,
  actions,
  mutations,
}
